import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useGetFulfillmentTypesQuery } from '../../../redux/api/fulfillmentMethodApi'
import { Alert, Skeleton, Stack } from '@mui/material'
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod'
import FulfillmentTypeCard from './FulfillmentTypeCard'
import { LocalShipping, Place } from '@mui/icons-material'
import ShippingMethodContent from './ShippingMethodContent'
import PickupMethodContact from './PickupMethodContent'
import { FieldValues, FormContainer, useForm } from 'react-hook-form-mui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  selectContactInfo,
  selectFulfillments,
  selectParams,
  selectSessionId,
  selectSettings,
} from '../../../redux/selectors/checkoutSelectors'
import { useUpdateFulfillmentDetailsMutation } from '../../../redux/api/checkoutApi'
import { Fulfillment } from '../../../models/Order'
import { completeEditStep } from '../../../redux/reducers/checkoutReducer'
import { getError } from '../../../helpers/checkout'
import OptionCard from '../OptionCard'

export interface FulfillmentForm extends FieldValues {
  fulfillments: Fulfillment[]
}

export default function FulfillmentActiveContent() {
  const dispatch = useAppDispatch()
  const params = useAppSelector(selectParams)
  const settings = useAppSelector(selectSettings)
  const sessionId = useAppSelector((state) => selectSessionId(state, params))
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const contactInfo = useAppSelector((state) =>
    selectContactInfo(state, params),
  )
  const fulfillments = useAppSelector((state) =>
    selectFulfillments(state, params),
  )
  const { data: types, isLoading: typesLoading } = useGetFulfillmentTypesQuery()

  const [
    updateFulfillmentDetails,
    { isLoading: loadingUpdate, error: updateError, isSuccess: updateSuccess },
  ] = useUpdateFulfillmentDetailsMutation()

  const formContext = useForm<FulfillmentForm>({
    defaultValues: useMemo(() => {
      if (fulfillments.length > 0) {
        return { fulfillments }
      } else {
        return { fulfillments: [{ contact: contactInfo }] }
      }
    }, [fulfillments, contactInfo]),
  })

  const { reset } = formContext

  const handleSubmit = async (data: FulfillmentForm) => {
    try {
      if (!sessionId || !params) {
        return
      }
      const fulfillments = data.fulfillments.map((f) => ({
        methodId: f.method.methodId,
        quoteId: f.quoteId,
        contact: f.contact,
      }))
      await updateFulfillmentDetails({
        sessionId,
        userId: params.userId,
        fulfillmentRequests: fulfillments,
      })
    } catch (error) {}
  }

  useEffect(() => {
    if (types?.length === 1) {
      setSelectedType(types[0])
    }
  }, [types])

  useEffect(() => {
    if (fulfillments?.[0]) {
      setSelectedType(fulfillments[0].method.type)
    }
    if (fulfillments.length > 0) {
      reset({ fulfillments })
    } else {
      reset({ fulfillments: [{ contact: contactInfo }] })
    }
  }, [fulfillments, contactInfo])

  useEffect(() => {
    if (updateSuccess) {
      dispatch(completeEditStep())
    }
  }, [updateSuccess])

  const handleClick = (type: FulfillmentMethodType) => () => {
    setSelectedType(type)
    reset({ fulfillments: [{ contact: contactInfo }] })
  }

  if (typesLoading) {
    return (
      <Stack direction={'column'} spacing={2}>
        <Skeleton variant="rounded" width={'100%'} height={'74px'} />
        <Skeleton variant="rounded" width={'100%'} height={'74px'} />
      </Stack>
    )
  }

  return (
    <FormContainer formContext={formContext}>
      <Stack direction={'column'} spacing={2}>
        {settings?.deliveryInstructionsEnabled && (
          <Alert severity="info">{settings.deliveryInstructionsText}</Alert>
        )}
        {types?.includes(FulfillmentMethodType.PICKUP) && (
          <OptionCard
            iconComponent={<Place />}
            label={'Pickup'}
            onClick={handleClick(FulfillmentMethodType.PICKUP)}
            selected={selectedType === FulfillmentMethodType.PICKUP}
          >
            <PickupMethodContact
              index={0}
              onSubmit={handleSubmit}
              error={updateError ? getError(updateError) : undefined}
              submitLoading={loadingUpdate}
            />
          </OptionCard>
        )}
        {types?.includes(FulfillmentMethodType.SHIPPING) && (
          <OptionCard
            iconComponent={<LocalShipping />}
            label={'Ship'}
            onClick={handleClick(FulfillmentMethodType.SHIPPING)}
            selected={selectedType === FulfillmentMethodType.SHIPPING}
          >
            <ShippingMethodContent
              index={0}
              onSubmit={handleSubmit}
              error={updateError ? getError(updateError) : undefined}
              submitLoading={loadingUpdate}
            />
          </OptionCard>
        )}
      </Stack>
    </FormContainer>
  )
}
