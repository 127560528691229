import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { selectParams, selectPayments } from "../../../redux/selectors/checkoutSelectors";
import { Stack } from "@mui/material";
import PaymentSummaryCard from "./PaymentSummaryCard";

export default function PaymentPassiveContent() {
  const params = useAppSelector(selectParams);
  const payments = useAppSelector((state) => selectPayments(state, params));

  return (
    <Stack direction="column" spacing={2}>
      {payments.map((payment, index) => (
        <PaymentSummaryCard key={index} payment={payment} />
      ))}
    </Stack>
  );
}