import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid2,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useGetSavedAddressesQuery } from '../../redux/api/coShippingLocationApi'
import AddressForm from './AddressForm'
import React, { useMemo, useEffect, useState } from 'react'
import ListSkeleton from '../../common/ListSkeleton'
import SavedAddressList from './fulfillment/SavedAddressList'
import { AddressSource } from '../../models/FulfillmentQuote'
import { useAppSelector } from '../../redux/hooks'
import {
  selectParams,
  selectContactInfo,
} from '../../redux/selectors/checkoutSelectors'
import {
  Address,
  AddressContact,
  SavedAddress,
  SavedAddressSource,
} from '../../models/Address'
import { useFormContext, useWatch } from 'react-hook-form-mui'
import { FulfillmentForm } from './fulfillment/FulfillmentActiveContent'
import AddressContactForm from './AddressContactForm'
import useAddressFormValues from './fulfillment/useAddressFormValues'

interface AddressSelectorProps {
  label: string
  formPrefix?: string
  editingAddress?: boolean
  setEditingAddress?: (editing: boolean) => void
  editingContact: boolean
  setEditingContact: (editing: boolean) => void
}

export default function AddressSelector({
  label,
  formPrefix,
  editingAddress,
  setEditingAddress,
  editingContact,
  setEditingContact,
}: AddressSelectorProps) {
  const params = useAppSelector(selectParams)
  const [openAddressForm, setOpenAddressForm] = useState(false)
  const [appliedDefaultAddress, setAppliedDefaultAddress] = useState(false)
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))
  const contactInfo = useAppSelector((state) =>
    selectContactInfo(state, params),
  )

  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`
    } else {
      return ''
    }
  }, [formPrefix])

  const {
    data: savedAddresses,
    isLoading: savedAddressesLoading,
    error: savedAddressesError,
  } = useGetSavedAddressesQuery(
    { userId: params?.userId! },
    { skip: !params?.userId },
  )

  const { setValue } =
    useFormContext<FulfillmentForm>()

  const { address, contact, savedAddressId } = useAddressFormValues({ formPrefix })

  useEffect(() => {
    if (address || savedAddressId) {
      setAppliedDefaultAddress(true)
    }
  }, [address, savedAddressId])

  const handleContinueNewAddress = () => {
    setValue(`${effectivePrefix}addressSource`, AddressSource.USER_SUPPLIED)
    setEditingAddress!(false)
    setOpenAddressForm(false)
  }

  const handleCancelEditAddress = () => {
    setOpenAddressForm(false)
  }

  const handleSelectSavedAddress = (savedAddress: SavedAddress) => {
    setValue(`${effectivePrefix}savedAddressId`, savedAddress.id)
    setValue(`${effectivePrefix}address`, savedAddress)
    setValue(`${effectivePrefix}contact`, {
      firstName:
        savedAddress?.firstName ?? contact?.firstName ?? contactInfo?.firstName,
      lastName:
        savedAddress?.lastName ?? contact?.lastName ?? contactInfo?.lastName,
      email: savedAddress?.email ?? contact?.email ?? contactInfo?.email,
    })
    setValue(
      `${effectivePrefix}addressSource`,
      savedAddress.source === SavedAddressSource.SHIPPING_LOCATION
        ? AddressSource.SHIPPING_LOCATION
        : AddressSource.USER_ADDRESS,
    )
  }

  useEffect(() => {
    if (editingAddress && !appliedDefaultAddress) {
      if (savedAddresses?.length === 0) {
        setOpenAddressForm(true)
      } else {
        const defaultAddress = savedAddresses?.find((addr) => addr.isDefault)
        if (defaultAddress) {
          handleSelectSavedAddress(defaultAddress)
          setEditingAddress!(false)
        }
      }
    }
  }, [savedAddresses, editingAddress, appliedDefaultAddress])

  const handleContinueSavedAddress = () => {
    const savedAddress = savedAddresses?.find(
      (address) => address.id === savedAddressId,
    )
    if (savedAddress) {
      setEditingAddress!(false)
    }
  }

  const handleChangeAddress = () => {
    setEditingAddress!(true)
    if (savedAddresses?.length === 0) {
      setOpenAddressForm(true)
    }
  }

  const handleEditAddress = () => {
    setOpenAddressForm(true)
  }

  const handleUpdateContact = () => {
    setEditingContact(false)
  }

  const handleCancelEditContact = () => {
    setEditingContact(false)
  }

  const handleEditContact = () => {
    setEditingContact(true)
  }

  if (!editingAddress && !editingContact) {
    return (
      <Grid2 container m={2} spacing={2}>
        <Grid2 size={4}>
          <Typography variant="body1">{label} Contact</Typography>
        </Grid2>
        <Grid2 size={{ xs: 8, sm: 5 }}>
          <Stack direction={'column'}>
            {contact.company && (
              <Typography variant="body2">{contact.company}</Typography>
            )}
            <Typography variant="body2">{`${contact.firstName} ${contact.lastName}`}</Typography>
            {contact.attentionTo && (
              <Typography variant="body2">{`Attention To: ${contact.attentionTo}`}</Typography>
            )}
            <Typography variant="body2">{contact.phone}</Typography>
            <Typography variant="body2">{contact.email}</Typography>
          </Stack>
        </Grid2>
        <Grid2
          container
          size={{ xs: 12, sm: 3 }}
          justifyContent={'center'}
          alignItems={xsDisplay ? 'stretch' : 'end'}
          direction={'column'}
        >
          <Button variant="outlined" onClick={handleEditContact}>
            Edit
          </Button>
        </Grid2>
        {setEditingAddress &&
          address && [
            <Grid2 key={'addressLabel'} size={4}>
              <Typography variant="body1">{label} Address</Typography>
            </Grid2>,
            <Grid2 key={'addressData'} size={{ xs: 8, sm: 5 }}>
              <Stack direction={'column'}>
                {address.locationName && (
                  <Typography variant="body2">
                    {address.locationName}
                  </Typography>
                )}
                <Typography variant="body2">{address.street1}</Typography>
                <Typography variant="body2">{address.street2}</Typography>
                <Typography variant="body2">{`${address.city}, ${address.state} ${address.postalCode} ${address.country}`}</Typography>
              </Stack>
            </Grid2>,
            <Grid2
              key={'addressEdit'}
              container
              size={{ xs: 12, sm: 3 }}
              justifyContent={'center'}
              alignItems={xsDisplay ? 'stretch' : 'end'}
              direction={'column'}
            >
              <Button variant="outlined" onClick={handleChangeAddress}>
                Change
              </Button>
            </Grid2>,
          ]}
      </Grid2>
    )
  }

  if (editingContact) {
    return (
      <CardContent sx={{ pt: 0 }}>
        <AddressContactForm
          label={label}
          formPrefix={formPrefix}
          onSave={handleUpdateContact}
          onCancel={handleCancelEditContact}
          showCancel
        />
      </CardContent>
    )
  }

  // Editing address
  return (
    <Box>
      {openAddressForm && (
        <CardContent sx={{ pt: 0 }}>
          <AddressForm
            label={label}
            formPrefix={formPrefix}
            onSave={handleContinueNewAddress}
            onCancel={handleCancelEditAddress}
            showCancel={savedAddresses && savedAddresses.length > 0}
          />
        </CardContent>
      )}
      {!openAddressForm && (
        <>
          <Typography variant="body1" pb={2} pl={2}>
            Select Address
          </Typography>
          {savedAddressesLoading && (
            <ListSkeleton primaryText secondaryText quantity={3} />
          )}
          <SavedAddressList
            addresses={savedAddresses ?? []}
            selectedAddressId={savedAddressId}
            onSelectAddress={handleSelectSavedAddress}
          />
          <CardContent>
            <Stack direction={'row'} justifyContent={'center'} spacing={2}>
              <Button
                variant="outlined"
                onClick={handleEditAddress}
                fullWidth={xsDisplay}
              >
                Manually Add Address
              </Button>
              <Button
                variant="contained"
                onClick={handleContinueSavedAddress}
                fullWidth={xsDisplay}
              >
                Continue
              </Button>
            </Stack>
          </CardContent>
        </>
      )}
    </Box>
  )
}
