import { useDispatch, useSelector } from "react-redux";
import { store } from "..";
import rootReducer from "./reducers/rootReducer";

interface StoreState {
    userData: {
        id: string;
    };
    cartData: {
        id: string;
        cartItems: any[]
    },
}

export type RootState = Omit<ReturnType<typeof rootReducer>, 'cartData'> & StoreState;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = <TSelected>(selector: (state: RootState) => TSelected) => useSelector(selector);