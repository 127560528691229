import React from "react";
import { CardGiftcard, CreditCard, Description, Receipt } from "@mui/icons-material";

export enum PaymentMethodType {
  DIRECT_BILL = 'directBill',
  PURCHASE_ORDER = 'purchaseOrder',
  CARDCONNECT = 'cardConnect',
  GIFT_CARD = 'giftCard',
  // POINTS = 'points',
  // VOUCHER = 'voucher',
  // BUDGET = 'budget',
}

export const PAYMENT_NAMES = {
  [PaymentMethodType.DIRECT_BILL]: 'Direct Bill',
  [PaymentMethodType.PURCHASE_ORDER]: 'Purchase Order',
  [PaymentMethodType.CARDCONNECT]: 'Credit Card',
  [PaymentMethodType.GIFT_CARD]: 'Gift Card',
}

export const PAYMENT_ICONS: Record<PaymentMethodType, React.ReactNode> = {
  [PaymentMethodType.DIRECT_BILL]: <Description />,
  [PaymentMethodType.PURCHASE_ORDER]: <Receipt />,
  [PaymentMethodType.CARDCONNECT]: <CreditCard />,
  [PaymentMethodType.GIFT_CARD]: <CardGiftcard />,
}