import React,{useEffect, useState,useContext} from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import axios from 'axios';
import { X_TENANT_ID,SETTINGS } from '../../common/ActionUrl'
import { AuthContext } from "../../common/Auth"
import { USERTYPES } from "../../constant/userType"
import BreadcrumbEditIcon from "../../pages/Breadcrumb/BreadcrumbEditIcon";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import Breadcrumbs1 from '@material-ui/core/Breadcrumbs';
import { useLocation } from 'react-router-dom'

const Breadcrumb = () => {
  const location = useLocation();
  const { userType, settingData, paymentMethodsSettings, fetchGeneralSettings } = useContext(AuthContext)
  const [showBreadCrumbs,setShowBreadCrumbs] = useState();
  const [breadCrumbStyle,setBreadCrumbStyle] = useState();
  const [budgetSettings, setBudgetSettings] = useState("")
  const [id, setId] = useState();

  const {
    generalSettings
  } = useThemeSettings({ settingData });

  const styles = {
    'color' : breadCrumbStyle?.breadCrumbFontColor ? breadCrumbStyle?.breadCrumbFontColor : "#5b5858",
    'fontSize' : breadCrumbStyle?.breadCrumbFontSize ? breadCrumbStyle?.breadCrumbFontSize + "px" : "15px",
    'fontFamily' : breadCrumbStyle?.breadCrumbFont ? breadCrumbStyle?.breadCrumbFont: "Poppins, sans-serif"
  }
  const bgStyles ={
	'backgroundColor':breadCrumbStyle?.breadCrumbBackgroundColor ? breadCrumbStyle?.breadCrumbBackgroundColor : "#f7f7f7",
  }

  useEffect(() => {
    if(generalSettings) {
      const {
        _id, values = {}
      } = generalSettings || {};
      setId(_id)
      setBreadCrumbStyle(values);
      setShowBreadCrumbs(values?.showBreadCrumbs)
    }
  }, [generalSettings])

  useEffect(() => {
    paymentMethodsSettings !== undefined && paymentMethodsSettings.map((val => {
      if (val.settingsName === 'budgetSettings') {
        setBudgetSettings(val?.values[0]?.budgetNameChange)
      }
    }))
  }, [paymentMethodsSettings])
  
  return (
  <>
    {showBreadCrumbs && 
    <div className="breadcrumb-area pt-15 pb-15 bg-gray-3" style={bgStyles}>
      <div className="container">

        <div className="breadcrumb-content text-center" style={ styles }>
          { userType && userType !== USERTYPES.USER && (
              <div className="edit-inline justify-content-center">
                <BreadcrumbEditIcon getId={ id } fromEditAction="topBottomSpace"
                                    retrieveSettings={ fetchGeneralSettings }/>
              </div>
          ) }


          {
            !!budgetSettings && location.pathname === '/budget' ?
                <Breadcrumbs1 aria-label="breadcrumb" className="breadcrumbs-container" style={ styles }>
                    <span>
                      <span>
                        <a underline="hover" style={ { color: "inherit" } } href="/">
                          Home
                        </a>
                      </span>
                    </span>
                  <span color="text.primary">{ budgetSettings || 'BUDGET 1' }</span>
                </Breadcrumbs1>

                : <Breadcrumbs

                    css={ styles }
                    separator={ <span>/</span> }
                    item={ NavLink }
                    finalItem={ "span" }
                />
          }

        </div>
      </div>
    </div>

    }
  </>

  );
};

export default Breadcrumb;
