import React, {useEffect, useState} from "react"
import productComponent from "../../constant/productComponent"

export const useThemeSettingsData = (props) => {
    const { themeSettingsData } = props
    
    const [showFreeShipping, setShowFreeShipping] = useState(false)
    const [isenableShipping, setIsenableShipping] = useState(false)
    const [shippingLabel, setShippingLabel] = useState('Shipping')
    const [SettingShippingLabel, setSettingShippingLabel] = useState('Shipping')
    const [shippingSettings, setShippingSettings] = useState(false)
    const [shipEngineIsEnable, setShipEngineIsEnable] = useState(false);
    const [shipStationIsEnable, setShipStationIsEnable] = useState(false);
    const [isHandlingFee, setIsHandlingFee] = useState(false)
    const [handlingFeeLabel, setHandlingFeeLabel] = useState('')
    const [handlingFeeCalculation, setHandlingFeeCalculation] = useState('')
    const [addHandlingFeeIntoFreight, setaddHandlingFeeIntoFreight] = useState(false)
    const [addTaxToHandlingFee, setaddTaxToHandlingFee] = useState(false)
    const [addHandlingFeeAmt, setaddHandlingFeeAmt] = useState(0)
    const [menuStyleData, setmenuStyleData] = useState([])
    const [menuIcons, setMenuIcons] = useState({})
    const [isSocialLinkIsEnable,setIsSocialLinkIsEnable]=useState(null)
    const [isAllowoutofStockOrder, setisAllowoutofStockOrder] = useState(false)
    const [isStock, setIsStock] = useState('')
    const [showInventory, setShowInventory] = useState(false)
    const [showInventoryLabelMessage, setShowInventoryLabelMessage] = useState('')
    const [productSettings, setProductSettings] = useState({})
    const [productSettingsData, setProductSettingsData] = useState({})
    const [generalShipFrom, setGeneralShipFrom] = useState(null);
    const [generalShipTo, setGeneralShipTo] = useState(null);
    const [shippinguspsValue, setShippinguspsValue] = useState([])
    const [shippingfedexValue, setShippingfedexValue] = useState([])
    const [shippingupsValue, setShippingupsValue] = useState([])
    const [shopLayout, setShopLayout] = useState("");
    const [sideBarSettings, setSideBarSettings] = useState({});
    const [inventorySettings, setInventorySettings] = useState(null)
    const [rowText, setRowText] = useState('')
    const [productSettingShowDescription, setProductSettingShowDescription] = useState(true)
    const [generalProductLayout, setGeneralProductLayout] = useState('');
    const [enableShipStationServices, setEnableShipStationServices] = useState([]);
    const [customFlatTax, setCustomFlatTax] = useState({})
    const [taxName, setTaxName] = useState({})
    const [allTax, setAllTax] = useState()
    const [anteraTax, setAnteraTax] = useState(null)
    const [theme, setTheme] = useState("");
    const [taxId, setTaxId] = useState('')
    const [productSorting, setProductSorting] = useState(null)
    const [shipStationPreventZeroCost, setShipStationPreventZeroCost] = useState(false);
    const [customVendor, setCustomVendor] = useState('');
    const [shipStationDefault, setShipStationDefault] = useState(null);
    const [SettingsRequiredDecorationForCheckOut, setSettingsRequiredDecorationForCheckOut] = useState(false);
    const [showHexColorSet, setShowHexColorSet] = useState(true)
    const [isSecondaryImgHover, setIsSecondaryImgHover] = useState(true);
    const [tabTaggedSettings, setTabTaggedSettings] = useState(null);
    const [productSliderSettings, setProductSliderSettings] = useState(null);
    const [tabEnabled, setTabEnabled] = useState(false);
    const [defaultTab, setDefaultTab] = useState(0);
    const [tagsIds, setTagsIds] = useState([]);
    const [featuredSettings,setFeaturedProducts]=useState(null);
    const [isLPublic, setIsLPublic]=useState(true);
    const [registrationLCheckoutRequired, setRegistrationCheckoutRequired]=useState(false);
    const [registrationLCheckoutOptional, setRegistrationCheckoutOptional]=useState(false);
    const [flatTaxPerState, setFlatTaxPerState] = useState([])
    const [taxJarMessage, setTaxJarMessage] = useState('')
    const [hideTaxJarMessage, setHideTaxJarMessage] = useState(false)
    const [avalaraMessage, setAvalaraMessage] = useState('')
    const [hideAvalaraMessage, setHideAvalaraMessage] = useState(false)
    const [taxStrategy, setTaxStrategy] = useState([])

    useEffect(() => {
        if (themeSettingsData && themeSettingsData.length > 0) {
            const customShippingSettings = themeSettingsData.find(x => x.settingsName === "customShippingSettings")
            const loginSettings = themeSettingsData.find(x => x.settingsName === "login")
            if (customShippingSettings?.values && customShippingSettings?.values?.[0] && customShippingSettings?.values?.[0].fields) {
                setCustomVendor(customShippingSettings?.values?.[0]?.fields?.vendor)
                setShowFreeShipping(customShippingSettings?.values?.[0].fields.isFreeShipping)
                if (customShippingSettings?.values?.[0].fields && customShippingSettings?.values?.[0].fields.shippingIsEnable) {
                  setIsenableShipping(true)
                  setShippingSettings(customShippingSettings?.values?.[0].fields)
                }
                if (customShippingSettings?.values?.[0].fields.shippingLabel != "" && customShippingSettings?.values?.[0].fields.shippingLabel != undefined) {
                    setShippingLabel(customShippingSettings?.values?.[0].fields.shippingLabel)
                    setSettingShippingLabel(customShippingSettings?.values?.[0].fields.shippingLabel)
                }
                if (customShippingSettings?.values?.[0].fields && customShippingSettings?.values?.[0].fields.shipEngineIsEnable) {
                  setIsenableShipping(true)
                  if (customShippingSettings?.values?.[0].fields.shipEngineIsEnable !== undefined) {
                    setShipEngineIsEnable(customShippingSettings?.values?.[0].fields.shipEngineIsEnable)
                  }
                  setShippingSettings(customShippingSettings?.values?.[0].fields)
                }
                if (customShippingSettings?.values?.[0].fields && customShippingSettings?.values?.[0].fields.shipStationIsEnable) {
                  setIsenableShipping(true)
                  setShipStationPreventZeroCost(customShippingSettings?.values?.[0].fields.shipStationPreventZeroCost)
                  if (customShippingSettings?.values?.[0].fields.shipStationIsEnable !== undefined) {
                    setShipStationIsEnable(customShippingSettings?.values?.[0].fields.shipStationIsEnable)
                  }
                  setShippingSettings(customShippingSettings?.values?.[0].fields)
                }
                if (customShippingSettings?.values?.[0].fields && customShippingSettings?.values?.[0].fields.isHandlingFee) {
                  setIsHandlingFee(true)
                  setHandlingFeeLabel(customShippingSettings?.values?.[0]?.fields?.handlingFeeLable || 'Handling Fee')
                  if (customShippingSettings?.values?.[0].fields.HandlingFeeCalculation != "" && customShippingSettings?.values?.[0].fields.HandlingFeeCalculation != undefined) {
                    setHandlingFeeCalculation(customShippingSettings?.values?.[0].fields.HandlingFeeCalculation)
                  }
                  if (customShippingSettings?.values?.[0].fields.addHandlingFeeIntoFreight != "" && customShippingSettings?.values?.[0].fields.addHandlingFeeIntoFreight != undefined) {
                    setaddHandlingFeeIntoFreight(customShippingSettings?.values?.[0].fields.addHandlingFeeIntoFreight)
                  }
                  if (customShippingSettings?.values?.[0].fields.addTaxToHandlingFee != "" && customShippingSettings?.values?.[0].fields.addTaxToHandlingFee != undefined) {
                    setaddTaxToHandlingFee(customShippingSettings?.values?.[0].fields.addTaxToHandlingFee)
                  }
                  if (customShippingSettings?.values?.[0].fields.addHandlingFeeAmt != "" && customShippingSettings?.values?.[0].fields.addHandlingFeeAmt != undefined) {
                    setaddHandlingFeeAmt(customShippingSettings?.values?.[0].fields.addHandlingFeeAmt)
                  }
                  setShippingSettings(customShippingSettings?.values?.[0].fields)
                }
            }

            if(loginSettings?.values  && loginSettings?.values?.[0]) {
              setIsLPublic(loginSettings?.values?.[0]?.isPublic)
              setRegistrationCheckoutRequired(loginSettings?.values?.[0]?.registrationCheckoutRequired)
              setRegistrationCheckoutOptional(loginSettings?.values?.[0]?.registrationCheckoutOptional)
            }
            
            const headerMenuSettings = themeSettingsData.find(x => x.settingsName === "headerMenu")
            if (headerMenuSettings?.values?.[0]?.menuSettings) {
                setmenuStyleData(headerMenuSettings.values[0].menuSettings)
            }

            const footerContentSettings = themeSettingsData.find(x => x.settingsName === "footerContent")
            setMenuIcons(footerContentSettings?.values?.[0]?.socialLink)
            setIsSocialLinkIsEnable(footerContentSettings?.values?.[0]?.socialLinkIsEnable ?? false)

            const inventorySettings = themeSettingsData.find(x => x.settingsName === "inventorySettings")
            setisAllowoutofStockOrder(inventorySettings?.values?.[0]?.isAllowoutofStockOrder ?? false)
            setIsStock(inventorySettings?.values?.[0]?.isStockFor);
            setShowInventory(inventorySettings?.values?.[0]?.showInventoryOnCatPage);
            setShowInventoryLabelMessage(inventorySettings?.values?.[0]?.categoryInventoryMessage);
            setInventorySettings(inventorySettings?.values?.[0]);

            const productPageSettings = themeSettingsData.find(x => x.settingsName === "productpageSettings")
            setProductSettings(productPageSettings?.values?.[0])
            setProductSettingsData(productPageSettings?.values?.[0])
            setRowText(productPageSettings?.values?.[0]?.rowText)
            setProductSettingShowDescription(productPageSettings?.values?.[0]?.showDescription)
            if (productPageSettings?.values?.[0]?.RequiredDecorationForCheckOut) {
              setSettingsRequiredDecorationForCheckOut(productPageSettings.values[0].RequiredDecorationForCheckOut)
            }
            if (productPageSettings?.values?.[0]?.showHexColorSet === false) {
              setShowHexColorSet(false)
            }
            if (productPageSettings?.values?.[0]?.isSecondaryImgHover === false) {
              setIsSecondaryImgHover(false)
            }

            const shipFromSettings = themeSettingsData.find(x => x.settingsName === "ShipFromSettings")
            setGeneralShipFrom(shipFromSettings?.values?.[0]?.fields);

            const shipToSettings = themeSettingsData.find(x => x.settingsName === "ShipToSettings")
            setGeneralShipTo(shipToSettings?.values?.[0]?.fields);

            const upsShippingSettings = themeSettingsData.find(x => x.settingsName === "upsShippingSettings")
            setShippingupsValue(upsShippingSettings?.values?.[0]?.fields?.upsValue ?? [])

            const uspsShippingSettings = themeSettingsData.find(x => x.settingsName === "uspsShippingSettings")
            setShippinguspsValue(uspsShippingSettings?.values?.[0]?.fields?.uspsValue ?? [])
            
            const fedexShippingSettings = themeSettingsData.find(x => x.settingsName === "fedexShippingSettings")
            setShippingfedexValue(fedexShippingSettings?.values?.[0]?.fields?.fedexValue ?? [])

            const shopLayout = themeSettingsData.find(x => x.settingsName === 'theme' )
            if (!!shopLayout?.values?.[0]?.productLayout && productComponent[shopLayout?.values[0].productLayout]) {
              setGeneralProductLayout(shopLayout?.values[0].productLayout || 'product-tab-bottom');
            }
            if(shopLayout?.values[0]?.categoryLayout === "shop-grid-two-column"){
                setShopLayout("grid two-column")
            }
            if(shopLayout?.values[0]?.categoryLayout === "shop-list-standard" || shopLayout?.values[0]?.categoryLayout === "shop-list-full-width"){
                setShopLayout("list")
            }
            if(shopLayout?.values[0]?.categoryLayout === "shop-list-two-column"){
                setShopLayout("list two-column")
            }
            if(shopLayout?.values?.[0]?.themeName === "dark") {
              setTheme(shopLayout.values[0].themeName);
            }

            const shipStationServicesSettings = themeSettingsData.find(x => x.settingsName === 'ShipStationServicesSettings' )
            setEnableShipStationServices(shipStationServicesSettings?.values?.[0]?.fields?.shipStationServices || []);
            setShipStationDefault(shipStationServicesSettings?.values?.[0]?.fields?.default);

            const taxSettings = themeSettingsData.find(x => x.settingsName === 'taxSettings')
            setTaxId(taxSettings?._id)
            setTaxName(taxSettings?.values?.[0]?.taxName)
            setCustomFlatTax(taxSettings?.values?.[0]?.customFlatTax?.[0])
            setAllTax(taxSettings?.values?.[0])
            if(taxSettings?.values?.[0]?.anteraTax?.length > 0) {
              taxSettings.values[0].anteraTax = taxSettings.values[0].anteraTax.map(x => {
                return {
                  ...x,
                  taxEnabled: typeof x.taxEnabled === 'boolean' ? x.taxEnabled : x.isEnable
                }
              })
            }
            setAnteraTax(taxSettings?.values?.[0]?.anteraTax);
            if(taxSettings?.values?.[0]?.listFlatTaxPerState?.length > 0) {
              setFlatTaxPerState(taxSettings.values[0].listFlatTaxPerState)
            }

            if(taxSettings?.values?.[0]?.taxStrategy?.length > 0) {
              setTaxStrategy(taxSettings.values[0].taxStrategy)
            }

            if(!taxSettings?.values?.[0]?.taxJar?.[0]?.hideMessage){
              setTaxJarMessage(taxSettings?.values?.[0]?.taxJar?.[0]?.checkoutTaxMessage || 'Any applicable tax will be applied on the payment screen')
            }
            setHideTaxJarMessage(taxSettings?.values?.[0]?.taxJar?.[0]?.hideMessage)
            if(!taxSettings?.values?.[0]?.avalara?.[0]?.hideMessage){
              setAvalaraMessage(taxSettings?.values?.[0]?.avalara?.[0]?.checkoutTaxMessage || 'Any applicable tax will be applied on the payment screen')
            }
            setHideAvalaraMessage(taxSettings?.values?.[0]?.avalara?.[0]?.hideMessage)
            const productSortingSetting = themeSettingsData.find(x => x.settingsName === 'productSortingSetting')
            setProductSorting(productSortingSetting?.values?.[0])

            const taggedProductsSettings = themeSettingsData.find(x => x.settingsName === 'taggedProducts');
            setTabTaggedSettings(taggedProductsSettings?.values?.[0]);
            let catIds = [];
            if (taggedProductsSettings?.values?.[0]?.tabList?.length > 0) {
              taggedProductsSettings.values[0].tabList.map((tab, index) => {
                if (tab.tabProductCat != "") {
                  catIds.push(tab.tabProductCat)
                }
                if(tab.tabEnabled && !tabEnabled){
                  setTabEnabled(true)
                  setDefaultTab(index)
                }
              })
            }
            if (catIds.length > 0) {
              setTagsIds(catIds);
            }
            const productSliderSettings = themeSettingsData.find(x => x.settingsName === 'productSlider');
            setProductSliderSettings(productSliderSettings?.values?.[0]);

            const featuredProductsSettings = themeSettingsData.find(x => x.settingsName === 'featuredProducts');
            setFeaturedProducts(featuredProductsSettings?.values?.[0])

        }
    }, [themeSettingsData])

    return {
        showFreeShipping,
        isenableShipping,
        shippingLabel,
        SettingShippingLabel,
        shippingSettings,
        shipEngineIsEnable,
        shipStationIsEnable,
        isHandlingFee,
        handlingFeeLabel,
        handlingFeeCalculation,
        addHandlingFeeIntoFreight,
        addTaxToHandlingFee,
        addHandlingFeeAmt,
        menuStyleData,
        menuIcons,
        isSocialLinkIsEnable,
        isAllowoutofStockOrder,
        isStock,
        showInventory,
        showInventoryLabelMessage,
        productSettings,
        productSettingsData,
        generalShipFrom,
        generalShipTo,
        shippinguspsValue,
        shippingfedexValue,
        shippingupsValue,
        shopLayout,
        sideBarSettings,
        inventorySettings,
        rowText,
        productSettingShowDescription,
        generalProductLayout,
        enableShipStationServices,
        taxId,
        taxName,
        customFlatTax,
        allTax,
        theme,
        productSorting,
        shipStationPreventZeroCost,
        customVendor,
        shipStationDefault,
        SettingsRequiredDecorationForCheckOut,
        showHexColorSet,
        isSecondaryImgHover,
        tabTaggedSettings,
        productSliderSettings,
        tabEnabled,
        defaultTab,
        tagsIds,
        featuredSettings,
        anteraTax,
        isLPublic,
        registrationLCheckoutRequired,
        registrationLCheckoutOptional,
        flatTaxPerState,
        taxJarMessage,
        hideTaxJarMessage,
        avalaraMessage,
        hideAvalaraMessage,
        taxStrategy,
    }
}
