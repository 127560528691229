import React, { useEffect, useState, useContext, useMemo } from "react";
import { AuthContext } from "./common/Auth"
import productComponent from "./constant/productComponent"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useProductDetailState } from "./pages/shop-product/state/useProductDetailState";
import { useThemeSettingsData } from "./layouts/state/useThemeSettingsData";
import Loader from "./common/Loader";
import NoProductFound from "./common/NoProductFound";
import { useThemeSettings } from "./layouts/state/useThemeSettings";

const DynamicProductComponent = (props) => {
    const {match} = props
    const [productLayout, setProductLayout] = useState('');
    const [ProductLayoutComponent, setProductLayoutComponent] = useState(null);
    const { themeSettingsData, settingData } = useContext(AuthContext)
    const [productId, setProductId] = useState('')
    const {
        product,
        productSettings,
        tabName,
        userGroupDiscount,
        title,
        resetProduct,
        isLoadingProduct,
        getCategoryTree,
        productCategories
    } = useProductDetailState({productId})
    const {
        generalProductLayout
    } = useThemeSettingsData({themeSettingsData})

    const {
        productLayoutSettings,
    } = useThemeSettings({ settingData })

    useMemo(() => {
        let categories = []
        if (productCategories && !localStorage.getItem('catTree')) {
            const mainCat = productCategories[0]?.categoryName;
            if(mainCat) {
                categories = getCategoryTree(mainCat, [mainCat]).reverse()
            }
            localStorage.setItem('catTree', JSON.stringify(categories))
        }
    }, [productCategories])

    useEffect(() => {
        if (match && !!match?.params?.id) {
            const id = match.params.id.toString().split('-')[0];
            if(id !== productId) {
                resetProduct()
            }
            setProductId(id)
        }
      }, [match])

    useEffect(()=>{
        if ( !!product && generalProductLayout) {
            let gl = generalProductLayout
            let pl = product?.productLayout
            if (['product-simple', 'product-variation', 'product-affiliate', 'eComm-product-template'].includes(pl)) {
                pl = 'product-tab-bottom';
            }
            if (['product-simple', 'product-variation', 'product-affiliate', 'eComm-product-template'].includes(gl)) {
                gl = 'product-tab-bottom';
            }
            if(productLayoutSettings?.topLayout?.imageCarouselLocation && gl == 'product-tab-bottom' && gl !== productLayoutSettings?.topLayout?.imageCarouselLocation) {
                gl = productLayoutSettings?.topLayout?.imageCarouselLocation
            }
            console.log('pl: ', pl, " === ", gl);
            if (!!pl && gl !== pl) {
                setProductLayout(pl);
            } else {
                setProductLayout(gl)
            }
        }
    }, [product, generalProductLayout])

    useEffect(()=>{
        if ( productLayout ) {
            setProductLayoutComponent(productComponent[productLayout]);
        }
    }, [productLayout])


    useEffect(() => {
        return () => {
            localStorage.removeItem('catTree')
        };
    },[]);

    return (
            <>
                {
                    !isLoadingProduct && product &&  ProductLayoutComponent && !!productLayout && <ProductLayoutComponent
                        { ...props } 
                        productSettings={productSettings} 
                        tabName={tabName}
                        userGroupDiscount={userGroupDiscount} 
                        product={product} 
                        title={title}
                    />
                }
                {!(!!product) && !isLoadingProduct ? <NoProductFound /> : ''}
                {
                   isLoadingProduct ? <div className="container py-3">
                       <Loader />
                       <div className="row">
                            <div className="col-md-6">
                                <Skeleton count={ 1 } height={ 300 }/>
                            </div>
                            <div className="col-md-6">
                                <Skeleton count={ 1 } height={ 200 }/>
                                <Skeleton count={ 1 } height={ 100 }/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Skeleton count={ 1 } height={ 100 }/>
                            </div>
                        </div>
                    </div>: <></>
                }
            </>


    )
}

export default DynamicProductComponent;
