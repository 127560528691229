import React, { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../common/Auth';
import { USERTYPES } from '../../constant/userType';
import BreadcrumbEditSection from './BreadcrumbEditSection';

const BreadcrumbEditIcon = ({getId, fromEditAction, retrieveSettings, generalSettings}) => {
    const { userType } = useContext(AuthContext)
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(!open)
    }
    const handleOpen = () => {
        setOpen(!open)
    }
    return (
        <>
            <h6 className="animated" onClick={ () => {
                handleOpen()} }>
                <i className="fa fa-edit"></i>
            </h6>
            <BreadcrumbEditSection open={ open } handleClose={ handleClose } getId={ getId }
                                   fromEditAction={ fromEditAction } retrieveSettings={ retrieveSettings } generalSettings={generalSettings}/>
        </>
    )
}

export default BreadcrumbEditIcon