import React, { useEffect, useMemo, useState } from 'react'
import { Stack, useMediaQuery, useTheme } from '@mui/system'
import { Alert, Button, Typography } from '@mui/material'
import AddressSelector from '../AddressSelector'
import { FormContainer, useForm } from 'react-hook-form-mui'
import { AetherPaymentRequest } from '../../../models/Payment'
import {
  PAYMENT_ICONS,
  PAYMENT_NAMES,
  PaymentMethodType,
} from '../../../models/PaymentMethodType'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectParams,
  selectCurrency,
  selectOrderDecimalsToShow,
  makeSelectMethodPaymentData,
  selectTaxRate,
} from '../../../redux/selectors/checkoutSelectors'
import { getPaymentForm } from './PaymentMethodForms'
import { PaymentMethodSettings } from '../../../models/PaymentMethodSettings'
import OptionCard from '../OptionCard'
import TestModeConfirmDialog from './TestModeConfirmDialog'
import {
  useGetPaymentSettingsQuery,
  useValidateRequestMutation,
} from '../../../redux/api/paymentApi'
import {
  calculateConvenienceFee,
  getError,
  getItemIds,
  getRestrictedPaymentMessage,
} from '../../../helpers/checkout'
import Decimal from 'decimal.js'
import { LoadingButton } from '@mui/lab'
import parse from 'html-react-parser';

interface PaymentMethodCardProps {
  settings: PaymentMethodSettings
  request?: Partial<AetherPaymentRequest>
  errorMessage?: string
  index: number | null
  onSelect: () => void
  onSubmit: (data: AetherPaymentRequest) => void
  selected: boolean
}

export default function PaymentMethodCard({
  request,
  settings,
  errorMessage,
  index,
  onSelect,
  onSubmit,
  selected,
}: PaymentMethodCardProps) {
  const params = useAppSelector(selectParams)
  const currency = useAppSelector(selectCurrency)
  const orderDecimalsToShow = useAppSelector((state) =>
    selectOrderDecimalsToShow(state, params),
  )
  const taxRate = useAppSelector((state) => selectTaxRate(state, params))
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))
  const [editingContact, setEditingContact] = useState(false)
  const [editingAddress, setEditingAddress] = useState(
    request?.address === undefined,
  )

  const selectMethodPaymentData = makeSelectMethodPaymentData()

  const { data: paymentSettings } = useGetPaymentSettingsQuery()

  const [
    validateRequest,
    { error: validateError, isLoading: validateLoading },
  ] = useValidateRequestMutation()

  const {
    amount: methodBalanceDue,
    items: methodItems,
    restrictedItemIds,
  } = useAppSelector((state) =>
    selectMethodPaymentData(state, params, index, settings.methodType),
  )

  const [testModeDialog, setTestModeDialog] = useState(false)

  const formContext = useForm<AetherPaymentRequest>({
    defaultValues: useMemo(
      () =>
        request
          ? {
              ...request,
            }
          : {},
      [request],
    ),
  })

  const { reset, handleSubmit, control } = formContext

  useEffect(() => {
    reset({
      ...(request ?? {}),
    })
  }, [request])

  const { fee: convenienceFee, tax: convenienceFeeTax } = useMemo(() => {
    if (settings.convenienceFeeEnabled) {
      return calculateConvenienceFee(
        methodBalanceDue,
        settings.convenienceFeePercent ?? 0,
        settings.convenienceFeeTaxable ?? false,
        taxRate ?? 0,
      )
    } else {
      return { fee: new Decimal(0), tax: new Decimal(0) }
    }
  }, [settings, taxRate, methodBalanceDue])

  const handleFormSubmit = async (data: AetherPaymentRequest) => {
    const amount = methodBalanceDue
      .plus(convenienceFee)
      .plus(convenienceFeeTax)
      .toNumber()
    const submitData = {
      ...data,
      methodType: settings.methodType,
      itemIds: getItemIds(methodItems, amount),
      amount: amount,
      convenienceFee: convenienceFee.toNumber(),
      convenienceFeeTax: convenienceFeeTax.toNumber(),
      otpEnabled: settings.otpEnabled,
    }
    if (testModeDialog) {
      setTestModeDialog(false)
    } else if (
      submitData.methodType === PaymentMethodType.CARDCONNECT &&
      paymentSettings?.find(
        (s) => s.methodType === PaymentMethodType.CARDCONNECT,
      )?.testModeEnabled
    ) {
      setTestModeDialog(true)
      return
    }

    if (settings.validationRequired) {
      try {
        const validationResponse = await validateRequest(submitData).unwrap()
        submitData.amount = validationResponse.amount
          ? Math.min(validationResponse.amount, submitData.amount)
          : submitData.amount
        submitData.itemIds = getItemIds(methodItems, submitData.amount)
        submitData.validationResponse = validationResponse
        onSubmit(submitData)
      } catch (error) {
        console.error(error)
      }
    } else {
      onSubmit(submitData)
    }
  }

  const methodDisabled = useMemo(
    () => methodBalanceDue.equals(0),
    [methodBalanceDue],
  )

  const handleCloseTestModeDialog = () => {
    setTestModeDialog(false)
  }

  const handleContinueTestModeDialog = () => {
    handleSubmit(handleFormSubmit)()
  }

  if (methodDisabled) {
    return <></>
  }

  return (
    <FormContainer formContext={formContext}>
      <TestModeConfirmDialog
        open={testModeDialog}
        onClose={handleCloseTestModeDialog}
        onContinue={handleContinueTestModeDialog}
      />
      <OptionCard
        iconComponent={PAYMENT_ICONS[settings.methodType]}
        selected={selected}
        onClick={onSelect}
        label={
          settings.labelRenameEnabled && settings.labelRenameText
            ? settings.labelRenameText
            : PAYMENT_NAMES[settings.methodType]
        }
        warningText={
          (restrictedItemIds?.length ?? 0) > 0 && !selected
            ? getRestrictedPaymentMessage(settings)
            : undefined
        }
      >
        {selected && (
          <>
            <AddressSelector
              label={'Billing'}
              editingContact={editingContact}
              setEditingContact={setEditingContact}
              editingAddress={editingAddress}
              setEditingAddress={setEditingAddress}
            />
            {!editingAddress && !editingContact && (
              <Stack direction={'column'} margin={2} spacing={2}>
                {(restrictedItemIds?.length ?? 0) > 0 && (
                  <Alert severity="warning">
                    {getRestrictedPaymentMessage(settings)}
                  </Alert>
                )}
                {settings.convenienceFeeEnabled && (
                  <Typography variant="body1">
                    Convenience Fee: {currency.currencySymbol}
                    {convenienceFee
                      .plus(convenienceFeeTax)
                      .toFixed(orderDecimalsToShow)}
                  </Typography>
                )}
                {!settings.validationRequired && (
                  <Typography variant="body1">
                    {`Payment Amount: ${
                      currency.currencySymbol
                    } ${methodBalanceDue
                      .plus(convenienceFee)
                      .toFixed(orderDecimalsToShow)}`}
                  </Typography>
                )}
                {settings.checkoutHtmlEnabled && (
                  <Typography variant="body1">
                    {parse(settings.checkoutHtml)}
                  </Typography>
                )}
                {getPaymentForm(settings.methodType, { settings, index })}
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {validateError && (
                  <Alert severity="error">{getError(validateError)}</Alert>
                )}
                <Stack direction={'row'} justifyContent={'center'}>
                  <LoadingButton
                    variant={'contained'}
                    size={'large'}
                    onClick={handleSubmit(handleFormSubmit)}
                    fullWidth={xsDisplay}
                    loading={validateLoading}
                  >
                    Continue
                  </LoadingButton>
                </Stack>
              </Stack>
            )}
          </>
        )}
      </OptionCard>
    </FormContainer>
  )
}
