import { CardConnectAccount } from '../../models/CardConnectAccount'
import { AetherPaymentRequest, ValidateRequestResponse } from '../../models/Payment'
import { PaymentMethodSettings } from '../../models/PaymentMethodSettings'
import { aetherApi } from './aetherApi'

export const paymentApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentSettings: builder.query<PaymentMethodSettings[], void>({
      query: () => `api/v2/payment/front-methods`,
    }),
    validateRequest: builder.mutation<ValidateRequestResponse, AetherPaymentRequest>({
      query: (request) => ({
        url: `api/v2/payment/validate`,
        method: 'POST',
        body: request,
      }),
    }),
    getCardConnectAccounts: builder.query<CardConnectAccount[], void>({
      query: () => `api/v2/payment/cardconnect/accounts`,
    }),
    deleteCardConnectAccount: builder.mutation<void, string>({
      query: (id) => ({
        url: `api/v2/payment/cardconnect/accounts/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            paymentApi.util.updateQueryData(
              'getCardConnectAccounts',
              undefined,
              (draft) => {
                if (draft) {
                  const index = draft.findIndex((t) => t.acctid === id)
                  if (index !== -1) {
                    draft.splice(index, 1)
                  }
                }
              },
            ),
          )
        } catch (error) {
          console.error(error)
        }
      },
    }),
  }),
})

export const { useGetPaymentSettingsQuery, useGetCardConnectAccountsQuery, useDeleteCardConnectAccountMutation, useValidateRequestMutation } = paymentApi