import React, {useState, useEffect, useContext} from "react"
import {
    getDiscountPrice,
    getFirstPrice,
    getGlobalShowProductPrice,
    getShowProductPrice,
} from '../../../helpers/product'

export const useProductPriceState = (props) => {

    const { product, userGroupDiscount, currency, user, itempriceDisplay, priceDisplay, kitConfig } = props
    const [productPrice, setProductPrice] = useState(0)
    const [showSellPrice, setShowSellPrice] = useState(false)
    const [showSell, setShowSell] = useState(false)
    const [productDiscount, setProductDiscount] = useState(0)
    const [discountedPrice, setDiscountedPrice] = useState(0)
    const [finalProductDiscPrice, setFinalProductDiscPrice] = useState(0)
    const [discountType, setDiscountType] = useState("")
    const [finalProductPrice, setFinalProductPrice] = useState(0)
    const [finalDiscountedPrice, setFinalDiscountedPrice] = useState(0)
    const [offerPrice, setOfferPrice] = useState(0)
    const [showPrice, setShowPrice] = useState(false);
    useEffect(() => {
        if(product && currency) {
            const todayDate = new Date().toISOString();
            const productPrice = product.price ? product.price : 0;
            const productDiscount = product.discount ? product.discount : 0;
            const discountedPrice = getDiscountPrice(productPrice, productDiscount);
            const disIndProductPrice = getShowProductPrice(product)
            let tempShowPrice = false;
            let tempFinalProductDiscPrice = 0;
            let tempDiscountType = ""
            let tempFinalProductPrice = getFirstPrice(product, product.decimalsToShow || 2);
            const finalDiscountedPrice = +(discountedPrice * currency.currencyRate).toFixed(product.decimalsToShow || 2);
            let tempOfferPrice = 0;
            if ((todayDate >= product?.startDate && todayDate <= product?.endDate) || 
                    (!!!product?.startDate)) {
                if (product?.makeProductSale) {
                    setShowSell(true)
                    if (product?.showPriceBreak) {
                        setShowSellPrice(true)
                    }
                }
            }

            if (product.sellingPrice) {
                tempOfferPrice = product.sellingPrice
            }
            if (product.discountType === "0" || product.discountType === "1") {
                tempDiscountType = product.discountType
            }
            if (Number(userGroupDiscount > 0) && Number(tempFinalProductPrice) > 0) {
                tempFinalProductPrice = Number(tempFinalProductPrice) - (Number(tempFinalProductPrice) * Number(userGroupDiscount) / 100)
                if(tempFinalProductPrice < 0) {
                    tempFinalProductPrice = 0
                }
            }
            tempFinalProductDiscPrice = tempFinalProductPrice
            if (tempDiscountType === "1" && product?.makeProductSale && Number(tempFinalProductDiscPrice) > 0) {
                if (tempOfferPrice > 0) {
                    tempFinalProductDiscPrice = Number(tempFinalProductDiscPrice) - Number(tempOfferPrice)
                    if(tempFinalProductDiscPrice < 0) {
                        tempFinalProductDiscPrice = 0
                    }
                }
            } else if (tempDiscountType === "0" && product?.makeProductSale && Number(tempFinalProductDiscPrice) > 0) {
                tempFinalProductDiscPrice = Number(tempFinalProductDiscPrice) - (Number(tempFinalProductDiscPrice) * Number(tempOfferPrice) / 100)
                if(tempFinalProductDiscPrice < 0) {
                    tempFinalProductDiscPrice = 0
                }
            }
            tempShowPrice = getGlobalShowProductPrice(product, itempriceDisplay, priceDisplay, user );
            if (kitConfig && kitConfig.priceMode !== 'separate') {
                tempShowPrice = false;
            }
            if (product?.isKitEnabled && product?.kitPriceMode === 'rollup') {
              tempShowPrice = false;
            }

            setDiscountType(tempDiscountType)
            setProductPrice(productPrice)
            setFinalProductDiscPrice(tempFinalProductDiscPrice)
            setFinalProductPrice(tempFinalProductPrice)
            setFinalDiscountedPrice(finalDiscountedPrice)
            setOfferPrice(tempOfferPrice)
            setDiscountedPrice(discountedPrice)
            setProductDiscount(productDiscount)
            setShowPrice(tempShowPrice);

        }
    }, [product, currency, userGroupDiscount, user, kitConfig, itempriceDisplay, priceDisplay])

    console.log(product?.productName, showPrice)

    return {
        productPrice,
        showSellPrice,
        productDiscount,
        discountedPrice,
        finalProductDiscPrice,
        discountType,
        finalProductPrice,
        finalDiscountedPrice,
        offerPrice,
        showPrice,
        showSell,
    }
}
