import React, { useContext, useEffect, useState } from "react";
import {useLocation} from "react-router-dom";
import {Breadcrumbs} from "react-breadcrumbs-dynamic";
import axios from 'axios';
import {BREADCRUMBS, X_TENANT_ID} from '../../common/ActionUrl'
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import BannerForm from "../../components/hero-slider/bannerData/DialogEditBanner"
import EditBreadCrumbDialog from "../../components/hero-slider/bannerData/EditBreadCrumbDialog";
import {pathNames, pathRoutes} from "../../shared/infrastructure/breadcrumb";
import { useSelector } from "react-redux";
import { defaultTo } from "lodash";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import { AuthContext } from "../../common/Auth";

const MainBreadCrumb = (props) => {
  const [open, setOpen] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  const [breadCrumbData, setBreadCrumbData] = useState([]);
  const [hoverColor, setHoverColor] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  let locationState = useLocation();
  const {
    settingData,
      categoriesList
  } = useContext(AuthContext)

  const {
    breadCrumbHoverColor
  } = useThemeSettings({ settingData });

  const currentProduct = useSelector((state) =>
      defaultTo( state.productData.currentProduct, { })
  );

  useEffect(() => {
    if(!!!breadCrumbData?.length || !!!currentProduct?.id ) {
      if(breadCrumbData?.length ) {
        setMappedData(breadCrumbData)
      }
      return
    }
    const index = breadCrumbData.findIndex(x => x.name === currentProduct.id)
    if(index > 0) {
      breadCrumbData[index].name = currentProduct.productName
    }
    setMappedData(breadCrumbData)
  }, [currentProduct, breadCrumbData])

  useEffect(() => {
    if(categoriesList?.length === 0) {
        return
    }
    const paths = locationState.pathname.split("/")
    const itemsAdded = [
      {
        "name": pathNames.Home,
        "path": "/"
      }
    ];
    paths.forEach((pathItem) => {
      if (pathItem !== "") {
        // validate edge cases
        if (pathItem === "products" || pathItem === "product") {
          itemsAdded.push({
            "name": pathNames.products,
            "path": pathRoutes.products
          })
          if(pathItem === "product") {
            if(localStorage.getItem('catTree')) {
              const catTree = JSON.parse(localStorage.getItem('catTree'))
              catTree.forEach(x => {
                itemsAdded.push({
                  "name": x,
                  "path": x.replace(/ /g, '-')
                })
              })
            }
          }
        } else {
          const catPathName = categoriesList.find(x => x.categoryName.replace(/ /g, "-").toLowerCase() === pathItem.replace(/ /g, "-").toLowerCase())
          const path = catPathName ? catPathName.categoryName : pathItem
          itemsAdded.push({
            "name": path,
            "path": pathItem
          })
        }
      }
    })

    let temportalPath = itemsAdded[0].path;
    for (let i = 1; i < itemsAdded.length; i++) {
      temportalPath += itemsAdded[i].path + "/";
      itemsAdded[i].path = temportalPath;
    }

    setBreadCrumbData(itemsAdded);
  }, [locationState, categoriesList])

  return (
      <>
        {mappedData.map((item, index) => (
            <BreadcrumbsItem
                onMouseEnter={() => {
                  setHoverColor(breadCrumbHoverColor)
                  setCurrentIndex(index)
                }}
                onMouseLeave={() => setHoverColor('')}
                key={index}
                style={{
                  color: (index === currentIndex && index !== mappedData.length - 1) ? hoverColor : '',
                }}
                to={process.env.PUBLIC_URL + item.path}>
              <span>{item.name}</span>
            </BreadcrumbsItem>
        ))
        }
        <Breadcrumb/>


      </>

  );
};

export default MainBreadCrumb;
