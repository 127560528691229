import axios from 'axios';
import {
    X_TENANT_ID,
    SETTINGS_URL
} from '../../common/ActionUrl';

const requestOptions = {
    headers: {
        "Content-Type": "application/json",
        'X-TENANT-ID': X_TENANT_ID,
    },
}

export const updateGeneralSettings = async (id, params) => {
    return await axios.patch(SETTINGS_URL + id, params, requestOptions)
};

