import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Grid2,
  Skeleton,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import axios from 'axios'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import ItemsCard from './ItemsCard'
import { AuthContext } from '../../common/Auth'
import {
  useGetCheckoutSettingsQuery,
  useGetOrCreateCheckoutSessionQuery,
} from '../../redux/api/checkoutApi'
import { newTheme } from '../../components/theme/theme'
import { useThemeSettings } from '../../layouts/state/useThemeSettings'
import CustomerDetailsCard from './customerDetails/CustomerDetailsCard'
import {
  setDebug,
  updateSessionParams,
} from '../../redux/reducers/checkoutReducer'
import {
  selectActiveStep,
  selectCartId,
  selectDebug,
  selectHeaderOffset,
  selectParams,
  selectStatus,
  selectIsCartEmpty
} from '../../redux/selectors/checkoutSelectors'
import FulfillmentCard from './fulfillment/FulfillmentCard'
import { useLocation } from 'react-router-dom'
import DebugCard from './DebugCard'
import PaymentCard from './payment/PaymentCard'
import { CheckoutStatus, CheckoutStep } from '../../models/CheckoutSession'
import ConfirmationCard from './ConfirmationCard'
import { useGetCheckoutQuestionsQuery } from '../../redux/api/aetherApi'

export default function NewCheckout() {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const { user, settingData, ipV4, ipV4Loaded } = useContext(AuthContext)
  const { themedefaultColor, themedefaultTextColor } = useThemeSettings({
    settingData,
  })
  const cartId = useAppSelector(selectCartId)
  const isCartEmpty = useAppSelector(selectIsCartEmpty)
  const debug = useAppSelector(selectDebug)
  const params = useAppSelector(selectParams)
  const offset = useAppSelector(selectHeaderOffset)
  const status = useAppSelector((state) => selectStatus(state, params))
  const activeStep = useAppSelector((state) => selectActiveStep(state, params))

  const {
    isSuccess: sessionSuccess,
    isLoading: sessionLoading,
    error: sessionError,
  } = useGetOrCreateCheckoutSessionQuery(
    {
      userId: params?.userId ?? '',
      cartId: params?.cartId,
      ipAddress: params?.ipAddress ?? '',
    },
    { skip: !params || !ipV4Loaded || isCartEmpty },
  )
  const {
    data: settings,
    isSuccess: settingsSuccess,
    isLoading: settingsLoading,
  } = useGetCheckoutSettingsQuery(undefined, { skip: isCartEmpty })

  const { isLoading: questionsLoading, isSuccess: questionsSuccess } =
    useGetCheckoutQuestionsQuery(undefined, { skip: isCartEmpty })

  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))

  const fulfillmentRef = useRef<HTMLDivElement>(null)
  const paymentRef = useRef<HTMLDivElement>(null)
  const [debugCartId, setDebugCartId] = useState<string>()

  // Can be replaced with a selector once user ID is in Redux
  useEffect(() => {
    if (!ipV4Loaded) return
    dispatch(
      updateSessionParams({
        userId: user.userId ?? localStorage.getItem('-'),
        cartId: debugCartId,
        ipAddress: ipV4,
      }),
    )
  }, [user, ipV4, ipV4Loaded, cartId, debugCartId])

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      dispatch(setDebug(params.get('debug') === 'true'))
      setDebugCartId(params.get('cartId') ?? undefined)
    }
  }, [search])

  useEffect(() => {
    if (!sessionSuccess || !settingsSuccess) {
      return
    }
    let element = null
    if (activeStep === CheckoutStep.FULFILLMENT) {
      element = fulfillmentRef.current
    }
    if (activeStep === CheckoutStep.PAYMENT) {
      element = paymentRef.current
    }
    if (element) {
      const y =
        (element.getBoundingClientRect().top ?? 0) -
        offset +
        window.scrollY -
        16
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [activeStep, sessionSuccess, settingsSuccess, offset])

  const isLoading =
    (!sessionSuccess && !sessionError && !isCartEmpty) || settingsLoading || questionsLoading
  const isSuccess = sessionSuccess && settingsSuccess && questionsSuccess

  return (
    <ThemeProvider
      theme={newTheme({ themedefaultColor, themedefaultTextColor })}
    >
      <Container
        maxWidth={'lg'}
        sx={{
          pt: 2,
          pb: 2,
          flexGrow: 1,
          pl: xsDisplay ? '4px' : '16px',
          pr: xsDisplay ? '4px' : '16px',
        }}
      >
        <Stack direction={'column'} alignItems={'stretch'} height={'100%'}>
          <Typography variant={'h4'} gutterBottom>
            Checkout
          </Typography>
          {isCartEmpty && status !== CheckoutStatus.COMPLETED && (
            <Card>
              <Box sx={{ p: 2 }}>
                <Typography variant={'body1'}>
                  Your cart is empty. Please add items to your cart before
                  checking out.
                </Typography>
              </Box>
            </Card>
          )}
          {status === CheckoutStatus.COMPLETED && (
            <Grid2 container spacing={2}>
              <Grid2 key={'debug'} size={12}>
                {debug && <DebugCard />}
              </Grid2>
              <Grid2 key={'confirm'} size={12}>
                <ConfirmationCard />
              </Grid2>
            </Grid2>
          )}
          {status !== CheckoutStatus.COMPLETED && (
            <Grid2 container flexGrow={1} spacing={2} direction={'row-reverse'}>
              <Grid2 key={'itemscard'} size={{ xs: 12, md: 4 }}>
                <Box sx={{ position: 'sticky', top: offset + 16 }}>
                  {isLoading && <Skeleton variant="rounded" height={'100%'} />}
                  {isSuccess && <ItemsCard />}
                </Box>
              </Grid2>
              <Grid2 key={'maincontent'} size={{ xs: 12, md: 8 }}>
                <Stack height={'100%'} spacing={2}>
                  {debug && <DebugCard />}
                  {isLoading && (
                    <>
                      <Skeleton variant="rounded" height={'34%'} />
                      <Skeleton variant="rounded" height={'33%'} />
                      <Skeleton variant="rounded" height={'33%'} />
                    </>
                  )}
                  {isSuccess && (
                    <>
                      {settings?.instructionsEnabled && (
                        <Alert severity="info">
                          {settings.instructionsText}
                        </Alert>
                      )}
                      <CustomerDetailsCard />
                      <FulfillmentCard ref={fulfillmentRef} />
                      <PaymentCard ref={paymentRef} />
                    </>
                  )}
                </Stack>
              </Grid2>
            </Grid2>
          )}
        </Stack>
      </Container>
    </ThemeProvider>
  )
}
