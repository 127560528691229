import { Order } from './Order'
import { OrderItem } from './OrderItem'

export enum CheckoutStep {
  CUSTOMER_DETAILS = 'CustomerDetails',
  FULFILLMENT = 'Fulfillment',
  PAYMENT = 'Payment',
}

export enum CheckoutStatus {
  PENDING_DETAILS = 'PendingDetails',
  PENDING_FULFILLMENT_METHOD = 'PendingFulfillmentMethod',
  PENDING_PAYMENT = 'PendingPayment',
  PENDING_SUBMISSION = 'PendingSubmission',
  COMPLETED = 'Completed',
}

export interface CheckoutSession {
  _id: string
  userId: string
  cartId: string
  status: CheckoutStatus
  order: Order,
  confirmationHtml?: string,
  skipCustomerDetails?: boolean
}

export interface CheckoutSettings {
  fulfillmentRenameEnabled?: boolean
  fulfillmentRenameText?: string
  showInHandsDateField?: boolean
  ihdRenameEnabled?: boolean
  ihdRenameText?: string
  ihdRequired?: boolean
  showFulfillmentEstimate?: boolean
  instructionsEnabled?: boolean;
  instructionsText?: string;
  deliveryInstructionsEnabled?: boolean;
  deliveryInstructionsText?: string;
  paymentInstructionsEnabled?: boolean;
  paymentInstructionsText?: string;
  orderNoteEnabled?: boolean;
}
