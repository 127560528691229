import { createSelector } from 'reselect'
import { RootState } from '../hooks'
import { checkoutApi } from '../api/checkoutApi'
import {
  CheckoutSettings,
  CheckoutStatus,
  CheckoutStep,
} from '../../models/CheckoutSession'
import { GetOrCreateSessionParams } from '../api/checkoutApi'
import Decimal from 'decimal.js'
import {
  AetherPaymentRequest,
  ItemPaymentDetails,
  OrderPayment,
} from '../../models/Payment'
import { AccessType } from '../../models/AccessType'
import { PaymentMethodType } from '../../models/PaymentMethodType'
import { paymentApi } from '../api/paymentApi'
import { PaymentMethodSettings } from '../../models/PaymentMethodSettings'
import { AetherItemType } from '../../models/OrderItem'

export const selectParams = (state: RootState) => state.checkout.sessionParams

export const selectHeaderOffset = (state: RootState) =>
  state.checkout.headerOffset

export const selectSession = createSelector(
  [
    (state: RootState) => state,
    (_, params: GetOrCreateSessionParams | null) => params,
  ],
  (state, params) =>
    params != null
      ? checkoutApi.endpoints.getOrCreateCheckoutSession.select(params)(state)
          .data ?? null
      : null,
)

export const selectSettings = (state: RootState): CheckoutSettings =>
  checkoutApi.endpoints.getCheckoutSettings.select()(state).data ?? {}

export const selectPaymentSettings = (
  state: RootState,
): PaymentMethodSettings[] =>
  paymentApi.endpoints.getPaymentSettings.select()(state).data ?? []

export const selectPaymentMethods = createSelector(
  [selectPaymentSettings],
  (paymentSettings) =>
    paymentSettings
      .filter((settings) => settings.enabled)
      .map((settings) => settings.methodType),
)

export const selectOrder = createSelector(
  selectSession,
  (session) => session?.order,
)

export const selectConfirmationHtml = createSelector(
  selectSession,
  (session) => session?.confirmationHtml,
)

export const selectAetherOrderData = createSelector(
  selectOrder,
  (order) => order?.aetherData ?? null,
)

export const selectOrderDecimalsToShow = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.decimalsToShow ?? 2,
)

export const selectSubtotal = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displaySubtotal ?? 0,
)

export const selectTaxAmount = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayTax ?? 0,
)

export const selectTaxRate = createSelector(
  selectOrder,
  (order) => order?.tax.rate,
)

export const selectShippingAmount = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayShipping ?? 0,
)

export const selectTotal = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayTotal ?? 0,
)

export const selectConvenienceFee = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.displayConvenienceFee ?? 0,
)

export const selectDiscount = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.discount ?? 0,
)

export const selectItems = createSelector(
  selectSession,
  (session) => session?.order.items ?? null,
)

export const selectOrderTotals = createSelector(
  [
    selectSubtotal,
    selectShippingAmount,
    selectTaxAmount,
    selectTotal,
    selectConvenienceFee,
    selectDiscount,
  ],
  (subtotal, shipping, tax, total, convenienceFee, discount) => ({
    subtotal,
    shipping,
    tax,
    total,
    convenienceFee,
    discount,
  }),
)

export const selectStatus = createSelector(
  selectSession,
  (session) => session?.status,
)

export const selectOrderPayments = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.payments ?? [],
)

export const selectPaymentRequests = (state: RootState) =>
  state.checkout.paymentRequests

export const selectSkipCustomerDetails = createSelector(
  selectSession,
  (session) => session?.skipCustomerDetails ?? false,
)

export const selectActiveStep = createSelector(
  [selectStatus, (state: RootState) => state.checkout.editingStep],
  (status, editingStep) => {
    if (editingStep) {
      return editingStep
    } else {
      switch (status) {
        case CheckoutStatus.PENDING_DETAILS:
          return CheckoutStep.CUSTOMER_DETAILS
        case CheckoutStatus.PENDING_FULFILLMENT_METHOD:
          return CheckoutStep.FULFILLMENT
        case CheckoutStatus.PENDING_PAYMENT:
          return CheckoutStep.PAYMENT
        case CheckoutStatus.PENDING_SUBMISSION:
          return CheckoutStep.PAYMENT
        default:
          return 'CustomerDetails'
      }
    }
  },
)

export const selectQuestions = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.questions ?? null,
)

export const selectFirstName = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.firstName ?? '',
)

export const selectLastName = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.lastName ?? '',
)

export const selectEmail = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.email ?? '',
)

export const selectContactInfo = createSelector(
  [selectFirstName, selectLastName, selectEmail],
  (firstName, lastName, email) => ({ firstName, lastName, email }),
)

export const selectInHandsDate = createSelector(
  [selectOrder],
  (order) => order?.inhandDate,
)

export const selectOrderNote = createSelector(
  [selectAetherOrderData],
  (data) => data?.orderNote,
)

export const selectSessionId = createSelector(
  selectSession,
  (session) => session?._id,
)

export const selectFulfillments = createSelector(
  selectOrder,
  (order) => order?.aetherData?.fulfillments ?? [],
)

export const selectCoupon = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.coupon ?? null,
)

export const selectBalanceDue = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.displayBalanceDue ?? 0,
)

export const selectPayments = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.payments ?? [],
)

export const selectCurrency = createSelector(
  (state: RootState) => state,
  (state) => state.currencyData,
)

export const selectCartId = (state: RootState) => state.cartData.id
export const selectIsCartEmpty = (state: RootState) => (state.cartData?.cartItems?.length ?? 0) === 0

export const selectDebug = (state: RootState) => state.checkout.debug

export const selectGiftCardCodes = createSelector(
  [
    selectPaymentRequests,
    selectOrderPayments,
    (_, __, index: number | null) => index,
  ],
  (requests: AetherPaymentRequest[], payments: OrderPayment[], index) => {
    const codes = []
    for (const request of [
      ...requests.filter((_, idx) => idx !== index),
      ...payments,
    ]) {
      if (request.giftCardCode) {
        codes.push(request.giftCardCode)
      }
    }
    return codes
  },
)

export const selectWorkingItemPaymentDetails = createSelector(
  [selectPaymentRequests, selectItems, (_, __, index: number | null) => index],
  (requests, items, index) => {
    let details: ItemPaymentDetails[] = []
    for (const item of items ?? []) {
      const restriction = item.aetherData.paymentRestriction
      details.push({
        restriction,
        itemId: item.id,
        itemType: item.aetherData.aetherItemType,
        applied: item.aetherData.paymentApplied,
        total: new Decimal(item.aetherData.displayTotalPrice)
          .plus(new Decimal(item.aetherData.displayTax))
          .toNumber(),
      })
    }
    const filteredRequests = requests.filter((_, i) => i !== index)
    for (const request of filteredRequests) {
      let left = new Decimal(request.amount)
      for (const itemId of request.itemIds) {
        const itemDetail = details.find((r) => r.itemId === itemId)
        if (itemDetail) {
          const itemRemaining = new Decimal(itemDetail.total).minus(
            itemDetail.applied,
          )
          if (itemRemaining.greaterThan(0)) {
            const applied = left.greaterThan(itemRemaining)
              ? itemRemaining.toNumber()
              : left.toNumber()
            left = left.minus(applied)
            itemDetail.applied = new Decimal(itemDetail.applied)
              .plus(applied)
              .toNumber()
          }
        }
      }
    }
    return details
  },
)

export const selectWorkingConvenienceFee = createSelector(
  selectPaymentRequests,
  (requests) =>
    requests.reduce(
      (acc, request) => ({
        fee: new Decimal(request.convenienceFee ?? 0).plus(acc.fee),
        tax: new Decimal(request.convenienceFeeTax ?? 0).plus(acc.tax),
      }),
      { fee: new Decimal(0), tax: new Decimal(0) },
    ),
)

export const selectRemainingBalanceDue = createSelector(
  [
    selectBalanceDue,
    selectPaymentRequests,
    (_, __, index: number | null) => index,
  ],
  (balanceDue, requests, index) =>
    new Decimal(balanceDue)
      .minus(
        requests
          .filter((_, i) => i !== index)
          .reduce(
            (acc, request) =>
              acc
                .plus(request.amount)
                .minus(request.convenienceFee ?? 0)
                .minus(request.convenienceFeeTax ?? 0),
            new Decimal(0),
          ),
      )
      .toNumber(),
)

export const selectMethodBalanceDueMap = createSelector(
  [
    selectRemainingBalanceDue,
    selectWorkingItemPaymentDetails,
    selectPaymentMethods,
    selectPaymentSettings,
  ],
  (
    remainingBalanceDue,
    workingItemPaymentDetails,
    methodTypes,
    paymentSettings,
  ) => {
    const restrictedBalanceDue = workingItemPaymentDetails.reduce(
      (acc, r) => acc.plus(new Decimal(r.total)).minus(new Decimal(r.applied)),
      new Decimal(0),
    )
    const unrestrictedBalanceDue = new Decimal(remainingBalanceDue).minus(
      restrictedBalanceDue,
    )
    let methodDataMap: Record<
      string,
      {
        amount: Decimal
        items: { itemId: string; amount: number }[]
        restrictedItemIds: string[]
      }
    > = {}
    for (const methodType of methodTypes) {
      methodDataMap[methodType] = {
        amount: new Decimal(unrestrictedBalanceDue),
        items: [],
        restrictedItemIds: [],
      }
    }
    for (const details of workingItemPaymentDetails) {
      const restriction = details.restriction
      for (const methodType of methodTypes) {
        const settings = paymentSettings.find(
          (settings) => settings.methodType === methodType,
        )
        const methodAllowed =
          (details.itemType === AetherItemType.PRODUCT &&
            settings?.applyToProduct) ||
          (details.itemType === AetherItemType.SHIPPING &&
            settings?.applyToShipping)
        const productAllowed =
          !restriction ||
          (restriction.type === AccessType.ALLOW_LIST &&
            restriction.methodTypes.includes(methodType)) ||
          (restriction.type === AccessType.BLOCK_LIST &&
            !restriction.methodTypes.includes(methodType))
        if (methodAllowed && productAllowed) {
          const amount = new Decimal(details.total).minus(details.applied)
          methodDataMap[methodType].amount =
            methodDataMap[methodType].amount.plus(amount)
          methodDataMap[methodType].items.push({
            itemId: details.itemId,
            amount: amount.toNumber(),
          })
        } else {
          methodDataMap[methodType].restrictedItemIds.push(details.itemId)
        }
      }
    }
    return methodDataMap
  },
)

export const selectAllowedPaymentMethods = createSelector(
  [selectPaymentSettings, selectPaymentRequests, selectOrderPayments],
  (paymentSettings, paymentRequests, orderPayments) => {
    const allowedMethods = paymentSettings.filter(
      (settings) => settings.enabled,
    )
    const types = new Set(
      [...paymentRequests, ...orderPayments].map((p) => p.methodType),
    )
    return allowedMethods.filter(
      (m) => m.allowMultiple || !types.has(m.methodType),
    )
  },
)

export const selectLockChanges = createSelector(
  [selectOrderPayments, selectStatus],
  (payments, status) =>
    payments.length > 0 ||
    status === undefined ||
    [CheckoutStatus.COMPLETED, CheckoutStatus.PENDING_SUBMISSION].includes(
      status,
    ),
)

export const makeSelectMethodPaymentData = () => {
  return createSelector(
    [
      selectMethodBalanceDueMap,
      (_, __, ___, methodType: PaymentMethodType) => methodType,
    ],
    (methodBalanceDueMap, methodType) => methodBalanceDueMap[methodType],
  )
}
