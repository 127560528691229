import React, { useMemo } from 'react'
import { Card, Divider, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../redux/hooks'
import {
  selectCurrency,
  selectItems,
  selectLockChanges,
  selectOrderDecimalsToShow,
  selectOrderPayments,
  selectOrderTotals,
  selectParams,
  selectPaymentRequests,
  selectRemainingBalanceDue,
  selectStatus,
  selectWorkingConvenienceFee,
} from '../../redux/selectors/checkoutSelectors'
import { ItemListItem } from './ItemListItem'
import { CheckoutStatus } from '../../models/CheckoutSession'
import { PAYMENT_NAMES } from '../../models/PaymentMethodType'
import Decimal from 'decimal.js'
import { OrderPayment } from '../../models/Payment'
import { AetherItemType } from '../../models/OrderItem'
import CouponRow from './CouponRow'

export default function ItemsCard() {
  const params = useAppSelector(selectParams)
  const orderDecimalsToShow = useAppSelector((state) =>
    selectOrderDecimalsToShow(state, params),
  )
  const status = useAppSelector((state) => selectStatus(state, params))
  const items = useAppSelector((state) => selectItems(state, params))
  const lockChanges = useAppSelector((state) => selectLockChanges(state, params))
  const paymentRequests = useAppSelector(selectPaymentRequests)
  const orderPayments = useAppSelector((state) =>
    selectOrderPayments(state, params),
  )
  const remainingBalanceDue = useAppSelector((state) =>
    selectRemainingBalanceDue(state, params, null),
  )
  const currency = useSelector(selectCurrency)
  const {
    subtotal,
    shipping,
    tax,
    total,
    convenienceFee: orderConvenienceFee,
    discount,
  } = useAppSelector((state) => selectOrderTotals(state, params))

  const { fee: workingConvenienceFee, tax: convenienceFeeTax } = useAppSelector(
    selectWorkingConvenienceFee,
  )

  const convenienceFee = useMemo(
    () => workingConvenienceFee.plus(orderConvenienceFee),
    [workingConvenienceFee, orderConvenienceFee],
  )

  const orderCreated = useMemo(
    () =>
      status === CheckoutStatus.PENDING_PAYMENT ||
      status === CheckoutStatus.PENDING_SUBMISSION,
    [status],
  )

  const displayPayments: OrderPayment[] = [...orderPayments, ...paymentRequests]

  return (
    <Card variant="outlined" data-testid="itemcard">
      <Stack divider={<Divider flexItem />}>
        <>
          <Typography variant="h6" align="center" mt={1}>
            Items
          </Typography>
          {items
            ?.filter(
              (i) => i.aetherData.aetherItemType === AetherItemType.PRODUCT,
            )
            ?.map((item, idx) => [
              <ItemListItem
                key={idx}
                item={item}
                data-testid={'listItem' + idx}
              />,
            ])}
        </>
        <Stack direction={'column'} p={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body2">Shipping</Typography>
            <Typography variant="body2">
              {orderCreated
                ? `${currency.currencySymbol}${shipping.toFixed(
                    orderDecimalsToShow,
                  )}`
                : '-'}
            </Typography>
          </Stack>
          {convenienceFee.greaterThan(0) && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant="body2">Convenience Fee</Typography>
              <Typography variant="body2">
                {orderCreated
                  ? `${currency.currencySymbol}${convenienceFee.toFixed(
                      orderDecimalsToShow,
                    )}`
                  : '-'}
              </Typography>
            </Stack>
          )}
          {discount > 0 && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant="body2">Discount</Typography>
              <Typography variant="body2">
                -{currency.currencySymbol}
                {discount.toFixed(orderDecimalsToShow)}
              </Typography>
            </Stack>
          )}
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body2">Subtotal</Typography>
            <Typography variant="body2">
              {currency.currencySymbol}
              {new Decimal(subtotal)
                .plus(workingConvenienceFee)
                .toFixed(orderDecimalsToShow)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body2">Tax</Typography>
            <Typography variant="body2">
              {orderCreated
                ? `${currency.currencySymbol}${new Decimal(tax)
                    .plus(convenienceFeeTax)
                    .toFixed(orderDecimalsToShow)}`
                : '-'}
            </Typography>
          </Stack>
        </Stack>
        {!lockChanges && <CouponRow />}
        <Stack direction={'row'} justifyContent={'space-between'} p={2}>
          <Typography variant="body1">Total</Typography>
          <Typography variant="body1">
            {currency.currencySymbol}
            {new Decimal(total)
              .plus(workingConvenienceFee)
              .plus(convenienceFeeTax)
              .toFixed(orderDecimalsToShow)}
          </Typography>
        </Stack>
        {displayPayments.length > 0 && (
          <>
            <Stack direction={'column'} p={2}>
              {displayPayments.map((request, idx) => (
                <Stack
                  key={idx}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Typography variant="body2">
                    {PAYMENT_NAMES[request.methodType]}
                  </Typography>
                  <Typography variant="body2">
                    - {currency.currencySymbol}
                    {request.amount.toFixed(orderDecimalsToShow)}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </>
        )}
        {orderCreated && (
          <>
            <Stack direction={'row'} justifyContent={'space-between'} p={2}>
              <Typography variant="body1">Balance Remaining</Typography>
              <Typography variant="body1">
                {currency.currencySymbol}
                {remainingBalanceDue.toFixed(orderDecimalsToShow)}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  )
}
