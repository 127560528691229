import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface TestModeConfirmDialogProps {
  open: boolean
  onClose: () => void
  onContinue: () => void
}

export default function TestModeConfirmDialog({ open, onClose, onContinue }: TestModeConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {"Test Mode Enabled"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Test mode is enabled. This credit card payment is not a real transaction.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue} autoFocus>Continue</Button>
      </DialogActions>
    </Dialog>
  );
}
