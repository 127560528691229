import { Stack } from '@mui/material'
import React from 'react'
import CheckoutTextField from '../CheckoutTextField'
import { PaymentMethodFormProps } from './PaymentMethodForms'

export default function POForm({ settings }: PaymentMethodFormProps) {

  return (
    <Stack direction={'column'} spacing={2}>
      <CheckoutTextField
        name={'poNumber'}
        label={'PO Number'}
        rules={{ required: true }}
      />
    </Stack>
  )
}
