import React from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  Radio,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { Warning } from '@mui/icons-material'

interface OptionCardProps {
  iconComponent: React.ReactNode
  label: string
  selected: boolean
  onClick: () => void
  disabled?: boolean
  warningText?: string
  actions?: React.ReactNode
  children?: React.ReactNode
}

export default function OptionCard({
  iconComponent,
  label,
  selected,
  onClick,
  disabled,
  warningText,
  actions,
  children,
}: OptionCardProps) {
  const theme = useTheme()

  return (
    <Card
      sx={{
        borderColor: selected ? theme.palette.primary.main + '66' : undefined,
        backgroundColor: disabled ? grey[200] : undefined,
      }}
    >
      <ContentWrapper action={!disabled && !selected} onClick={onClick}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          m={2}
        >
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            {iconComponent}
            <Typography variant="body1">{label}</Typography>
          </Stack>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            {!disabled && (
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                {warningText && (
                  <Tooltip title={warningText}>
                    <Warning color={'warning'} />
                  </Tooltip>
                )}
                {selected && actions}
                <Radio checked={selected} />
              </Stack>
            )}
          </Stack>
        </Stack>
      </ContentWrapper>
      {selected && children}
    </Card>
  )
}

function ContentWrapper({
  action,
  onClick,
  children,
}: {
  action: boolean
  onClick: () => void
  children: React.ReactNode
}) {
  if (action) {    
    return (
      <CardActionArea onClick={onClick}>
        {children}
      </CardActionArea>
    )
  } else {
    return (
      <>{children}</>
    )
  }
}
