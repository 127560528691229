import React from "react";
import { PaymentMethodType } from "../../../models/PaymentMethodType"
import POForm from "./POForm";
import CardConnectForm from "./CardConnectForm";
import { PaymentMethodSettings } from "../../../models/PaymentMethodSettings";
import GiftCardForm from "./GiftCardForm";

export interface PaymentMethodFormProps {
  settings: PaymentMethodSettings,
  index: number | null
}

export const getPaymentForm = (methodType: PaymentMethodType, props: PaymentMethodFormProps) => {
  const map = {
    [PaymentMethodType.DIRECT_BILL]: undefined,
    [PaymentMethodType.PURCHASE_ORDER]: <POForm {...props} />,
    [PaymentMethodType.CARDCONNECT]: <CardConnectForm {...props} />,
    [PaymentMethodType.GIFT_CARD]: <GiftCardForm {...props} />,
  }
  return map[methodType];
}