import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form-mui'
import { useEffect, useMemo } from 'react'
import { Button, Grid2, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import CheckoutTextField from './CheckoutTextField'
import { LoadingButton } from '@mui/lab'
import {
  useGetCountriesQuery,
  useLazyGetStatesQuery,
} from '../../redux/api/advanceApi'
import CheckoutDropdown from './CheckoutDropdown'
import GridSkeleton from '../../common/GridSkeleton'

interface AddressFormProps {
  label: string,
  formPrefix?: string
  loading?: boolean
  showCancel?: boolean
  onSave: () => void
  onCancel?: () => void
}

export default function AddressForm({
  label,
  formPrefix,
  loading,
  showCancel,
  onSave,
  onCancel
}: AddressFormProps) {
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))

  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`
    } else {
      return ''
    }
  }, [formPrefix])

  const { data: countries, isLoading: loadingCountries, isSuccess: countriesSuccess } =
    useGetCountriesQuery()

  const [getStates, { data: states, isFetching: loadingStates }] =
    useLazyGetStatesQuery()

  const { setValue, trigger } = useFormContext()

  const countryValue: string = useWatch({ name: `${effectivePrefix}address.country` })

  useEffect(() => {
    if (countryValue) {
      getStates(countryValue)
    }
  }, [countryValue])

  useEffect(() => {
    setValue(`${effectivePrefix}address.country`, 'US')
  }, [countriesSuccess]);

  const handleSave = async () => {
    const result = await trigger([`${effectivePrefix}address`, `${effectivePrefix}contact`])
    if (result) {
      onSave()
    }
  }

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant="body1">{label} Address</Typography>
      {loadingCountries && (
        <GridSkeleton size={6} quantity={12} />
      )}
      {!loadingCountries && (
        <>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.firstName`}
                label="First Name"
                rules={{ required: true }}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.lastName`}
                label="Last Name"
                rules={{ required: true }}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.email`}
                label="Email"
                rules={{ required: true }}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.phone`}
                label="Phone"
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField name={`${effectivePrefix}contact.company`} label="Company" />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField name={`${effectivePrefix}contact.attentionTo`} label="Attention To" />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.street1`}
                label="Address Line 1"
                rules={{ required: true }}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField name={`${effectivePrefix}address.street2`} label="Address Line 2" />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.city`}
                label="City"
                rules={{ required: true }}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.postalCode`}
                label="Postal Code"
                rules={{ required: true }}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutDropdown
                name={`${effectivePrefix}address.country`}
                label="Country"
                options={
                  countries?.map((c) => ({ id: c.code, label: c.name })) ?? []
                }
                rules={{ required: true }}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutDropdown
                name={`${effectivePrefix}address.state`}
                label="State"
                options={states?.map((s) => ({ id: s.code, label: s.name })) ?? []}
                rules={{ required: true }}
                loading={loadingStates}
              />
            </Grid2>
          </Grid2>
          <Stack direction={'row'} justifyContent={'center'} mt={2} spacing={2}>
            {showCancel && (
              <Button
                variant='outlined'
                onClick={onCancel}
                fullWidth={xsDisplay}
              >
                Cancel
              </Button>
            )}
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleSave}
              loading={loading}
              fullWidth={xsDisplay}
            >
              Submit
            </LoadingButton>
          </Stack>
        </>
      )}
    </Stack>
  )
}
