import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { OrderItem } from '../models/OrderItem'
import { SerializedError } from '@reduxjs/toolkit'
import {
  CheckoutQuestion,
  CheckoutQuestionForm,
} from '../models/CheckoutQuestion'
import { Question } from '../models/Question'
import { Address } from '../models/Address'
import { Fulfillment } from '../models/Order'
import moment from 'moment'
import Decimal from 'decimal.js'
import { PaymentMethodSettings } from '../models/PaymentMethodSettings'

export function calculateConvenienceFee(amount: Decimal, percentage: number, taxable: boolean, taxRate: number) {
  const fee = amount.times(new Decimal(percentage)).div(100).toDP(2)
  if (taxable) {
    const tax = fee.times(new Decimal(taxRate)).div(100).toDP(2)
    return { tax, fee }
  } else {
    return { tax: new Decimal(0), fee }
  }
}

export function getItemColorText(item: OrderItem): string {
  const set = new Set(item.rows.map((r) => r.color))
  if (set.size === 1) {
    return item.rows[0].color
  } else {
    return 'Multiple'
  }
}

export function getItemSizesText(item: OrderItem): string {
  const set = new Set(item.rows.map((r) => r.size))
  if (set.size === 1) {
    return item.rows[0].size
  } else {
    return 'Multiple'
  }
}

export function getAddressString(address: Address): string {
  if (!address) return 'N/A'
  return `${address.street1}, ${address.street2 ? `${address.street2}, ` : ''}${
    address.city
  }, ${address.state} ${address.postalCode}`
}

export function getEtaString(fulfillment: Fulfillment): string {
  if (
    fulfillment.minFulfillmentDate &&
    fulfillment.maxFulfillmentDate &&
    fulfillment.minFulfillmentDate !== fulfillment.maxFulfillmentDate
  ) {
    return `${moment(fulfillment.minFulfillmentDate).format(
      'M/D/YY',
    )} - ${moment(fulfillment.minFulfillmentDate).format('M/D/YY')}`
  } else if (fulfillment.minFulfillmentDate) {
    return moment(fulfillment.minFulfillmentDate).format('M/D/YY')
  } else if (fulfillment.maxFulfillmentDate) {
    return moment(fulfillment.maxFulfillmentDate).format('M/D/YY')
  } else {
    return 'N/A'
  }
}

export function getError(
  error: FetchBaseQueryError | SerializedError | undefined,
) {
  if (error) {
    if ('data' in error) {
      const message = (error as any).data?.message
      if (typeof message === 'string') {
        return message
      } else if (Array.isArray(message)) {
        return message.join(', ')
      }
    }
  }
  return 'An error occurred'
}

export function getFormQuestions(
  questions: Question[],
  checkoutQuestions: CheckoutQuestion[],
): CheckoutQuestionForm[] {
  return questions.map((q) => {
    const existingQuestion = checkoutQuestions.find(
      (cq) => cq.questionId === q._id,
    )
    return {
      ...q,
      questionId: q._id,
      choiceValue: existingQuestion?.choiceValue ?? '',
      value: existingQuestion?.value ?? '',
      choices: q.choices,
    }
  })
}

export function getItemIds(items: { itemId: string, amount: number }[], amount: number) {
  let left = new Decimal(amount)
  const itemIds: string[] = []
  for (const item of items) {
    if (left.greaterThan(0)) {
      left = left.minus(item.amount)
      itemIds.push(item.itemId)
    } else {
      break;
    }
  }
  return itemIds;
}

export function getRestrictedPaymentMessage(settings: PaymentMethodSettings) {
  if (!settings.applyToProduct) {
    return 'This payment method cannot be applied to products.'
  } else if (!settings.applyToShipping) {
    return 'This payment method cannot be applied to shipping.'
  } else {
    return 'Some items cannot be paid with this method.'
  }
}

export function isCanadaCountry(value: string) {
  return value === 'CA' || value === 'Canada'
}
