import React, { useMemo } from "react"
import { useFormContext, useWatch } from "react-hook-form-mui"

export default function useAddressFormValues({ formPrefix }: { formPrefix?: string }) {
  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`
    } else {
      return ''
    }
  }, [formPrefix])

  const { getValues } = useFormContext()
  const names = [`${effectivePrefix}address`, `${effectivePrefix}addressSource`, `${effectivePrefix}savedAddressId`, `${effectivePrefix}method`, `${effectivePrefix}contact`];

  const [] = useWatch({ name: names });
  const [address, addressSource, savedAddressId, method, contact] = getValues(names);

  return {
    address,
    addressSource,
    savedAddressId,
    method,
    contact
  }
}