import React, { useEffect, useState } from 'react'
import { FulfillmentForm } from './FulfillmentActiveContent'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectFulfillments,
  selectParams,
  selectStatus,
} from '../../../redux/selectors/checkoutSelectors'
import {
  CheckoutStatus,
} from '../../../models/CheckoutSession'
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod'
import AddressSelector from '../AddressSelector'
import FulfillmentQuoteContent from './FulfillmentQuoteContent'

interface ShippingMethodContentProps {
  index: number
  onSubmit: (data: FulfillmentForm) => Promise<void>
  error?: string
  submitLoading: boolean
}

export default function ShippingMethodContent({
  index,
  onSubmit,
  error,
  submitLoading,
}: ShippingMethodContentProps) {
  const params = useAppSelector(selectParams)
  const status = useAppSelector((state) => selectStatus(state, params))
  const fulfillments = useAppSelector((state) =>
    selectFulfillments(state, params),
  )
  const [editingAddress, setEditingAddress] = useState(true)
  const [editingContact, setEditingContact] = useState(false)

  useEffect(() => {
    console.log(status, fulfillments?.[0]?.method?.type)
    if (
      fulfillments?.[0]?.method?.type === FulfillmentMethodType.SHIPPING
    ) {
      setEditingAddress(false)
    }
  }, [status])

  const handleEditAddress = (value: boolean) => {
    setEditingAddress(value)
  }

  const handleEditContact = (value: boolean) => {
    setEditingContact(value)
  }

  return (
    <>
      <AddressSelector
        label="Shipping"
        formPrefix={`fulfillments.${index}`}
        editingAddress={editingAddress}
        setEditingAddress={handleEditAddress}
        editingContact={editingContact}
        setEditingContact={handleEditContact}
      />
      <FulfillmentQuoteContent
        index={index}
        label="Shipping"
        hide={editingAddress || editingContact}
        type={FulfillmentMethodType.SHIPPING}
        onSubmit={onSubmit}
        error={error}
        submitLoading={submitLoading}
      />
    </>
  )
}
