import React, { useState } from "react"
import { FulfillmentMethodType } from "../../../models/FulfillmentMethod";
import { FulfillmentForm } from "./FulfillmentActiveContent";
import FulfillmentQuoteContent from "./FulfillmentQuoteContent";
import AddressSelector from "../AddressSelector";

interface PickupMethodContactProps {
  index: number;
  onSubmit: (data: FulfillmentForm) => Promise<void>;
  error?: string;
  submitLoading: boolean;
}

export default function PickupMethodContact({ index, onSubmit, submitLoading, error }: PickupMethodContactProps) {
  const [editingContact, setEditingContact] = useState(false);

  return (
    <>
      <AddressSelector
        label="Pickup"
        formPrefix={`fulfillments.${index}`}
        editingContact={editingContact}
        setEditingContact={setEditingContact}
      />
      <FulfillmentQuoteContent
        index={index}
        label="Pickup"
        hide={editingContact}
        type={FulfillmentMethodType.PICKUP}
        submitLoading={submitLoading}
        error={error}
        onSubmit={onSubmit}
      />
    </>
  )
}
