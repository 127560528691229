import { OtpRequest } from '../../models/Otp'
import { aetherApi } from './aetherApi'

const otpApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    validateOtp: builder.mutation<void, OtpRequest>({
      query: (params) => ({
        url: `api/v1/otp/validate`,
        body: params,
        method: 'POST',
      }),
    }),
  }),
})

export const { useValidateOtpMutation } = otpApi
