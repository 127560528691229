import { Address, AddressContact } from '../../models/Address'
import { CheckoutQuestion } from '../../models/CheckoutQuestion'
import { CheckoutSession, CheckoutSettings } from '../../models/CheckoutSession'
import { RemoveCouponRequest, ValidateCouponRequest } from '../../models/Coupon'
import {
  AddressSource,
  FulfillmentQuote,
  GetFulfillmentQuoteParams,
} from '../../models/FulfillmentQuote'
import { SubmitPaymentsRequest, SubmitPaymentsResponse } from '../../models/Payment'
import { RootState } from '../hooks'
import { clearPaymentRequests } from '../reducers/checkoutReducer'
import { aetherApi } from './aetherApi'

export interface GetOrCreateSessionParams {
  cartId?: string
  ipAddress?: string
  userId: string
}

export type UpdateCustomerDetails = SessionDetails & {
  firstName: string
  lastName: string
  orderNote?: string
  questions: CheckoutQuestion[]
}

export type UpdateFulfillmentDetails = SessionDetails & {
  fulfillmentRequests: {
    quoteId: string
    methodId: string
    contact: AddressContact
    addressSource?: AddressSource
    address?: Address
    savedAddressId?: string
  }[]
}

export interface SessionDetails {
  sessionId: string,
  userId: string
}

const onQueryStarted = async ({ }, { dispatch, queryFulfilled, getState }: any) => {
  try {
    const { data } = await queryFulfilled
    if (data) {
      const params = getState().checkout.sessionParams
      if (!params) return;
      dispatch(
        checkoutApi.util.updateQueryData(
          'getOrCreateCheckoutSession',
          params,
          (draft) => {
            Object.assign(draft, data)
          },
        ),
      )
      dispatch(clearPaymentRequests())
    }
  } catch { }
};

export const checkoutApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrCreateCheckoutSession: builder.query<
      CheckoutSession,
      GetOrCreateSessionParams
    >({
      query: (body) => {
        return {
          url: `api/v2/checkout/session`,

          method: 'POST',
          body,
        }
      },
      providesTags: ['CheckoutSession'],
    }),
    updateCustomerDetails: builder.mutation<
      CheckoutSession,
      UpdateCustomerDetails
    >({
      query: (body) => ({
        url: `api/v2/checkout/customer-details`,
        method: 'POST',
        body,
      }),
      onQueryStarted,
    }),
    updateFulfillmentDetails: builder.mutation<
      CheckoutSession,
      UpdateFulfillmentDetails
    >({
      query: (body) => ({
        url: `api/v2/checkout/fulfillment-details`,
        method: 'POST',
        body,
      }),
      onQueryStarted,
    }),
    submitPayments: builder.mutation<
      SubmitPaymentsResponse,
      SubmitPaymentsRequest
    >({
      query: (body) => ({
        url: `api/v2/checkout/submit-payments`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            const { session } = data;
            const params = (getState() as RootState).checkout.sessionParams
            if (!params) return;
            dispatch(
              checkoutApi.util.updateQueryData(
                'getOrCreateCheckoutSession',
                params,
                (draft) => {
                  Object.assign(draft, session)
                },
              ),
            )
          }
        } catch { }
      },
    }),
    submitOrder: builder.mutation<
      CheckoutSession,
      SessionDetails
    >({
      query: (body) => ({
        url: `api/v2/checkout/submit-order`,
        method: 'POST',
        body,
      }),
      onQueryStarted,
    }),
    createFulfillmentQuote: builder.mutation<
      FulfillmentQuote,
      GetFulfillmentQuoteParams
    >({
      query: (params) => ({
        url: `api/v2/checkout/fulfillment-quote`,
        method: 'POST',
        body: params,
      }),
    }),
    getCheckoutSettings: builder.query<CheckoutSettings, void>({
      query: () => ({
        url: `api/v2/checkout/settings`,
        method: 'GET',
      }),
    }),
    sendOtp: builder.mutation<{ id: string }, SessionDetails>({
      query: (body) => ({
        url: `api/v2/checkout/send-otp`,
        method: 'POST',
        body,
      }),
    }),
    applyCoupon: builder.mutation<CheckoutSession, ValidateCouponRequest>({
      query: (body) => ({
        url: `api/v2/checkout/apply-coupon`,
        method: 'POST',
        body
      }),
      onQueryStarted
    }),
    removeCoupon: builder.mutation<CheckoutSession, RemoveCouponRequest>({
      query: (body) => ({
        url: `api/v2/checkout/remove-coupon`,
        method: 'POST',
        body
      }),
      onQueryStarted
    }),
    getEnabled: builder.query<boolean, void>({
      query: () => ({
        url: `api/v2/checkout/enabled`,
        method: 'GET'
      })
    })
  }),
})

export const {
  useGetOrCreateCheckoutSessionQuery,
  useUpdateCustomerDetailsMutation,
  useCreateFulfillmentQuoteMutation,
  useUpdateFulfillmentDetailsMutation,
  useSubmitOrderMutation,
  useSubmitPaymentsMutation,
  useGetCheckoutSettingsQuery,
  useSendOtpMutation,
  useApplyCouponMutation,
  useRemoveCouponMutation,
  useGetEnabledQuery
} = checkoutApi
