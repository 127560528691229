import PropTypes from "prop-types";
import React, {Fragment, useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    getProductCartQuantity,
    showCurrencycodeSymbol,
    getStock,
    getPersColor,
    getPersFont,
    getPersLocation,
    getPersNote,
	getminQuantity,
    roundedPrice,
    getPriceByColorSizeQty,
    getMoqNote,
    getShowMoqNote
} from "../../helpers/product";
import {addToCart} from "../../redux/actions/cartActions";
import {addToWishlist} from "../../redux/actions/wishlistActions";
import {addToCompare} from "../../redux/actions/compareActions";
import {
    fetchDecorationLocations,
    fetchArtworksById,
    fetchProductStock,
    receiveDecorationLocations
} from "../../redux/actions/productActions";
import Rating from "./sub-components/ProductRating";
import {AuthContext} from "../../common/Auth"
import AddDecoration from "./sub-components/AddDecoration"
import Button from "@material-ui/core/Button/Button";
import {Table} from "react-bootstrap";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import * as productsAPIUtil from "../../util/products/productsAPIUtil"
import * as advanceAPIUtil from "../../util/advance/advanceAPIUtil"
import AddnewArtDialogue from "../artwork/AddnewArtDialogue"
import {getDefaultSize, getDefaultColor} from "../../redux/actions/cartActions"
import Loader from "../../common/Loader"
import {useHistory} from "react-router-dom";
import { USERTYPES } from '../../constant/userType'
import {  replaceOrigin } from "../../util/helper";
import { getShowProductPrice } from '../../helpers/product'
import { X_TENANT_ID } from "../../common/ActionUrl";
import axios from "axios";
import SharedModal from "../../wrappers/modal/SharedModal";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import { useProductDetailState } from "../../pages/shop-product/state/useProductDetailState";
import PriceTable from "../../wrappers/product/productPrice/PriceTable";
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import Select from 'react-select'
import { useProductArtworkState } from "./state/useProductArtworkState";
import { useProductPriceToShow } from "./state/useProductPriceToShow";
import ProductPriceCharges from "./sub-components/ProductPriceCharges";
import { addOnChargesPersBody } from "../../common/AdvanceBodies";

const ProductDescriptionInfoPromotional = ({
                                               product,
                                               discountedPrice,
                                               currency,
                                               finalDiscountedPrice,
                                               finalProductPrice,
                                               cartItems,
                                               wishlistItem,
                                               compareItem,
                                               addToast,
                                               addToCart,
                                               setToggle,
                                               toggle,
                                               setSelectedDecorations,
                                               addToWishlist,
                                               addToCompare,
                                               fetchDecorationLocations,
    											fetchArtworksById,
                                               decorationLocations,
                                               setSelectedColor,
                                               selectedColor,
    											artworkListById,
                                               showSell,
                                               showSellPrice,
                                               offerPrice,
                                               discountType,
    finalProductDiscPrice,
    setSingleSelectedDecorations,
    setSelectedImage,
    selectedImage,
    setSwipeimageFlag,
    singleSelectedDecorations,
    setTempValueCheck,
    tempValueCheck,
    isModel = false,
    fetchProductStock,
    tempfun }) => {
		const {
			settingData,
			themeSettingsData,
			storeDetails,
			user,
			isDirectToCart,
			userGroupDiscount,
			showAddToCartOnProd,
			decoTypeList,
            ipV4,
			userType,
            usePointsAsCurrency
		} = useContext(AuthContext)
    const [temp, setTemp] = useState(0);
    const [loading, setLoading] = useState(false);
    const [productDstInventory, setProductDstInventory] = useState(null);
	const [isReplace,setIsReplace]=useState(true)
	const [searchTextArtwork,setTextSearchArtwork]=useState('')
	const [isSearchArtwork,setIsSearchArtwork]=useState(false)
	const [personalizationCost, setPersonalizationCost] = useState({
		matchOrderQty: 0,
		price: 0,
		setupPrice:0,
        chargeId: ''
	  })
  const [customDecorationFee,setCustomDecorationFee]=useState(false)
  const [allowCustomDecoration,setAllowCustomDecoration]=useState(false)
  const [customDecorationPerUnitPrice,setCustomDecorationPerUnitPrice]=useState(null)
  const [customDecorationSetupFee,setCustomDecorationSetupFee]=useState(null)
  const [personalizationPerUnitPrice,setPersonalizationPerUnitPrice]=useState(null)
  const [personalizationSetupFee,setPersonalizationSetupFee]=useState(null)
  const [allowOneDec, setallowOneDec] = useState('')
  const [RequiredDecorationForCheckOutPro, setRequiredDecorationForCheckOutPro] = useState(false)
  const [IsAllowOutOfStockOrder, setIsAllowOutOfStockOrder] = useState(false)
  const [finalPrice, setFinalPriceProduct] = useState(Number(0))
  const disIndProductPrice = getShowProductPrice(product)
  const [moqNote, setMoqNote] = useState('Minimum order quantity for this item is [MOQ]');
  const [showMoqNote, setShowMoqNote] = useState(false);
  const [updatePriceTable, setUpdatePriceTable] = useState([])
  const [productSettingShowDescription, setProductSettingShowDescription] = useState(true)
  const [storeArtwork, setStoreArtwork] = useState([])
  const [groupArtwork, setGroupArtwork] = useState([])
  const [categoryArtwork, setCategoryArtwork] = useState([])
  const [isAdded, setIsAdded] = useState(false)
  const [decorationListOrder, setDecorationListOrder] = useState(false);
  const [preventAutoAttach, setPreventAutoAttach] = useState(false)
  const [showPersonalizationCostInCart, setShowPersonalizationCostInCart] = useState(false);
  const [qtyDropDown, setQtyDropDown] = useState(false)
  const [qtyValues, setQtyValues] = useState([])
  const [personalizationDropDown, setPersonalizationDropDown] = useState(false)
  const [requiredLinesPersonalization, setRequiredLinesPersonalization] = useState('')
  const [dropDownValues, setDropDownValues] = useState([])
  const [colorLabel, setColorLabel] = useState('Color')
  const [sizeLabel, setSizeLabel] = useState('Size')
  const [decimalsToShow, setDecimalsToShow] = useState(2);
  const [isStockFor, setIsStockFor] = useState(null)
  const [appliedFirstDeco, setAppliedFirstDeco] = useState(false)
  const [defaultSizeForAll, setDefaultSizeForAll] = useState([])
  const [fetchedInvProductId, setFetchedInvProductId] = useState('')
  const [hideDecorationName, setHideDecorationName] = useState(null)


    const {
    productSettings, 
    isAllowoutofStockOrder,
    inventorySettings,
    isStock,
  } = useThemeSettingsData({themeSettingsData})

  const {
    priceDisplay,
    itempriceDisplay,
    showCompareToggle,
    showWishListToggle,
    artworkSetting,
    sortSizeSetting
  } = useThemeSettings({settingData})

    const {
        getPointsFromValue,
        getPointsValue
    } = useProductPriceToShow({ productSettings, product })

    const artworksListState = useSelector((state) => state.productData.artworkList);
    const artworksProductState = useSelector((state) => state.productData.artworkListById);
    const artworksFromState = [...artworksListState, ...artworksProductState]
    const productStockState = useSelector((state) => state.productData.productStock);


    useEffect(() => {
        if (sortSizeSetting?.length) {
            setDefaultSizeForAll(sortSizeSetting.map(item => item.value.toLowerCase()));
        }
    }, [sortSizeSetting])

  useEffect(() => {
    // console.log('productStockState: ', productStockState, " ==== isModel === ", isModel,  " ==== fetchedInvProductId === ", fetchedInvProductId,  " ==== product.id === ", product.id );
    if((!!!productStockState || (isModel && (fetchedInvProductId === ""  || (!!fetchedInvProductId && fetchedInvProductId !== product.id)))) && isShowInventoryEnabled) {
        console.log('iscalll: ');
        fetchProductStock(product.id)
      setFetchedInvProductId(product.id)
    }
  },[productStockState, product])
  
  const colorStyles = {
    container: (styles) => ({...styles, textAlign: 'left', 
    }),
    input: (styles) => ({...styles,  position: "absolute",
      top: "0",
      width: "100%",
      height: "40px",
      left: "0",
      padding: "0",
      margin: "0",
      display: "block",
      '& > input' : {
        position: "absolute",
        top: "-4px",
        height: "40px",
        left: "0",
        paddingLeft: "10px !important",
      },
    }),
    singleValue : (styles) => ({...styles,
      top:'3px',
      position: "relative",
    }),
    placeholder : (styles) => ({...styles,
      top:'3px',
      position: "relative",
    }),
    control: (styles) => ({ ...styles, backgroundColor: 'transparent',
      border: '1px solid #ebebeb',
      color: '#333',
      fontSize: '14px',
      position: "relative",
      borderRadius:0,
      height:"38px",
      textAlign:"left !important",
      '& > div' : {
        height: "100% !important",
        display: "block !important",
        overflow: "unset !important",
      },
    }),
  };

  useEffect(() => {
	setFinalPriceProduct(Number(finalProductPrice))
  },[finalProductPrice])

  useEffect(() => {
    if(product?.personalizationDropDown === true || product?.personalizationDropDown === false) {
      setPersonalizationDropDown(product?.personalizationDropDown)
      setRequiredLinesPersonalization(product?.requiredLinesPersonalization)
      setDropDownValues(product?.dropDownValues)
    } else if(productSettings?.personalizationDropDown === true || productSettings?.personalizationDropDown === false) {
      setPersonalizationDropDown(productSettings?.personalizationDropDown)
      setRequiredLinesPersonalization(productSettings?.requiredLinesPersonalization)
      setDropDownValues(productSettings?.dropDownValues)
    }
    if (product?.hideDecorationName === true || product?.hideDecorationName === false) {
        setHideDecorationName(product?.hideDecorationName)
    } else {
        setHideDecorationName(!!productSettings?.hideDecorationName ? productSettings.hideDecorationName : false)
    }
    if (product?.hideLocationName === true || product?.hideLocationName === false) {
        setHideLocationName(product?.hideLocationName)
    } else {
        setHideLocationName(!!productSettings?.hideLocationName ? productSettings.hideLocationName : false)
    }
  },[product, productSettings])

  useEffect(() => {
    if(!!product?.relabelColor) {
        setColorLabel(product.relabelColor)
    }
    if(!!product?.relabelSize) {
        setSizeLabel(product.relabelSize)
    }
  },[product])

    const history = useHistory();
    let itemFromCart = null;
    let tempitemFromCart = cartItems?.filter(item => item.id === product.id)
    if (tempitemFromCart?.[0]) { itemFromCart = tempitemFromCart[0] }


    useEffect(() => {
        if(product && !!product?.decorationListOrder) {
          setDecorationListOrder(product?.decorationListOrder)
        }
    }, [product])

    useEffect(()=>{
        if ( product?.preventAutoAttach === true || product?.preventAutoAttach === false ) {
          setPreventAutoAttach(product?.preventAutoAttach)
        } else {
          setPreventAutoAttach(!!productSettings?.preventAutoAttach ? productSettings.preventAutoAttach : false)
        }
    }, [product, productSettings])

    let groups = [];
    let colors = [];
    let priceTable = [];
    let colors_final = [];
    let colors_ids = [];
    let colors_hex = [];
    let colors_idsColor = [];
    let priceTableTemp = []

    product?.ProductPartArray?.ProductPart && product.ProductPartArray.ProductPart.forEach(part => {
        let priceOption = (part);
        const size = part.ApparelSize.labelSize;
        if (!groups[size]) {
            groups[size] = [];
        }
        groups[size].push(priceOption);

        const color = part.ColorArray.Color.colorName;
        colors_ids[part.partPrice.partId] = color;
        colors_idsColor[color] = part.partPrice.partId;
        colors_hex[color] = part.ColorArray.Color.hex;

        if (!colors[color]) {
            colors[color] = [];
        }
    });
    for (let size in groups) {
        priceTableTemp.push({size: size, priceOptions: groups[size]})
    }

    priceTableTemp = priceTableTemp.sort((a,b) => {
        return defaultSizeForAll.indexOf(a.size.toLowerCase()) - defaultSizeForAll.indexOf(b.size.toLowerCase());
    })
    let priceTableTemp1 = []
    let priceTableTemp2 = []

    priceTableTemp.map((a) => {
        if(defaultSizeForAll.indexOf(a.size.toLowerCase()) !== -1) {
            priceTableTemp1.push(a)
        } else {
            priceTableTemp2.push(a)
        }
    })

    if(priceTableTemp2.length > 0) {
        priceTableTemp1 = priceTableTemp1.concat(priceTableTemp2)
    }
    
    priceTableTemp1.map(res => {
        priceTable.push(res)
    })
    for (let color in colors) {
        colors_final.push(color);
    }
    const numericSort = (a, b) => {
        const aNumeric = parseInt(a);
        const bNumeric = parseInt(b);
        return aNumeric - bNumeric;
    };
    colors_final = colors_final.sort(numericSort);
    const [selectedProductColor, setSelectedProductColor] = useState("");
    const [showAddnewart, setAddnewart] = useState(false);
    const [addDecorations, setAddDecorations] = useState(false);
    const [selectedProductLocation, setSelectedProductLocation] = useState("");
    const [selectedProductLocationIdDis, setselectedProductLocationIdDis] = useState("");
    const [selectedProductArtwork, setSelectedProductArtwork] = useState("");
    const [selectedProductDecorations, setSelectedProductDecorations] = useState([]);
    const [locationLabel, setLocationLabel] = useState('')
    const [showLocationLabel, setShowLocationLabel] = useState(true)
    const [locationPrompt, setLocationPrompt] = useState('')
    const [personalizationArray, setPersonalizationArray] = useState(null);
    const [selectedProductSize, setSelectedProductSize] = useState();
    const [productStock, setProductStock] = useState(
        product.variation ? product.variation[0].size[0].stock : product.stock
    );
    const [quantityCount, setQuantityCount] = useState(1);
	const [isMoq,setIsMoq]=useState(false);
	const [isPriceBreak, setIsPriceBreak] = useState(false)
	const [isSkuMoq,setIsSkuMoq]=useState(false);
	const [isRestrictQty,setIsRestrictQty]=useState(false)
	const [partPrice,setPartPrice]=useState([]);
	const [minQty,setMinQty]=useState("1");
	const [minAllQty,setMinAllQty]=useState([])
	const [productId, setProductId] = useState(null)
	const [prodId,setProdId]=useState(null)
    const productCartQty = getProductCartQuantity(
        cartItems,
        product,
        selectedProductColor,
        selectedProductSize
    );
	const [productSize,setProductSize]=useState({})
    const [decorationLocation, setDecorationLocations] = useState([]);
    const [uniqueLocation, setUniqueLocation] = useState('');
    const [showDecoPopup, setShowDecoPopup] = useState(false);
	const [indexPopup, setIndexPopup] = useState([])
	const [mainArrayKeyPopup, setMainArrayKeyPopup] = useState([])
	const [itemsRowsPopup, setItemsRowsPopup] = useState([])
	const [popupText, setPopupText] = useState('');
    const [artworkLocations, setArtworkLocations] = useState([]);
    const [showColor, setShowColor] = useState(false);
    const [showSize, setShowSize] = useState(false);
    const [loadingArtworks, setLoadingartworks] = useState(false);
    const [showDecorationCostInCart, setShowDecorationCostInCart] = useState(false);
    const [showSetupChargeInCart, setShowSetupChargeInCart] = useState(false);
    const [priceTables, setPriceTable] = useState([])
    const [isFirstTime, setIsFirstTime] = useState(true)
    const [hideLocationName, setHideLocationName] = useState(null)
    const dispatch = useDispatch()


    const {
        checkMinQty,
        orderAction,
        getDecorationCost,
        getSettingStatus,
        getCartItemTotal,
        getLocations,
      } = useProductConfigState({
        cartItems,
        product,
        artworksFromState,
        artworksProductState,
        allowCustomDecoration,
        selectedProductColor,
        selectedProductDecorations,
        selectedProductLocation,
        artworkSetting,
    })

    if (!selectedProductLocation && decorationLocation?.length === 1) {
        let locationCount = artworkListById.filter(
            (val) =>
            val.artworkLocation == decorationLocation?.[0]?.locationName,
        )
        if (locationCount > 1) {
        } else {
            setSelectedProductLocation(decorationLocation?.[0]?.id);
			setUniqueLocation(decorationLocation?.[0]?.locationName);
        }
    }

    useEffect(() => {
        if (product) {
            if (product.WebstoreProductPartArray?.length) {
                const partPriceArray = product.WebstoreProductPartArray.find(x =>
                    x.active &&
                    (selectedColor && selectedColor !== colorLabel ? x.color === selectedColor : x.color === colors_final[0]) &&
                    (selectedProductSize && selectedProductSize !== sizeLabel ? x.size === selectedProductSize : true)
                );
                if (partPriceArray) {
                    const priceTable = partPriceArray.partPrice?.PartPriceArray?.PartPrice ?? []
                    setPriceTable(priceTable)
                    setQtyValues(priceTable.map(x => x.minQuantity))
                }
            } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
                const defaultColor = colors_final?.[0] || '';
                const selectedColorToCompare = selectedColor || defaultColor;
                const selectedSizeToCompare = selectedProductSize || '';
                const part = product.ProductPartArray.ProductPart.find(part => {
                    const colors = part?.ColorArray?.Color?.colorName ?? part.color;
                    const size = part?.ApparelSize?.labelSize ?? part.size;
                    return selectedColorToCompare.toLowerCase() === colors.toLowerCase() &&
                        (selectedSizeToCompare === '' || selectedSizeToCompare === size);
                });
                if (part) {
                    const priceTable = part.partPrice?.PartPriceArray?.PartPrice ?? [];
                    setPriceTable(priceTable);
                    setQtyValues(priceTable.map(x => x.minQuantity));
                }
            }
            setQtyDropDown(!!product.qtyDropDown)
        }
    }, [product, selectedColor, selectedProductSize])
    const {
        selectDecoration, getDecoPrice
    } = useProductArtworkState({
        artworksFromState,
        getSettingStatus,
        setLoading: (loading) => setLoading(loading)
    })

    useEffect( ()=>{
        if (artworksFromState?.length && selectedProductColor && !appliedFirstDeco) {
            setTimeout( async ()=>{
                setAppliedFirstDeco(true)
                await applyFirstColor(colors_final.length === 1 ? colors_final[0] : selectedProductColor)
            }, 1)
        }

    }, [ artworksProductState])

    const applyFirstColor = async (color)=>{
        let getImage = product?.WebstoreProductPartArray.filter((val)=>val.color === color && val.isSwapImage === true,)
        let tempDecoArray = []
        if ( getImage.length === 0 ) {
            tempDecoArray = await selectDecoration(color)
            setIsReplace(true)
        } else {
            setIsReplace(false)
        }
        if ( tempDecoArray.length ) {
            setSingleSelectedDecorations(tempDecoArray[tempDecoArray.length - 1])
            setSelectedProductDecorations(tempDecoArray)
            setTemp(temp + 1)
        }

    }

    useEffect(() => {
        if(productStockState) {
            setProductDstInventory(productStockState.dstInventory)
            setProductInventory(productStockState.inventory)
        }
    }, [productStockState])

    const getStoreArtwork = () => {
		const requestOptions = {
			headers: {
				'Content-Type': 'application/json',
				'X-TENANT-ID': X_TENANT_ID,
			},
		}
		axios
			.get('/api/v1/artworkdata/', requestOptions)
			.then((res) => {
				if (res.data.error) {
					addToast(res.data.message, {
						appearance: 'error',
						autoDismiss: true,
					})
				} else {
					setStoreArtwork(res.data)
				}
			})
			.catch((err) => {
				console.log('err', err)
			})
	}

	useEffect(() => {
		getStoreArtwork()
        getArtworkLocations()
	}, [])

    const getArtworkLocations = () => {
        productsAPIUtil.getEnabledArtworkLocations(product._id).then(response => {
            setArtworkLocations(response.data);
        }).catch(error => {
            console.log('error', error);
            addToast('Error while loading data!', {
                appearance: "error",
                autoDismiss: true
            });
        })
      }
    const handleCancel = () => {
        setAddnewart(false)
    }
    const goToCart = () => {
        if(colors_final.length === 1){
            setSelectedProductColor('');
            setTempValueCheck(0)
		    setAddDecorations(true)
            colorselectbox()
        } else {
            setSelectedProductColor('');
		    setAddDecorations(false)
        }
        if(priceTable.length === 1) {
            setSelectedProductSize('')
            setTempValueCheckSize(0)
            setAddDecorations(true)
            sizeselectbox()
            if (priceTable.length === 1 && !showSize) {
                setSelectedProductSize(priceTable[0].size);
            }
        } else {
            setSelectedProductSize('')
		    setAddDecorations(false)
        }
        setQuantityCount(1)
		setSelectedProductDecorations([]);
		setPersonalizationArray(null)
		setPersonalizationCost({
			matchOrderQty: 0,
			price: 0,
			setupPrice:0,
			chargeId: ''
		})
		setSelectedProductLocation('')
        localStorage.setItem("redirectUrl", window.location.pathname)
        if (isDirectToCart) {
            history.push("/cart");
        }
    }
	const searchArtworkName = (e) => {
		setTextSearchArtwork(e.target.value)
	}
    const handleAddnewArt = (addedNewartdata) => {
        let artwork = addedNewartdata.newArtData;
        fetchArtworksById({
			account_id: storeDetails.accountId,
			product_id: product.id,
            userId: user?.userId || undefined,
            categories: product.ProductCategoryArray|| [],
            restrict: true,
		  })
        setAddnewart(false)
        if (artwork.id) {
            setSelectedProductArtwork(artwork.id)
            if (artwork?.design?.variation) {
                if (artwork.design.variation.length == 1) {
                    let variation = (artwork.design.variation[0])
                    let tempDecoArray = selectedProductDecorations ? selectedProductDecorations : [];
                    let locationArray = decorationLocations?.filter((locationArray) => locationArray.id === selectedProductLocation)
                    tempDecoArray.push({
                        artworkID: artwork.id,
                        location: locationArray?.[0]?.locationName,
                        locationId: selectedProductLocation,
                        variationUniqID: variation.design_variation_unique_id
                    })
                    setSelectedProductDecorations(tempDecoArray)
                    setSelectedProductLocation("")
                    setSelectedProductArtwork("")
                    setAppliedFirstDeco(true)
                }
            }
        }
    }
	  useEffect(() => {
		if (partPrice && partPrice.length > 0) {
			let allQty=[];
			let minQty =getminQuantity(partPrice)
			setMinQty(minQty)
			allQty=partPrice.map((item)=>{
				return Number(item.minQuantity)
			})
			setMinAllQty(allQty)
		}
	}, [partPrice])
	
    useEffect(() => {
        if (selectedColor) {
            setSelectedProductColor(selectedColor)
        }
    }, [selectedColor, artworkListById])
    useEffect(() => {
        if (selectedProductDecorations) {
            setSelectedDecorations(selectedProductDecorations)
            setToggle(!toggle)
        }
    }, [selectedProductDecorations.length])

    useEffect(()=> {
        getPriceByColorSizeQty(product, quantityCount,
        selectedProductColor,
        selectedProductSize,
        userGroupDiscount).then(res=>{
            setFinalPriceProduct(Number(res.price));
        })
	},[isPriceBreak, selectedProductSize, selectedProductColor, product, quantityCount, userGroupDiscount])

    useEffect(() => {
        let inventoryArray = null;
        let stock = null;
        if (productInventory) {
            if (productInventory.productId === product.productId) {
                inventoryArray = productInventory?.PartInventoryArray?.PartInventory
            }
        }

        inventoryArray && inventoryArray.map(stockArray => {
            if (selectedProductColor === stockArray.partColor && stockArray.labelSize === selectedProductSize) {
                if (stockArray?.InventoryLocationArray?.InventoryLocation.length) {
                    stockArray.InventoryLocationArray.InventoryLocation.map(inventoryArray => {
                        if (inventoryArray?.inventoryLocationQuantity?.Quantity) {
                            stock = Number(stock) + Number(inventoryArray.inventoryLocationQuantity.Quantity.value)
                        }
                    })
                }
            }
        })
        let dstStock = 0;
        //distributorStock or both
        //distributor inventory
        if (product?.poType == "Stock" && (isStockFor == "distributorStock" || isStockFor == "both")) {

            productDstInventory && productDstInventory.map(dstStockArray => {
                if (dstStockArray.productId === product.productId) {
                    if (selectedProductColor === dstStockArray.color && dstStockArray.size === selectedProductSize) {
                        dstStock = Number(dstStockArray.quantity)
                    }
                }
            })

        }
        setProductStock((stock + dstStock))
    }, [selectedProductSize, selectedProductColor, isStockFor])
    const [productInventory, setProductInventory] = useState(null);
    
    useEffect(() => {
        if(product?.allowCustomDecoration === true || product?.allowCustomDecoration === false) {
          setAllowCustomDecoration(product?.allowCustomDecoration)
        } else {
          setAllowCustomDecoration(!!productSettings?.allowCustomDecoration ? productSettings.allowCustomDecoration : false)
        }
    },[productSettings, product])

    useEffect(() => {
		setPartPrice(product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray?.PartPrice
            ?? product?.ProductPartArray?.ProductPart?.[0]?.partPrice?.PartPriceArray?.PartPrice)
		setIsMoq(product?.isProductMoq);
        setIsPriceBreak(product?.doNotCalculateAtOrder == "1" ? false : product?.isPriceBreak)
		setIsSkuMoq(product?.isProductskuMoq)
		setIsRestrictQty(product?.isRestrictQty)
		setProductId(product.id)
        setProdId(product?.productId)
		setIsSearchArtwork(product?.searchArtwork)
		setCustomDecorationFee(product?.customDecorationFee)
		setCustomDecorationPerUnitPrice(product?.customDecorationPerUnitPrice)
		setPersonalizationPerUnitPrice(product?.personalizationPerUnitPrice)
		setPersonalizationSetupFee(product?.personalizationSetupFee)
		setCustomDecorationSetupFee(product?.customDecorationSetupFee)
        let componentMounted = true;
        return () => {
            componentMounted = false;
        }
    }, [product])
    useEffect(() => {
        if(!!!product.isStockFor || product.isStockFor === 'globalsetting') {
            setIsStockFor(isStock)
        } else {
            setIsStockFor(product.isStockFor)
        }
    }, [product, isStock])
    useEffect(() => {
		if(!!product?.AllowOneDecorationLimitPro && product?.AllowOneDecorationLimitPro !== " ") {
            setallowOneDec(product?.AllowOneDecorationLimitPro)
        } else {
            setallowOneDec(productSettings?.AllowOneDecorationLimit)
        }
        if (product?.showColorProd === true || product?.showColorProd === false) {
            setShowColor(product?.showColorProd)
        } else {
            setShowColor(productSettings?.showColor)
        }
        if (product?.showSizeProd === true || product?.showSizeProd === false) {
            setShowSize(product?.showSizeProd)
        } else {
            setShowSize(productSettings?.showSize)
        }
        if(!!product?.renameLocationNameLabel) {
            setLocationLabel(product?.renameLocationNameLabel)
        } else if(!!productSettings?.renameLocationNameLabel) {
            setLocationLabel(productSettings?.renameLocationNameLabel)
        } else {
            setLocationLabel('Select Location')
        }
        if(product?.showDecorationLocationLabel === false || product?.showDecorationLocationLabel === true) {
            setShowLocationLabel(product?.showDecorationLocationLabel)
        } else {
            setShowLocationLabel(productSettings?.showDecorationLocationLabel)
        }
        if(!!product?.renameLocationNamePrompt) {
            setLocationPrompt(product?.renameLocationNamePrompt)
        } else if(!!productSettings?.renameLocationNamePrompt) {
            setLocationPrompt(productSettings?.renameLocationNamePrompt)
        } else {
            setLocationPrompt('Select Location')
        }
        if (product?.showDescription === true || product?.showDescription === false) {
            setProductSettingShowDescription(product?.showDescription)
        } else {
            setProductSettingShowDescription(productSettings?.showDescription)
        }
        if (product?.showDecorationCostInCart === true || product?.showDecorationCostInCart === false) {
            setShowDecorationCostInCart(product?.showDecorationCostInCart)
        } else {
            setShowDecorationCostInCart(productSettings?.showDecorationCostInCart)
        }
        if (product?.showSetupChargeInCart === true || product?.showSetupChargeInCart === false) {
            setShowSetupChargeInCart(product?.showSetupChargeInCart)
        } else {
            setShowSetupChargeInCart(productSettings?.showSetupChargeInCart)
        }
        if(product && productSettings && minQty) {
            setMoqNote(getMoqNote(productSettings, product, minQty));
            setShowMoqNote(getShowMoqNote(productSettings, product));
        }
        if(product?.RequiredDecorationForCheckOutPro === true || product?.RequiredDecorationForCheckOutPro === false) {
            setRequiredDecorationForCheckOutPro(product?.RequiredDecorationForCheckOutPro )
        } else {
            setRequiredDecorationForCheckOutPro(productSettings?.RequiredDecorationForCheckOut)
        }
        if(product?.showPersonalizationCostInCart === true || product?.showPersonalizationCostInCart === false) {
			setShowPersonalizationCostInCart(product?.showPersonalizationCostInCart)
		} else {
			setShowPersonalizationCostInCart(productSettings?.showPersonalizationCostInCart)
		}
        if(!!product?.decimalsToShow) {
            setDecimalsToShow(Number(product?.decimalsToShow))
        } else if(!!productSettings?.decimalsToShow) {
            setDecimalsToShow(Number(productSettings?.decimalsToShow))
        } else {
            setDecimalsToShow(2);
        }
	},[product, productSettings, minQty])

    useEffect(() => {
		getAlertMessageProductDecoration()
        verifyShowSize()
        verifyShowColor()
	}, [])

    const verifyShowSize = () => {
		if(priceTable.length === 1) {
		  setSelectedProductSize(priceTable[0].size);
		}
        if(!showSize && priceTable.length > 1) {
            setShowSize(true);
        }
	}
	const verifyShowColor = () => {
		if(colors_final.length === 1) {
		  setSelectedColor(colors_final[0]);
		  setSelectedProductColor(colors_final[0]);
		  setAddDecorations(true)
		}
        if((!showColor && colors_final.length > 1)) {
            setShowColor(true);
        }
	}

    useEffect(() => {
        verifyShowSize()
        verifyShowColor()
    }, [showColor, showSize])
    
    const checkStock = () => {
        if (product?.poType == "Stock") {
            if (productStock && (1 + Number(productStock) - Number(productCartQty)) > Number(quantityCount.toString().replace(/,/g, ''))) {
                return true
            }
            return false
        } else {
            return true
        }
    }
    const getProductStock = () => {
        return getStock(selectedProductSize, selectedProductColor, productInventory, product, productDstInventory, isStockFor)
    }


    let showPrice = false;
    if (user) {
        if (itempriceDisplay !== false) {
            showPrice = true
        }
    } else {
        if (priceDisplay !== false) {
            showPrice = true
        }
    }
    if(disIndProductPrice === false || disIndProductPrice === true){
		showPrice = disIndProductPrice
	}

    useEffect(() => {
        if (storeDetails?.accountId) {
            setLoadingartworks(true)
            fetchArtworksById({
                account_id: storeDetails.accountId,
                product_id: product.id,
                userId: user?.userId || undefined,
                categories: product.ProductCategoryArray|| [],
                restrict: true,
            }).finally(() => {
                setLoadingartworks(false)
            })
        }

    }, [storeDetails])
    useEffect(() => {
        const locations = getLocations();
        if (locations?.locationIds?.length > 0 && isFirstTime) {
            setIsFirstTime(false)
            fetchDecorationLocations(locations.locationIds)
        } else if(locations?.locationIds?.length === 0 && isFirstTime) {
            dispatch(
                receiveDecorationLocations({
                        data: []
                    }
                ),
            )
            setIsFirstTime(false)
        }
    }, [product, artworksProductState, selectedProductColor])
    useEffect(() => {
        if (personalizationArray) {
            addPersonalization()
        }
    }, [quantityCount])
	const fetchPersonalizationCost = async () => {
		setLoading(true)
		try {
          let result = await advanceAPIUtil.customAdvanceCall(addOnChargesPersBody)
		  if (result?.data?.data) {
			let personalizationData = result?.data?.data.filter(
			  (val) => val.itemCode === 'PERS',
			)
			if (personalizationData) {
			  setPersonalizationCost((preVal) => ({
				...preVal,
				['matchOrderQty']: personalizationSetupFee?1:0,
                ['price']:personalizationPerUnitPrice ? personalizationPerUnitPrice: 0,
			    ['setupPrice']:personalizationSetupFee ? personalizationSetupFee:0,
                ['chargeId']: personalizationPerUnitPrice ? '' : ''
			  }))
			  setLoading(false)
			}
		  }
		} catch (error) {
		  console.log('error', error)
		}
	  }
    const addPersonalization = () => {
		if (!personalizationCost.price) {
			fetchPersonalizationCost()
		  }

        let persQty = Number(quantityCount.toString().replace(/,/g, ''));

        let persRows = [];
        if (Number(persQty) > 0) {
            let itemQty = Number(persQty)
            let pushedIds = []
            let tempMainrows = !!personalizationArray && personalizationArray.filter(
                (row) => Number(row.sequance) === 1,
            )
            for (let index = 0; index < itemQty; index++) {
              if (tempMainrows[index]) {
                    let tempPersRow = personalizationArray.filter(
                        (row) => row.rowQtyNo === tempMainrows[index].rowQtyNo,
                    )
                    if (pushedIds.indexOf(tempPersRow[0].rowQtyNo) === -1) {
                      pushedIds.push(tempPersRow[0].rowQtyNo)
                      tempPersRow.map((tempRow) => {
                        persRows.push({
                          ...tempRow,
                          itemSize: selectedProductSize,
                          itemColor: selectedProductColor,
                          rowId: 1,
                        })
                      })
                    }
              } else {
                if(!!personalizationDropDown && !!requiredLinesPersonalization) {
                      !!dropDownValues && dropDownValues.length > 0 && dropDownValues.map((resData, keysData) => {
                        persRows.push({
                          color: getPersColor(product, productSettings),
                          location: getPersLocation(product, productSettings),
                          font: getPersFont(product, productSettings),
                          notes: getPersNote(product, productSettings),
                          displayText: '',
                          itemCode: product.itemCode,
                          itemColor: selectedProductColor,
                          itemId: product.id,
                          itemNo: product.productId,
                          itemSize: selectedProductSize,
                          orderId: '',
                          rowId: 1,
                          rowQtyId: '',
                          rowQtyNo: index + 1,
                          sequance: 1 + keysData,
                          lineName: resData.personalizationDropDownRename,
                          isDrp: resData.isPersonalizationDropDown,
                          drpValue: resData.personalizationDropDownValue,
                          isEnabled: personalizationDropDown,
                          requiredLine: requiredLinesPersonalization,
                        })
                    })
                } else {
                    persRows.push({
                        color: getPersColor(product, productSettings),
                        location: getPersLocation(product, productSettings),
                        font: getPersFont(product, productSettings),
                        notes: getPersNote(product, productSettings),
                        displayText: "",
                        itemCode: product.itemCode,
                        itemColor: selectedProductColor,
                        itemId: product.id,
                        itemNo: product.productId,
                        itemSize: selectedProductSize,
                        orderId: "",
                        rowId: 1,
                        rowQtyId: "",
                        rowQtyNo: (index + 1),
                        sequance: 1
                    })
                }
             }

            }
            setPersonalizationArray(persRows)
        }

    }
    const getPesonalizationTable = () => {

        let rowQtyNo = null;
        let rowId = null;
        return <Table className="personalizationTabel">
            <thead>
            <tr>
                <td>Item</td>
                <td>{sizeLabel}</td>
                <td>Item {colorLabel}</td>
                <td>Line</td>
                <td>Text</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {personalizationArray.map((personalization, key) => {
                let flag = true;
                if (Number(personalization.sequance) !== 1) {
                    flag = false;
                }
                rowQtyNo = personalization.rowQtyNo;
                rowId = personalization.rowId;
                const objOptions = []
                if(!!personalization?.isDrp) {
                    !!personalization?.drpValue && personalization?.drpValue.length > 0 && personalization.drpValue.map(op => {
                        objOptions.push({ value: op, label: op })
                    })
                }
                return <tr key={key}>
                    <td>{flag && "Item " + personalization.rowQtyNo}</td>
                    <td>{flag && personalization.itemSize}</td>
                    <td>{flag && personalization.itemColor}</td>
                    <td>{!!personalization?.lineName ? personalization?.lineName : 'Line ' + personalization.sequance}</td>
                    <td>
                    {!(!!personalization?.isDrp) ? (
                        <input onChange={(e) => {
                        changePersonalizatinArray(e, "displayText", key)
                    }} value={personalization.displayText}/>
                    ) : (
                        <div className="selectDropDown">
                          <Select
                            noOptionsMessage={() => "No matches for this search"}
                            options={objOptions}
                            styles={colorStyles}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            name="isDrpValuePer"
                            onChange={(e) =>
                              changePersonalizatinArray(e, "displayText", key, 1)
                            }
                          />
                        </div>
                      )
                    }
                    </td>
                    {(!(!!personalization?.isDrp) && !(!!personalization?.isEnabled) && !(!!personalization?.requiredLine)) ? (
                    <td>
                        {flag && <AddIcon onClick={() => {
                            AddPersonalizationRow(personalization, key)
                        }}/>}
                        {!flag && <DeleteIcon onClick={() => {
                            DeletePersonalizationRow(personalization, key)
                        }}/>}
                    </td>
                    ) : '' }
                </tr>
            })}
            </tbody>
        </Table>
    }
    const DeletePersonalization = () => {
        setPersonalizationArray(null)
		setPersonalizationCost((preVal) => ({
			...preVal,
			['matchOrderQty']: 0,
			['price']: 0,
            ['setupPrice']:0,
            ['chargeId'] : ''
		  }))
    }
    const DeletePersonalizationRow = (personalization, key) => {
        if (personalizationArray) {
            let tempArray = personalizationArray.filter((persArray, index) => {
                if (key === index) {
                    return false
                }
                return persArray
            })
            setPersonalizationArray(tempArray)
        }
    }
    const AddPersonalizationRow = (personalization, key) => {
        if (personalizationArray) {
            let tempArray = []
            personalizationArray.map((persArray, index) => {
                tempArray.push(persArray)
                if (key === index) {
                    tempArray.push({
                        ...persArray,
                        color: getPersColor(product, productSettings),
                        location: getPersLocation(product, productSettings),
                        font: getPersFont(product, productSettings),
                        notes: getPersNote(product, productSettings),
                        displayText: "",
                        sequance: (Number(persArray.sequance) + 1).toString()
                    })
                }
            })
            setPersonalizationArray(tempArray)
        }
    }
    const selectLocationBox = (selectlocationBox, key) => {
        let decoExistLocations = [];
        if (product?.LocationArray?.Location) {
            product.LocationArray.Location.map(location => {
                if (location?.locationName != "")
                    decoExistLocations.push(location.locationName)
            })
        }
        return <select value={selectlocationBox.location} onChange={(e) => {
            changePersonalizatinArray(e, "Location", key)
        }}>
            <option value="Select">Select</option>
            {decorationLocations?.map((location, key) => {
                if (decoExistLocations.indexOf(location.locationName) !== -1) {
                    return <option value={location.locationName} key={key}>{location.locationName}</option>
                } else {
                    return false
                }
            })}
        </select>
    }
    const changePersonalizatinArray = (e, type, key, isDrp = 0) => {
        if (personalizationArray) {
            let tempArray = personalizationArray.filter((persArray, index) => {
                if (key === index) {
                    if (type == "Font") {
                        persArray.font = e.target.value
                    } else if (type == "Color") {
                        persArray.color = e.target.value
                    } else if (type == "Location") {
                        persArray.location = e.target.value
                    } else if (type == "Note") {
                        persArray.notes = e.target.value
                    } else if (type == "displayText") {
                        persArray.displayText =  isDrp === 1 ? e.value : e.target.value
                    }
                }
                return persArray
            })
            setPersonalizationArray(tempArray)
        }
    }
    const selectcolorBox = (selectcolorBox, key) => {
        return <select onChange={(e) => {
            changePersonalizatinArray(e, "Color", key)
        }} value={selectcolorBox.color}>
            <option value="0">Select</option>
            <option value="Black">Black</option>
            <option value="White">White</option>
        </select>
    }
    const selectfontBox = (selectfontBox, key) => {
        return <select onChange={(e) => {
            changePersonalizatinArray(e, "Font", key)
        }} value={selectfontBox.font}>
            <option value="0">Select</option>
            <option value="Script">Script</option>
            <option value="Block Upper Lower">Block Upper Lower</option>
            <option value="Block Upper">Block Upper</option>
            <option value="Italic Upper Lower">Italic Upper Lower</option>
            <option value="Italic Upper">Italic Upper</option>
            <option value="Drax Standard Font">Drax Standard Font</option>
        </select>
    }

    const changeLocation = (e) => {
        setLoading(true)
        setSelectedProductLocation(e.currentTarget.value)
        setIsAdded(false)
        let locationId = e.currentTarget.value
        setselectedProductLocationIdDis(locationId)
        if (selectedProductDecorations.length === 1 && allowOneDec === "2") {
            setSingleSelectedDecorations(null)
            setSelectedProductDecorations([])
            setSelectedDecorations(null)
        }
        if (artworksFromState && artworksFromState.length) {
            artworksFromState.map((val, key) => {
                if (locationId == val?.productToDecorationMapping[0]?.location && val?.productToDecorationMapping[0]?.isRequiredForStore == "1") {
                    locationId = val?.productToDecorationMapping[0]?.location
                    val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                        if (mapVal.color === selectedProductColor) {

                            setSelectedProductLocation(locationId)
                            setSelectedProductArtwork(val.id)

                            if (val.design.variation.length == 1) {
                                addDecoration(val.design.variation[0], val.id, locationId, selectedProductColor)
                            } else {
                                let getVariation = ""
                                val.design.variation.map((val, index) => {
                                    if (val.design_variation_color.includes(selectedProductColor)) {
                                        getVariation = index;
                                    }
                                })
                                addDecoration(val.design.variation[Number(getVariation)], val.id, locationId, selectedProductColor)
                            }
                        }
                    })
                }
            })
        }
        setLoading(false)
    }
	const getDecoChargeArraybyqty = (decorationsArray, qty) => {
		let decosalePrice = []
		decorationsArray &&
			decorationsArray.map((decoitemArray, index) => {
				if (decoitemArray?.decoPricedetails?.length > 0) {
					let artworkselected = artworksFromState.filter(
						(artorkArray) => artorkArray.id === decoitemArray.artworkID,
					)[0]
					let priceq = 0

					decoitemArray.decoPricedetails.map((decoPriceeArray) => {
						let sCount = artworkselected.design.stitchCount
						if (sCount == '' || !Number(sCount)) {
							sCount = 0
						}
						if (
                            Number(sCount) >= Number(decoPriceeArray.stitchesStart) &&
                            Number(sCount) <= Number(decoPriceeArray.stitchesUpto)
                        ) {
                          if (
                              Number(qty !== '' ? qty : 1) >=
                              Number(decoPriceeArray.qunatityStart) &&
                              Number(qty !== '' ? qty : 1) <=
                              Number(decoPriceeArray.quantityUpto)
                          ) {
								priceq = decoPriceeArray
							}
						}
					})
					decosalePrice.push(priceq)
				}
			})
		return decosalePrice
	}
    const getDecoHtml = () => {
        const locations = getLocations();
        let decoExistLocations = locations.decoExistLocations;
        let yFilter = locations.yFilter
        let locationName = locations.locationName;
		let filteredX = decorationLocations.filter(itemX => yFilter.includes(itemX.locationName));
        let totalAdditionalCharges = 0
        const additionalCharges = []
		let tempDecorationLocations = filteredX
        if (decoExistLocations?.length > 0 && tempDecorationLocations?.length > 0) {
            if(decorationLocation.length !== tempDecorationLocations.length) {
                setDecorationLocations(tempDecorationLocations)
            }
			tempDecorationLocations = tempDecorationLocations.filter((f) => {
                if (decoExistLocations.indexOf(f.locationName) !== -1) {
                    if(product.restrictAfterFirstChoice && artworksProductState.length > 0) {
                        let numberChoice = 0;
                        let selectedArtworks = selectedProductDecorations.filter((row) => row.artworkID) || []
                        numberChoice = selectedArtworks.length === tempDecorationLocations.length 
                          ? selectedArtworks.length - 1
                          : selectedArtworks.length;
                        let arrayArtworks = artworkLocations.filter(x => x.numberChoice === numberChoice && !x.isEnable) ?? [];
                        let artworksToReadFiltered = [];
                        let arrayArtworksTemp = []; 
                        artworksFromState.forEach(x => {
                            let index = arrayArtworks.findIndex(z => z.artworkId === x.id);
                            if(index === -1) {
                              arrayArtworksTemp.push(x);
                            }
                        })
                        arrayArtworksTemp.forEach(x => {
                          let insert = x.productToDecorationMapping.filter(y =>  y.locationName === f.locationName).length;
                          if(insert > 0) {
                            artworksToReadFiltered.push(x);
                          }
                        });
                        return artworksToReadFiltered.length > 0;
                      } else {
                        return true;
                      }
                }
                return false
			})
		}

        let newLocation = []
		if (selectedProductDecorations.length > 0 || tempDecorationLocations.length > 0) {
			tempDecorationLocations &&
				tempDecorationLocations.map((val, index) => {
					let artworkSelected = null
					let checkLocation =
						selectedProductDecorations && selectedProductDecorations.find((d) => d.locationId == val.id && d.variationUniqID && d.variationUniqID != "" && d.variationUniqID != undefined)

					if (checkLocation == undefined || checkLocation == '') {
						newLocation.push(val)
                    }
                })
		}

        if (selectedProductDecorations.length > 0) {
            selectedProductDecorations.map((decoration, index) => {
                let artwork = null
                if (artworksFromState?.length) {
                    artwork = artworksFromState.find(
                        (artworkArray) => (artworkArray.id === decoration.artworkID && artworkArray.artworkLocation === decoration.location),
                    )

                    if (!!!artwork && artworksProductState?.length) {
                        artwork = artworksProductState.find((artworkArray) =>
                            artworkArray?.productToDecorationMapping?.some(
                                (res) => res.locationName === decoration.location && artworkArray.id === decoration.artworkID
                            )
                        );
                    }
                    if (artwork) {
                        artwork.productToDecorationMapping.filter(x => x.locationName === decoration.location).forEach(productMap => {
                            productMap.charges.forEach(charge => {
                                let price = 0
                                charge.ChargePriceArray.ChargePrice.forEach(x => {
                                    if(x.xMinQty <= quantityCount) price = x.salePrice
                                })
                                const chargePrice = charge.matchOrderQty === "1" ? Number(price) * Number(quantityCount) :  Number(price)
                                additionalCharges.push({
                                    totalSetupCharge: chargePrice,
                                    setupChargeName: charge.chargeName,
                                    chargeCode: charge.chargeCode,
                                })
                                totalAdditionalCharges =
                                    Number(totalAdditionalCharges) + chargePrice
                            })
                        })
                    }
                }
            })
        }

		let toatlPrice = 0
		let totalSetupDecoCharges = 0
		let totalDecoCharges = 0
        let totalSetupCharge = 0
        let setupChargeName = 'Setup Charge'
        let setupCharges = []
        if(showSetupChargeInCart) {
			if(product?.ChargeArray?.Charge?.length > 0) {
				product.ChargeArray.Charge.forEach(charge => {
					if(setupCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
                        const chargeByQuantity = charge?.ChargePriceArray?.ChargePrice?.filter(x => Number(x.xMinQty) <= quantityCount)
                        if(chargeByQuantity?.length > 0) {
                            let totalSetupChargeUnit = Number(chargeByQuantity?.[chargeByQuantity.length - 1]?.salePrice ?? 0) * Number(charge?.matchOrderQty === "1" ? quantityCount : 1);
                            setupChargeName = charge?.chargeName ?? 'Setup Charge';
                            totalSetupCharge = Number(totalSetupCharge) + totalSetupChargeUnit
                            toatlPrice = Number(toatlPrice) + Number(totalSetupCharge)
                            setupCharges.push({totalSetupCharge: totalSetupChargeUnit, setupChargeName, chargeCode: charge.chargeCode})   
                        }
					}
				})
			}

            additionalCharges.forEach(additionalCharge => {
                if (additionalCharge?.totalSetupCharge) {
                    setupCharges.push(additionalCharge)
                }
            })

            totalSetupCharge = totalSetupCharge + totalAdditionalCharges
    	}
		let valueForDecorationCost = getDecorationCost(selectedProductDecorations, quantityCount, customDecorationFee, showSetupChargeInCart, customDecorationPerUnitPrice, toatlPrice, customDecorationSetupFee, allowCustomDecoration)
        toatlPrice = valueForDecorationCost.toatlPrice;
        totalDecoCharges = valueForDecorationCost.totalDecoCharges;
        totalSetupDecoCharges = valueForDecorationCost.totalSetupDecoCharges;
        let rPrice = (Number(finalDiscountedPrice) > 0 ? Number(finalDiscountedPrice) : Number(finalPrice))
        let mainPersonalizationCost = 0
	    if(getSettingStatus('showPersonalization') && !showPersonalizationCostInCart && personalizationCost && !!personalizationCost?.price) {
            let personalizationCost2 = (quantityCount > 0 ? Number(personalizationCost.price).toFixed(decimalsToShow) : 0)
          if (personalizationCost.matchOrderQty == '1' && !showPersonalizationCostInCart && product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
            mainPersonalizationCost = Number(personalizationCost2) * Number(quantityCount)
          } else {
            rPrice = Number(rPrice) + Number(personalizationCost2)
          }
        }
        let calPriceIsPrice = Number(rPrice) * Number(quantityCount)
		if (product?.doNotCalculateAtOrder == "1" && Number(quantityCount) > 0 && product?.isRestrictQty) {
			calPriceIsPrice = Number(rPrice) + mainPersonalizationCost
		}
        toatlPrice = (calPriceIsPrice + Number(showSetupChargeInCart ? totalSetupDecoCharges : 0) + Number(totalDecoCharges) + Number(totalSetupCharge))

		if (personalizationCost?.price !== '' && personalizationCost?.price !== 'undefined' && personalizationCost?.price !== undefined) {
            if (personalizationCost.matchOrderQty == '0') {
              toatlPrice = Number(toatlPrice) + Number(personalizationCost.price)+Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0)
            } else if (personalizationCost.matchOrderQty == '1' && showPersonalizationCostInCart) {
              toatlPrice =
                Number(toatlPrice) +
                Number(personalizationCost.price) * Number(quantityCount) + (Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0))
            } else if (personalizationCost.matchOrderQty == '1' && showSetupChargeInCart) {
              toatlPrice = Number(toatlPrice) + (Number(personalizationCost.setupPrice))
            }
        }
		if(uniqueLocation === '') {
			if(!!(tempDecorationLocations.length === 1) && !!(tempDecorationLocations?.[0]?.locationName)) {
                if(selectedProductDecorations.length === 0) {
                    setSelectedProductLocation(tempDecorationLocations[0].id);
                    setUniqueLocation(tempDecorationLocations[0].locationName);
                }
			}
		}
        return (
		   <div className="mt-3 mb-3">
            {getSettingStatus('showDecoration') && isReplace && (!(selectedProductDecorations.length === 1 && allowOneDec === "1")) && (
            <>
                {loadingArtworks && <p className={"ml-3"}>Loading ...</p>}
                {(newLocation.length > 0 || tempDecorationLocations?.length === 1) && (
                    <>
                        {showLocationLabel && newLocation.length > 0 && <label>
                            <b>{`${locationLabel}:`}</b> {' '}
                        </label>}
                        {tempDecorationLocations.length > 1 || (selectedProductDecorations.length > 0 && newLocation.length > 0) ? (
                        <select
                            className={showLocationLabel ? "ml-2" : ''}
                            style={{ width: 'auto' }}
                            onChange={(e) => {
                            changeLocation(e)
                            }}
                            value={selectedProductLocation}
                        >
                            <option value="">{locationPrompt}</option>
                            {newLocation?.map((locationArray, key) => {
                            return (
                                <option key={key} value={locationArray.id}>
                                {locationArray.locationName}
                                </option>
                            )
                            })}
                        </select>
                        ) : <label>&nbsp;{uniqueLocation}</label>
                        }
                    </>
                ) }
            </>
            ) }
               {
                   showPrice !== false && <ProductPriceCharges
                       product={ product }
                       decimalsToShow={ decimalsToShow }
                       personalizationCost={ personalizationCost }
                       showPersonalizationCostInCart={ showPersonalizationCostInCart }
                       showSetupChargeInCart={ showSetupChargeInCart }
                       quantityCount={ quantityCount }
                       showDecorationCostInCart={ showDecorationCostInCart }
                       totalDecoCharges={ totalDecoCharges }
                       totalSetupDecoCharges={ totalSetupDecoCharges }
                       usePointsAsCurrency={ usePointsAsCurrency }
                       totalSetupCharge={totalSetupCharge}
                       setupCharges={setupCharges}
                   />
               }
               { showPrice !== false && toatlPrice > 0 && <>
                   <br/>
                   <label>
                       <b>Total:</b>{usePointsAsCurrency ? getPointsValue(toatlPrice) : <p>{`${showCurrencycodeSymbol(currency)}${productSettings?.priceRoundUp ? roundedPrice(Number(toatlPrice), decimalsToShow) : Number(toatlPrice).toFixed(decimalsToShow) }`}</p>}
                   </label>
               </> }
               {
                   <div className="row clear mt-20">
                       <div className="col-md-6"></div>
                       { selectedProductLocation !== '' && isSearchArtwork &&
                           <div className="col-md-6">
                               <input
                                   type="text"
                                   className="inputStyle"
                                   value={ searchTextArtwork }
                                   name="searchTextArtwork"
                                   placeholder={ (product?.artworkSearchPlaceholderText === undefined ? "Search Artwork…" : (!!product?.artworkSearchPlaceholderText ? product.artworkSearchPlaceholderText : '')) }
                                   onChange={ (e)=>searchArtworkName(e) }
                               />
                           </div>
                       }
                   </div>
               }
            {getSettingStatus('showDecoration') && getSettingStatus('showSelectArtwork', true) && selectedProductLocation !== '' && (
              <AddDecoration
                setSelectedProductArtwork={setSelectedProductArtwork}
                selectedProductArtwork={selectedProductArtwork}
                addDecoration={addDecoration}
                setAddnewart={setAddnewart}
                selectedProductLocation={selectedProductLocation}
                selectedColor={selectedColor}
                selectedProductColor={selectedProductColor}
                setLoading={setLoading}
                searchTextArtwork={searchTextArtwork}
                artworkSetting={artworkSetting}
                locationName={locationName}
                storeArtwork={storeArtwork}
                decorationLocations={decorationLocations}
                allowCustomDecoration={allowCustomDecoration}
                selectedDecorationsList={selectedProductDecorations}
                artworkDuplicatesAllowed={productSettings?.artworkDuplicatesAllowed}
                artworkDuplicatesAllowedPro={product?.artworkDuplicatesAllowed}
                locationID={selectedProductLocation}
                tempDecorationLocations={tempDecorationLocations}
                product={product}
                artworkLocations={artworkLocations}
                allowOneDec={allowOneDec}
                isAdded={isAdded}
                setIsAdded={setIsAdded}
                decorationListOrder = {decorationListOrder}
                preventAutoAttach = {preventAutoAttach}
                hideDecorationName = {hideDecorationName}

              />
            )}
          </div>)
    }
    useEffect(() => {
        if (singleSelectedDecorations) {
            let getIndex = product.LogoInfo.filter(
                (val) =>
                    val.color === selectedColor &&
                    val.location === singleSelectedDecorations.location,
            )

            if (getIndex.length > 0) {
                setSelectedImage(getIndex[0]?.image)
            } else {

                let getIndexOfExtra_logo = []
                product.LogoInfo.map(val => {
                    if (val.color == selectedColor) {

                        if (val.extra_logo.filter(e => e.location == singleSelectedDecorations.location).length > 0) {
                            getIndexOfExtra_logo.push(val)
                        }
                    }
                })
                if (getIndexOfExtra_logo.length) {
                    setSelectedImage(getIndexOfExtra_logo[0]?.image)
                } else {
                    let getImgColorIndex = product.LogoInfo.filter(
                        (val) =>
                            val.color === selectedColor
                    )

                    if (getImgColorIndex.length > 0) {
                        setSelectedImage(getImgColorIndex[0]?.image)
                    }
                }
            }
        }
    }, [singleSelectedDecorations])

    const changeColor = async (e)=>{
        setLoading(true)
        let color = e && e.target && e.target.value && e.target.value ? e.target.value : e
        setSelectedProductColor(color);
        if ( setSelectedColor ) {
            setSelectedColor(color)
        }
        setAddDecorations(true)
        setSelectedProductDecorations([])
        setSingleSelectedDecorations(null)

        let getImage = product?.WebstoreProductPartArray.filter((val)=>val.color == color && val.isSwapImage == true,)
        if ( getImage.length > 0 ) {
            setSelectedImage(getImage[0]?.image)
        } else {
            let getIndex = product.MediaContent.filter(
                (val)=>val.color == color && val.primary,
            )
            if ( getIndex.length ) {
                setSelectedImage(getIndex[0].url)
            } else {
                let secondarygetIndex = product.MediaContent.filter(
                    (val)=>val.color == color,
                )
                setSelectedImage(secondarygetIndex[0]?.url)
            }
        }
        let tempDecoArray = [];
        if ( getImage.length === 0 ) {
            tempDecoArray = await selectDecoration(color)
            setIsReplace(true)
        } else {
            setIsReplace(false)
        }
        setLoading(false)

        setSingleSelectedDecorations(tempDecoArray[tempDecoArray.length - 1])
        setSelectedProductDecorations(tempDecoArray)
        if (!!personalizationArray) {
            let tempPersRow = [...personalizationArray]
            let blnktest = !!tempPersRow ? tempPersRow.filter(x => !!!x.itemColor || x.itemColor != color) : []
            if (blnktest?.length) {
                tempPersRow = tempPersRow.map((tempRow) => {
                    return {
                        ...tempRow,
                        itemColor: color
                    }
                });
                setPersonalizationArray(tempPersRow);
            }
        }
        setTemp(temp + 1)
    }

    useEffect(() => {
        if (selectedProductColor) {
            let grp = []
            let updatePriceTable1 = []
            if (product?.WebstoreProductPartArray && product?.WebstoreProductPartArray.length > 0) {
                product?.WebstoreProductPartArray && product.WebstoreProductPartArray.forEach((part) => {
                    if (selectedProductColor === part?.ColorArray?.Color?.colorName) {
                        let priceOption = part
                        const size = part.ApparelSize.labelSize
                        if (!grp[size]) {
                            grp[size] = []
                        }
                        grp[size].push(priceOption)
                    }

                })
            } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
                product?.ProductPartArray?.ProductPart &&
                    product.ProductPartArray.ProductPart.forEach((part) => {
                        if (selectedProductColor === part?.ColorArray?.Color?.colorName) {
                            let priceOption = part
                            const size = part.ApparelSize.labelSize
                            if (!grp[size]) {
                                grp[size] = []
                            }
                            grp[size].push(priceOption)
                        }

                    })
            }
            for (let size in grp) {
                updatePriceTable1.push({ size: size, priceOptions: grp[size] })
            }
            updatePriceTable1 = updatePriceTable1.sort((a, b) => {
                let aValue = defaultSizeForAll.indexOf(a.size.toLowerCase());
                let bValue = defaultSizeForAll.indexOf(b.size.toLowerCase());
                if (aValue === -1) aValue = Infinity;
                if (bValue === -1) bValue = Infinity;
                return aValue - bValue;
            })
            setUpdatePriceTable(updatePriceTable1)

        }
    }, [selectedProductColor])

    useEffect(() => {
        if(colors_final.length === 1 && tempValueCheck === 0) {
            changeColor(colors_final[0]) 
            setTempValueCheck(tempValueCheck+1)
        }
    },[colors_final, setTempValueCheck, tempValueCheck])

    const colorselectbox = () => {
        let colors_final_temp = colors_final;
        return <select onChange={(e) => { changeColor(e) }}
            value={selectedProductColor}>
            <option key={"colorkey"}>Select {colorLabel}</option>
            {colors_final_temp.map((color, key) => {
                return <option key={key} value={color}>{color}</option>
            })}</select>
    }
    const [tempValueCheckSize, setTempValueCheckSize] = useState(0)
    const sizeselectbox = () => {
        if((!(!!selectedProductColor) || selectedProductColor == `Select ${colorLabel}` || showColor === false) && priceTable.length === 1 && tempValueCheckSize === 0) {
            setSelectedProductSize(priceTable[0].size)
            setTempValueCheckSize(tempValueCheckSize+1)
        }
        return <select onChange={(e) => {
            setSelectedProductSize(e.target.value)
            if (!!personalizationArray) {
                let tempPersRow = [...personalizationArray]
                let blnktest = !!tempPersRow ? tempPersRow.filter(x => !!!x.itemSize || x.itemSize != e.target.value) : []
                if (blnktest?.length) {
                    tempPersRow = tempPersRow.map((tempRow) => {
                        return {
                            ...tempRow,
                            itemSize: e.target.value
                        }
                    });
                    setPersonalizationArray(tempPersRow);
                }
            }
        }} value={selectedProductSize}>
            <option key={"colorkey"}>Select {sizeLabel}</option>
            {(selectedProductColor == undefined || selectedProductColor == "" || selectedProductColor == `Select ${colorLabel}`) && priceTable.map((tableArray, key) => {
				return <option key={key} value={tableArray.size}>{tableArray.size}</option>
			})}
			{(selectedProductColor !== undefined  && selectedProductColor !== "" && selectedProductColor !== `Select ${colorLabel}`) && updatePriceTable.map((tableArray, key) => {
				return <option key={key} value={tableArray.size}>{tableArray.size}</option>
			})}
            
            </select>
    }

	const addDecoration = async (variation, arkworkId, selectedProductLocationId, color) => {

		let dPrice = await getDecoPrice(arkworkId)
		setTemp(temp + 1)
		let tempDecoArray = selectedProductDecorations ? selectedProductDecorations : [];
		let locationArray = decorationLocations?.filter((locationArray) => locationArray.id === selectedProductLocationId)

		let artwork_selected = artworksFromState?.filter(val => val.id == arkworkId)[0]

		let decoPricedetails = []
		if (dPrice?.length > 0) {
			dPrice.map((priceArray) => {
				if (artwork_selected?.design?.designType == priceArray.decoratorType) {
					decoPricedetails.push(priceArray)
				}
			})
		}
		let decoData = {
			artworkID: arkworkId,
			location: locationArray[0].locationName,
			locationId: locationArray[0].id,
			variationUniqID: variation.design_variation_unique_id,
			decoPricedetails: decoPricedetails
		}
		let getDecoOfLocation = tempDecoArray.filter(val => val.locationId == locationArray[0].id)

		if (getDecoOfLocation.length == 0) {
			tempDecoArray.push(decoData)
			setSingleSelectedDecorations(decoData)
            setSelectedProductDecorations(tempDecoArray)
		}

		let getIndex = product.LogoInfo.filter(
			(val) =>
				val.color === color &&
				val.location === locationArray[0].locationName,
		)

		if (getIndex.length > 0) {
			setSelectedImage(getIndex[0]?.image)
		} else {

			let getIndexOfExtra_logo = []
			product.LogoInfo.map(val => {
				if (val.color == color) {

					if (val.extra_logo.filter(e => e.location == locationArray[0].locationName).length > 0) {
						getIndexOfExtra_logo.push(val)
					}
				}
            })

			if (getIndexOfExtra_logo.length) {
				setSelectedImage(getIndexOfExtra_logo[0]?.image)
			} else {
				let getImgColorIndex = product.LogoInfo.filter(
					(val) =>
						val.color === color
				)

				if (getImgColorIndex.length > 0) {
					setSelectedImage(getImgColorIndex[0]?.image)
				}
			}
		}
		setTemp(temp + 1)
		tempfun()

		// setSelectedProductLocation('')
		// setSelectedProductArtwork('')
		setUniqueLocation('')

    }

    const getInventrySettingStatus = (key) => {
        if (product) {
            if (product[key] == false || product[key] == true) {
                return product[key]
            }
        }
        if (inventorySettings) {
            if (inventorySettings[key] == false || inventorySettings[key] == true) {
                return inventorySettings[key]
            }
        }
        return false
    }
    
    const isSizeValid = () => {
        return !!selectedProductSize && selectedProductSize !== `Select ${sizeLabel}`;
    }

    const isColorValid = () => {
        return !!selectedProductColor && selectedProductColor !== `Select ${colorLabel}`;
    }

    const isShowInventoryEnabled = getInventrySettingStatus('showInventory');

	const handleChange = (e) => {
		const value = e.target.value.replace(/\D/g, "");
        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		setQuantityCount(Number(value) || 1)
	};

	const onClickArtDesign = (vari, locationName, locationId,) => {
		let getArt = artworksFromState.filter(t => t.design.variation.filter(y => y.design_variation_unique_id == vari.design_variation_unique_id).length > 0);
		if (getArt.length > 0) {
            let locationData = getArt[0].productToDecorationMapping.filter(val => val.locationName === locationName)
            if(locationData.length === 0)
                locationData = decorationLocations?.filter((locationArray) => locationArray.locationName === locationName)

            let locationId = !!locationData?.[0].location ? locationData[0].location : locationData[0].id
            setSelectedProductLocation(locationId);
            let decoData = {
                artworkID: getArt[0]?.id,
                location: locationData[0]?.locationName,
                locationId: locationId,
                variationUniqID: vari?.design_variation_unique_id
            }
			setSingleSelectedDecorations(decoData)

			tempfun()
		}
		setTemp(temp + 0)
	}
    useEffect(() => {
        if(product?.AllowOutofStock === true || product?.AllowOutofStock === false) {
            setIsAllowOutOfStockOrder(product?.AllowOutofStock)
        } else {
            setIsAllowOutOfStockOrder(isAllowoutofStockOrder)
        }
      },[product, isAllowoutofStockOrder])

    const removeDeco = (mainArraykey, itemsRows, index) => {
        if(index !== '' && index !== null) {
            let tempDecoArray = selectedProductDecorations;
            tempDecoArray = tempDecoArray.filter((decoArray, keyIndex) => keyIndex !== index,);
            setSelectedProductDecorations(tempDecoArray);
        } else {
            setSelectedProductDecorations([]);
        }
	}

	const showPopupText = (mainArraykey, itemsRows, index) => {
        if(product.restrictAfterFirstChoice) {
          setIndexPopup(index)
          setMainArrayKeyPopup(mainArraykey)
          setItemsRowsPopup(itemsRows)
          setShowDecoPopup(true);
        } else {
          removeDeco(mainArraykey, itemsRows, index);
        }
      }

	const getAlertMessageProductDecoration = () => {
		if(product._id) {
			productsAPIUtil.getProductDecorationPopup(product._id).then(response => {
			if(response?.data?.alertMessage) {
				setPopupText(response?.data?.alertMessage);
			}
			}).catch(error => {
			console.log('error', error);
				addToast('Error while loading data!', {
					appearance: "error",
					autoDismiss: true
				});
			})
		}
	}

    const getAdditionalPrice = () => {
        let personalization = (getSettingStatus('showPersonalization') && !showPersonalizationCostInCart && personalizationCost && !!personalizationCost?.price && quantityCount > 0) ? Number(personalizationCost.price) : 0
        let valueForDecorationCost = getDecorationCost(selectedProductDecorations, quantityCount, customDecorationFee, showSetupChargeInCart, customDecorationPerUnitPrice, 0, customDecorationSetupFee, allowCustomDecoration)
        let decoration = (!showDecorationCostInCart ? valueForDecorationCost.totalDecoCharges : 0) / quantityCount;
        return personalization + decoration;
      }
	
    return (
        <div className="product-details-content ml-70">
            <div className="row" style={{
                alignContent: "center"
            }}>
                <h4 className="mb-2" style={{
                    margin: "auto",
                    fontSize: "13px",
                    fontWeight: "bold"
                }}>
                    {"PRODUCT OVERVIEW"}
                </h4>
            </div>


            {loading && (<Loader/>)}
           {productSettingShowDescription === true && ( 
            <div className="mt-0 mb-5">
                <p dangerouslySetInnerHTML={{__html: product.description}}></p>
            </div>
           )}

            <div className="container mt-3 p-0 pb-3" style={{
                backgroundColor: "#eceeed"
            }}>
                <div className="row" style={{
                    alignContent: "center"
                }}>
                    <h4 className="mb-2 mt-4" style={{
                        margin: "auto",
                        fontSize: "13px",
                        fontWeight: "bold"
                    }}>
                        {"PER UNIT PRICING"}
                    </h4>
                </div>


                <div className="row col-md-12 ml-0 mr-0 pl-3 pr-3">
                    <div className="mt-0 mr-0" style={{
                        margin: "auto",
                        border: "2px black solid",
                        borderTop: "2px solid black",
                        width: "100%"
                    }}>
                        <table className="table mb-0 mr-0">
                            <tbody>
								<tr>
									<th scope="row">Quantity</th>
									{product.productLowPrice.map((val, key) => {
										return(<td scope="row">{Object.keys(val.break)[0]}</td>)
									})}
								</tr>
                                {showPrice !== false && <>
								<tr>
									<th>Price (R)</th>
									<td scope="row">
                                        {
                                            (product?.showProductPrice !== false) ?
                                                <>
                                                    {
                                                        usePointsAsCurrency ? getPointsValue(Number(finalPrice) + getAdditionalPrice()) : showCurrencycodeSymbol(currency) +
                                                            Number(Number(finalPrice) + (getAdditionalPrice())).toFixed(decimalsToShow)
                                                    }
                                                </>
                                            : ""
                                        }
                                    </td>
								</tr></>}
                            </tbody>
                        </table>
                        <div className="row ml-0 mr-0 p-0">
                            <p style={{
                                margin: "auto"
                            }}>{"Price includes: Full digital color with Under 50% ink coverage"}</p>
                        </div>
                    </div>
                </div>

                <div className="row col-md-12 ml-0 mr-0 pl-3 pr-3">
                    <div className="row ml-0 mr-0 p-0 mb-2">
                        <p style={{
                            margin: "auto"
                        }}>{"Shipping: All orders ship on our account. These are the fixed, flat rates, to ship anywhere" +
                            " in the continental US. These are not estimates."}</p>
                    </div>
                </div>

                {/* <div className="row col-md-12 mb-4 ml-0 mr-0 pl-3 pr-3" style={{
                    backgroundColor: "#e8fff2"
                }}>
                    <div className="mt-0 mr-0" style={{
                        margin: "auto",
                        border: "2px black solid",
                        borderTop: "2px solid black",
                        width: "100%"
                    }}>
                        <table className="table mb-0 mr-0">
                            <tbody>
                            <tr>
                                <th scope="row">Print Option (v)</th>
                                <td scope="row">$0.15</td>
                                <td scope="row">$0.15</td>
                                <td scope="row">$0.15</td>
                                <td scope="row">$0.15</td>
                                <td scope="row">$0.15</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="row ml-0 mr-0 p-0">
                            <p style={{
                                margin: "auto"
                            }}>{"Print Option: For Over 50% Ink coverage please add this cost"}</p>
                        </div>
                    </div>
                </div> */}

                <div className="row" style={{
                    alignContent: "center"
                }}>
                    <h4 className="mb-2" style={{
                        margin: "auto",
                        fontSize: "13px",
                        fontWeight: "bold"
                    }}>
                        {"PRODUCT INFORMATION"}
                    </h4>
                </div>

                <div className="row" style={{
                    alignContent: "center"
                }}>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Size:"}</p>
                            <p style={{marginLeft: "4px"}}>
							{product?.width?`${product?.width}\ W x `:'-'}
							{product?.height?`${product?.height}\  H(approx.)`:'-'}
							</p>
                        </div>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Material:"}</p>
                            <p style={{marginLeft: "4px"}}>{product?.material?`${product?.material}`:'-'}</p>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Hook & Grommet:"}</p>
                            <p style={{marginLeft: "4px"}}></p>
                        </div>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Weight:"}</p>
                            <p style={{marginLeft: "4px"}}> 
							{product?.weight?`${product?.weight}`:'-'}</p>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Production:"}</p>
                            <p style={{marginLeft: "4px"}}>{`${product?.productionTime?product?.productionTime:'-'}`}</p>
                        </div>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Finish:"}</p>
                            <p style={{marginLeft: "4px"}}>{product?.finish?`${product?.finish}`:'-'}</p>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Shipping:"}</p>
                            <p style={{marginLeft: "4px"}}></p>
                        </div>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Packaging Information:"}</p>
                            <p style={{marginLeft: "4px"}}>{product?.package?`${product?.package}`:'-'}</p>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Setup Fee:"}</p>
                            <p style={{marginLeft: "4px"}}>{}</p>
                        </div>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Proots Free:"}</p>
                            <p style={{marginLeft: "4px"}}>{product?.proofFree?`${product?.proofFree}`:'false'}</p>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Rush Available:"}</p>
                            <p style={{marginLeft: "4px"}}>{ product.rushAvailable == '1' ? 'true':'false' }</p>
                        </div>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Virtuals Free:"}</p>
                            <p style={{marginLeft: "4px"}}>{product?.virtualFree?`${product?.virtualFree}`:'false'}</p>
                        </div>
                    </div>

                </div>


                <div className="row mt-3" style={{
                    alignContent: "center"
                }}>
                    <h4 className="mb-2" style={{
                        margin: "auto",
                        fontSize: "13px",
                        fontWeight: "bold"
                    }}>
                        {"PRINTING INFORMATION"}
                    </h4>
                </div>


                <div className="row" style={{
                    alignContent: "center"
                }}>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Imprint Area:"}</p>
                            <p style={{marginLeft: "4px"}}>-</p>
                        </div>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Imprint Method:"}</p>
                            <p style={{marginLeft: "4px"}}>{product?.imprintMethod}</p>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-12 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Imprint Location:"}</p>
                            <p style={{marginLeft: "4px"}}>{!!product?.imprintArea?product.imprintArea:'-'}</p>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-12 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold"
                            }}>{"Bleeds:"}</p>
                            <p style={{marginLeft: "4px"}}>{product?.bleeds?`${product?.bleeds}`:'-'}</p>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-12 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <p style={{
                                fontWeight: "bold",
								width: "220px"
                            }}>{"Available Colors:"}</p>
                            <p style={{marginLeft: "4px"}}>{product?.colorsAlias?`${product?.colorsAlias}`:'-'}</p>
                        </div>
                    </div>
                </div>

				{/* btns ------------------*/}

				<div className="row mt-3" style={{
					alignContent: "center"
				}}>
					<div className="col-md-12 d-flex mb-2" style={{
						alignContent: "center"
					}}>
						<div className="col-md-6 d-flex pl-5 " style={{
							alignContent: "center"
						}}>
                            <Button variant="outlined" className="ml-2" style={{
                                borderRadius: "15px",
                                backgroundColor: "white",
                                border: "none",
                                width: "200px"
                            }}>
                                {"Artwork Template"}
                            </Button>
						</div>
						<div className="col-md-6 d-flex" style={{
							alignContent: "center"
						}}>
                            <Button variant="outlined" className="ml-2" style={{
                                borderRadius: "15px",
                                backgroundColor: "white",
                                border: "none",
                                width: "200px"
                            }}>
                                {"Download Flyer"}
                            </Button>
						</div>
					</div>

                    <div className="col-md-12 d-flex mt-1" style={{
                        alignContent: "center"
                    }}>
                        <div className="col-md-6 d-flex pl-5" style={{
                            alignContent: "center"
                        }}>
                            <Button variant="outlined" className="ml-2" style={{
                                borderRadius: "15px",
                                backgroundColor: "white",
                                border: "none",
                                width: "200px"
                            }}
							  /* onClick={requestV()} */
							>
                                {"Request Virtuals"}
                            </Button>
                        </div>
                        <div className="col-md-6 d-flex" style={{
                            alignContent: "center"
                        }}>
                            <Button variant="outlined" className="ml-2" style={{
                                borderRadius: "15px",
                                backgroundColor: "white",
                                border: "none",
                                width: "200px"
                            }}>
                                {"Request Samples"}
                            </Button>
                        </div>
                    </div>

				</div>

            </div>


            <div className="mt-4 pro-details-size-color">
                {showColor && <div className="pro-details-color-wrap">
                    <span>{colorLabel}</span>
                    <div className="pro-details-color-content">
                        {colorselectbox()}
                    </div>
                </div>}
                {showSize && <div className="pro-details-size">
                    <span>{sizeLabel}</span>
                    <div className="pro-details-size-content">
                        {sizeselectbox()}
                    </div>
                </div>}
            </div>


            {selectedProductDecorations.length > 0 && getSettingStatus('showDecoration') && <div className="mt-2"><span><b>Selected Decorations</b></span>
                <div className="col-md-12" style={{display: "flex"}}>
                    <div className="row pl-2">
                        {selectedProductDecorations?.length > 0 && selectedProductDecorations.map((decoitem, index) => {
                            let artworkSelected = null;
                            if (decoitem.artworkID && artworksFromState?.length) {
                                artworkSelected = artworksFromState.filter(artorkArray => artorkArray.id === decoitem.artworkID)[0]
                            }
                            let variations = null;
                            if (!artworkSelected) {
                                return ""
                            } else {
								variations = artworkSelected.design.variation.filter(
									(variationArray) =>
										variationArray.design_variation_unique_id ==
										decoitem.variationUniqID,
								)

								let label = decoTypeList.filter(
									(val) => val.code == artworkSelected?.design?.designType,
								)
								if (variations) {

                                    return variations.map((vari, indexi) => {
										return <div className="decorows inlinefexDecorationDiv" key={"idx" + indexi}
											onClick={() => {
												onClickArtDesign(vari, decoitem?.location, label?.[0]?.name)
											}}
										>
                                            <img
                                                className="default-img1"
                                                style={{maxHeight: '60px', maxWidth: "60px"}}
												src={
													vari?.itemImageThumbnail?.[0] != ''
														? replaceOrigin(vari.itemImageThumbnail)
														: ''
												}
                                            />
                                            <div>
                                                <div className="col-md-12">
                                                    {!!!hideDecorationName && <p><b>{artworkSelected?.identity}</b></p>}
                                                    {!!!hideLocationName && <p>{decoitem.location}</p>}
                                                    {(!decoitem?.isRequiredForStore || decoitem?.isRequiredForStore == "0") &&
                                                        <DeleteIcon className="float-right" onClick={() => {
                                                            showPopupText([], [], index)
                                                        }} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                            }
							}
                        })}
                    </div>
                </div>
            </div>}

            {product.variation ? (
                <div className="pro-details-size-color">
                    <div className="pro-details-color-wrap">
                        <span>{colorLabel}</span>
                        <div className="pro-details-color-content">
                            {product.variation.map((single, key) => {
                                return (
                                    <label
                                        className={`pro-details-color-content--single ${single.color}`}
                                        key={key}
                                    >
                                        <input
                                            type="radio"
                                            value={single.color}
                                            name="product-color"
                                            checked={
                                                single.color === selectedProductColor ? "checked" : ""
                                            }
                                            onChange={() => {
                                                setSelectedProductColor(single.color);
                                                setSelectedProductSize(single.size[0].name);
                                                setProductStock(single.size[0].stock);
                                                setQuantityCount(1);
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                    <div className="pro-details-size">
                        <span>{sizeLabel}</span>
                        <div className="pro-details-size-content">
                            {product?.variation.map(single => {
                                return single.color === selectedProductColor
                                    ? single.size.map((singleSize, key) => {
                                        return (
                                            <label
                                                className={`pro-details-size-content--single`}
                                                key={key}
                                            >
                                                <input
                                                    type="radio"
                                                    value={singleSize.name}
                                                    checked={
                                                        singleSize.name === selectedProductSize
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onChange={() => {
                                                        setSelectedProductSize(singleSize.name);
                                                        setProductStock(singleSize.stock);
                                                        setQuantityCount(1);
                                                    }}
                                                />
                                                <span className="size-name">{singleSize.name}</span>
                                            </label>
                                        );
                                    })
                                    : "";
                            })}
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}



            {addDecorations && getDecoHtml()}
            {addDecorations && (userType && (userType === USERTYPES.ADMIN || userType === USERTYPES.USER) || productSettings?.allowToUpCustArtwork === "2") && getSettingStatus('showDecoration') && allowCustomDecoration ? <div>
                <div className="col-md AddNewart ml-3 mt-2">
                    <Button variant="outlined" color="primary" onClick={() => { setAddnewart && setAddnewart(true) }}>Add New Art</Button>
                </div>
            </div> : ''}
            {!personalizationArray && getSettingStatus('showPersonalization') &&
                <Button variant="contained" className="mt-2 mb-2" onClick={() => {
                    addPersonalization();
                }}>Add { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                ? productSettings['showPersonalizationLabel']
                : 'Personalization' }</Button>}
            {personalizationArray && <Button variant="contained" className="mt-2 mb-2" onClick={() => {
                DeletePersonalization();
            }}>Delete { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
            ? productSettings['showPersonalizationLabel']
            : 'Personalization' }</Button>}
            {personalizationArray && <div className="mt-2 mb-2"  style={!!personalizationDropDown && !!requiredLinesPersonalization && !!dropDownValues && dropDownValues.length > 0 && dropDownValues.filter((res) => !!res?.isPersonalizationDropDown).length > 0 ? {overflowX: 'unset'} : {}}>
                {getPesonalizationTable()}
            </div>}
            {(isSkuMoq || isMoq) && showMoqNote && (
                <p style={{color: 'red'}}>{moqNote}</p>
            )}
            {(product && priceTables.length > 0 && !!product?.displayPriceGrid) &&
                                        <PriceTable
                                            priceTable={priceTables}
                                            product={product}
                                            currency={currency}
                                            decimalsToShow={decimalsToShow}
                                            doNotCalculateAtOrder={product?.doNotCalculateAtOrder}
                                            userGroupDiscount={userGroupDiscount}
                                            showSellPrice={showSellPrice}
                                        />}
            {product.affiliateLink ? (
                <div className="pro-details-quality">
                    <div className="pro-details-cart ml-0">
                        <a
                            href={product.affiliateLink}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Buy Now
                        </a>
                    </div>
                </div>
            ) : (
                <>{isShowInventoryEnabled && isSizeValid() && isColorValid() && < div className="col-md-12 mt-3">
                    <label>{inventorySettings?.['inventoryLabel'] ? inventorySettings['inventoryLabel'] : "Available"} {" "}{getProductStock()}</label>
                </div>}
                    <div className="pro-details-quality">
                    { qtyDropDown ? <select name="qty-drop-down" id="qty-drop-down"
                            className="cart-plus-minus-box cart-plus-minus"
                            style={ {
                                borderWidth: '1px',
                                padding: '5px',
                                textAlign: "center"
                            } }
                            onChange={ (e) => setQuantityCount(e.target.value) }
                        >
                            <option value="0">QTY</option>
                            {
                                qtyValues.map((o, index)=>{
                                    return (
                                        <option value={ o.toString() }>{ o }</option>
                                    )
                                })
                            }
                        </select> :
                        <div className="cart-plus-minus">
                            <button
                                onClick={() => {
									let num =Number(quantityCount.toString().replace(/,/g, '')) > 1 ? Number(quantityCount.toString().replace(/,/g, '')) - 1 : 1
                                    setQuantityCount(num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                                }
                                }
                                className="dec qtybutton"
                            >
                                -
                            </button>
                            <input
                                className="cart-plus-minus-box"
                                type="text"
                                value={quantityCount}
								onChange={handleChange}
								maxLength={6}
                            />
                            <button
                               onClick={() => {
								if (IsAllowOutOfStockOrder) {
									let num =Number(quantityCount.toString().replace(/,/g, '')) + 1
									setQuantityCount(num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
								} else {
									// if (product?.poType == "Stock") {
										let num1= Number(quantityCount.toString().replace(/,/g, '')
											) < getProductStock() - productCartQty
											? Number(quantityCount.toString().replace(/,/g, '')
											) + 1
											: Number(quantityCount.toString().replace(/,/g, '')
											)
										setQuantityCount(num1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
									// } else {
									// 	if (getProductStock()) {
									// 		setQuantityCount(quantityCount + 1)
									// 	}
									// }
								}
							}}
                                className="inc qtybutton"
                            >
                                +
                            </button>
                        </div>
                        }

                            {((showAddToCartOnProd != false && getSettingStatus('showAddToCart') != false) || getSettingStatus('showAddToCart') != false) != false && <div className="pro-details-cart">
                            {((getProductStock() > 0) || IsAllowOutOfStockOrder || !selectedProductSize || selectedProductSize === `Select ${sizeLabel}`
                                    || !selectedProductColor || selectedProductColor === `Select ${colorLabel}`) ? (
                                <Link
                                    to={{
                                        state: {redirectUrl: window.location.pathname}
                                    }}
                                    onClick={() => {
                                        /*if (product?.showProductPrice !== false) {*/
                                        if (checkMinQty(prodId, quantityCount, isRestrictQty, minAllQty, isMoq, isSkuMoq, minQty, {color: selectedProductColor, size: selectedProductSize}, product?.doNotCalculateAtOrder)) {
                                                let blnktest = []
                                                if (!!personalizationArray) {
                                                    blnktest = !!personalizationArray ? personalizationArray.filter(x => x.displayText === '') : []
                                                }
                                                if(showSize) {
                                                    if(!selectedProductSize || selectedProductSize === `Select ${sizeLabel}`){
                                                      orderAction(`You must select a ${sizeLabel}`, "error");
                                                      return;
                                                    }
                                                  }
                                                  if(showColor) {
                                                    if(!selectedProductColor || selectedProductColor === `Select ${colorLabel}`){
                                                      orderAction(`You must select a ${colorLabel}`, "error");
                                                      return;
                                                    }
                                                  }
                                                  if((RequiredDecorationForCheckOutPro === true) && !((!!selectedProductDecorations && selectedProductDecorations.length > 0))) {
                                                    orderAction('Please choose a decoration to add this product to your cart', "error")
                                                    return;
                                                  } 
                                                  if (blnktest.length > 0) {
                                                    orderAction('You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.', "error")
                                                    return;
                                                  }
                                                  const quantityFromCart = getCartItemTotal(prodId, selectedProductColor, selectedProductSize);
                                                  const stock = getProductStock();
                                                  if(!IsAllowOutOfStockOrder && (stock - quantityFromCart < quantityCount)) {
                                                    setQuantityCount(1)
                                                    orderAction("Stock is not enough to add to cart", "error");
                                                    return;
                                                  }
                                                addToCart(
                                                    product,
                                                    addToast,
                                                    Number(quantityCount.toString().replace(/,/g, '')),
                                                    selectedProductColor,
                                                    selectedProductSize,
                                                    selectedProductDecorations,
                                                    personalizationArray,
                                                    (!!personalizationArray && personalizationArray.length > 0 && getSettingStatus('showPersonalization') == true) ? personalizationCost : {},
                                                    userGroupDiscount,
                                                    isPriceBreak,
                                                    true,
                                                    RequiredDecorationForCheckOutPro,
                                                    ipV4,
                                                )
                                                goToCart()
                                            }
                                        /*} else {
                                            addToast('Price not available', {
                                                appearance: "error",
                                                autoDismiss: true
                                            })
                                        }*/
                                    }
                                    }
                                    disabled={(IsAllowOutOfStockOrder === true) ? !IsAllowOutOfStockOrder : (getProductStock() == 0 ? true : false)}
                                >
                                    {" "}
                                    Add To Cart{" "}
                                </Link>
                            ) : (
                                <button disabled>Out of Stock</button>
                            )}
                        </div>}

                        {showWishListToggle && (
                            <div className="pro-details-wishlist ml-10">
                                <button
                                    className={wishlistItem !== undefined ? "active" : ""}
                                    disabled={wishlistItem !== undefined}
                                    title={
                                        wishlistItem !== undefined
                                            ? "Added to wishlist"
                                            : "Add to wishlist"
                                    }
                                    onClick={() => addToWishlist(product, addToast)}
                                >
                                    <i className="pe-7s-like"/>
                                </button>
                            </div>
                        )}
                        {showCompareToggle && (
                            <div className="pro-details-compare">
                                <button
                                    className={compareItem !== undefined ? "active" : ""}
                                    disabled={compareItem !== undefined}
                                    title={
                                        compareItem !== undefined
                                            ? "Added to compare"
                                            : "Add to compare"
                                    }
                                    onClick={() => addToCompare(product, addToast)}
                                >
                                    <i className="pe-7s-shuffle"/>
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )
            }
            {
                product.category ? (
                    <div className="pro-details-meta">
                        <span>Categories :</span>
                        <ul>
                            {product.category.map((single, key) => {
                                return (
                                    <li key={key}>
                                        <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                                            {single}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    ""
                )
            }
            {
                product.tag ? (
                    <div className="pro-details-meta">
                        <span>Tags :</span>
                        <ul>
                            {product.tag.map((single, key) => {
                                return (
                                    <li key={key}>
                                        <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                                            {single}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    ""
                )
            }

            {
                showAddnewart && <AddnewArtDialogue
					productId={productId}
                    open={showAddnewart}
                    show={showAddnewart}
                    title="Add New Artwork"
                    onHide={() => handleCancel()}
                    handleSubmit={handleAddnewArt}
                    cancel={() => handleCancel()}
                    catalogueItem={product}
                />
            }
            {showDecoPopup && (
                <SharedModal 
                    indexPopup={indexPopup}
                    mainArrayKeyPopup={mainArrayKeyPopup}
                    itemsRowsPopup={itemsRowsPopup}
                    popupText={popupText}
                    removeDeco={removeDeco}
                    setShowDecoPopup={setShowDecoPopup}
                    showDecoPopup={showDecoPopup}
                />
            )}
        </div>
    );
};

ProductDescriptionInfoPromotional.propTypes = {
    addToCart: PropTypes.func,
    addToCompare: PropTypes.func,
    addToWishlist: PropTypes.func,
    addToast: PropTypes.func,
    cartItems: PropTypes.array,
    compareItem: PropTypes.array,
    currency: PropTypes.object,
    discountedPrice: PropTypes.number,
    finalDiscountedPrice: PropTypes.number,
    finalProductPrice: PropTypes.number,
    product: PropTypes.object,
    wishlistItem: PropTypes.object
};

const mapDispatchToProps = dispatch => {
    return {
        addToCart: (
            item,
            addToast,
            quantityCount,
            selectedProductColor,
            selectedProductSize,
            selectedProductDecorations,
            personalizationArray,
			personalizationCost,
            userGroupDiscount,
			isPriceBreak,
            needValidation,
            RequiredDecorationForCheckOutPro,
            ipV4,
        ) => {
            dispatch(
                addToCart(
                    item,
                    addToast,
                    quantityCount,
                    selectedProductColor,
                    selectedProductSize,
                    selectedProductDecorations,
                    personalizationArray,
					personalizationCost,
                    userGroupDiscount,
					isPriceBreak,
                    true,
                    RequiredDecorationForCheckOutPro,
                    ipV4,
                )
            );
        },
        addToWishlist: (item, addToast) => {
            dispatch(addToWishlist(item, addToast));
        },
        addToCompare: (item, addToast) => {
            dispatch(addToCompare(item, addToast));
        },
        fetchDecorationLocations: (data) => dispatch(fetchDecorationLocations(data)),
        fetchArtworksById: (data) => dispatch(fetchArtworksById(data)),
        fetchProductStock: (data) => dispatch(fetchProductStock(data)),
    };
};
const mapStateToProps = state => {
    return {
        decorationLocations: state.productData.decorationLocations,
        artworkListById: state.productData.artworkListById,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDescriptionInfoPromotional);
