import {
  Alert,
  Grid2,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useContext, useEffect, useMemo } from 'react'
import CheckoutTextField from '../CheckoutTextField'
import {
  FormContainer,
  SubmitHandler,
  TextareaAutosizeElement,
  useFieldArray,
  useForm,
} from 'react-hook-form-mui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  selectContactInfo,
  selectInHandsDate,
  selectParams,
  selectQuestions,
  selectSessionId,
  selectSettings,
} from '../../../redux/selectors/checkoutSelectors'
import { useGetCheckoutQuestionsQuery } from '../../../redux/api/aetherApi'
import { LoadingButton } from '@mui/lab'
import { getError, getFormQuestions } from '../../../helpers/checkout'
import { completeEditStep } from '../../../redux/reducers/checkoutReducer'
import CheckoutQuestionField from './CheckoutQuestionField'
import { CustomerDetailsForm } from '../../../models/CustomerDetailsForm'
import { useUpdateCustomerDetailsMutation } from '../../../redux/api/checkoutApi'
import CheckoutDateField from '../CheckoutDateField'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AuthContext } from '../../../common/Auth'

const SIZE_MAP: { [key: string]: number } = {
  small: 4,
  medium: 6,
  large: 12,
}

export default function CustomerDetailsActiveContent() {
  const { user } = useContext(AuthContext)
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))
  const dispatch = useAppDispatch()
  const params = useAppSelector(selectParams)
  const settings = useAppSelector(selectSettings)
  const checkoutQuestions = useAppSelector((state) =>
    selectQuestions(state, params),
  )
  const contactInfo = useAppSelector((state) =>
    selectContactInfo(state, params),
  )
  const inHandsDate = useAppSelector((state) =>
    selectInHandsDate(state, params),
  )
  const sessionId = useAppSelector((state) => selectSessionId(state, params))
  const {
    data: questions,
    isLoading: questionsLoading,
    error: questionsError,
  } = useGetCheckoutQuestionsQuery()

  const formContext = useForm<CustomerDetailsForm>({
    defaultValues: useMemo(
      () => ({
        ...contactInfo,
        questions: getFormQuestions(questions ?? [], checkoutQuestions ?? []),
        inHandsDate,
      }),
      [contactInfo, questions, inHandsDate, checkoutQuestions],
    ),
  })
  const { handleSubmit, reset, control } = formContext
  const { fields: questionFields } = useFieldArray({
    control,
    name: 'questions',
  })

  const [
    updateCustomerDetails,
    { isLoading: updateSettingsLoading, error: updateSettingsError, isSuccess },
  ] = useUpdateCustomerDetailsMutation()

  useEffect(() => {
    if (questions && checkoutQuestions) {
      reset({
        ...contactInfo,
        questions: getFormQuestions(questions, checkoutQuestions),
        inHandsDate,
      })
    }
  }, [questions, checkoutQuestions])

  const onSubmit: SubmitHandler<CustomerDetailsForm> = async (data) => {
    if (sessionId && params) {
      updateCustomerDetails({ sessionId, userId: params.userId, ...data })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(completeEditStep())
    }
  }, [isSuccess])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormContainer
        formContext={formContext}
        handleSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={2}>
          <Typography variant="subtitle2" gutterBottom pt={2}>
            Contact Info
          </Typography>
          <Grid2 container spacing={2}>
            <Grid2 size={6}>
              <CheckoutTextField
                name={'firstName'}
                label={'First Name'}
                rules={{ required: true }}
                readOnly={!!user}
              />
            </Grid2>
            <Grid2 size={6}>
              <CheckoutTextField
                name={'lastName'}
                label={'Last Name'}
                rules={{ required: true }}
                readOnly={!!user}
              />
            </Grid2>
            <Grid2 size={12}>
              <CheckoutTextField
                name={'email'}
                label={'Email'}
                rules={{ required: true }}
                readOnly={!!user}
              />
            </Grid2>
          </Grid2>
          {((questions?.length ?? 0) > 0 || settings.showInHandsDateField) && (
            <>
              <Typography variant="subtitle2" gutterBottom pt={2}>
                Questions
              </Typography>
              <Grid2 container spacing={2}>
                {settings.showInHandsDateField && (
                  <Grid2 size={4}>
                    <CheckoutDateField
                      name={'inHandsDate'}
                      label={
                        settings.ihdRenameEnabled && settings.ihdRenameText
                          ? settings.ihdRenameText
                          : 'In Hands Date'
                      }
                      required={settings.ihdRequired ?? false}
                    />
                  </Grid2>
                )}
                {!questionsLoading &&
                  questionFields.map((field, index) => (
                    <Grid2 key={index} size={SIZE_MAP[field.size]}>
                      <CheckoutQuestionField
                        key={field.questionId}
                        index={index}
                        field={field}
                      />
                    </Grid2>
                  ))}
              </Grid2>
            </>
          )}
          {settings.orderNoteEnabled && (
            <>
              <Typography variant="subtitle2" gutterBottom pt={2}>
                Order Note
              </Typography>
              <TextareaAutosizeElement
                name="orderNote"
                label="Note"
                fullWidth
              />
            </>
          )}
          <Stack direction={'row'} justifyContent={'center'}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              data-testid={'cdContinue'}
              loading={updateSettingsLoading}
              fullWidth={xsDisplay}
            >
              Continue
            </LoadingButton>
          </Stack>
          <Alert severity="error" hidden={!updateSettingsError}>
            {getError(updateSettingsError)}
          </Alert>
        </Stack>
      </FormContainer>
    </LocalizationProvider>
  )
}
