import { Alert, Stack, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import {
  CheckboxElement,
  TextFieldElement,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form-mui'
import { AuthContext } from '../../../common/Auth'

const PARAMS = '?useexpiry=true&usecvv=true&invalidcreditcardevent=true&invalidcvvevent=true&invalidexpiryevent=true'

const PROD_URL = 'https://fts.cardconnect.com/itoke/ajax-tokenizer.html'
const TEST_URL = 'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html'

const CSS =
  'input%2C%20select%20%7B%0A%20%20font-family%3A%20%22Roboto%22%2C%22Helvetica%22%2C%22Arial%22%2Csans-serif%3B%0A%20%20border-radius%3A%204px%3B%0A%20%20border-color%3A%20rgba%280%2C%200%2C%200%2C%200.23%29%3B%0A%20%20border-width%3A%201px%3B%0A%20%20border-style%3A%20solid%3B%0A%20%20box-sizing%3A%20content-box%3B%0A%20%20background%3A%20none%3B%0A%20%20box-shadow%3A%20none%3B%0A%20%20height%3A%201.4375em%3B%0A%20%20padding-top%3A%201px%3B%0A%20%20padding%3A%208.5px%2014px%3B%0A%20%20outline-color%3A%20OUTLINE_COLOR%3B%0A%20%20margin-top%3A%204px%3B%0A%20%20margin-bottom%3A%208px%3B%0A%7D%0A%0Alabel%20%7B%0A%20%20display%3A%20inline-block%3B%0A%20%20font-family%3A%20%22Roboto%22%2C%22Helvetica%22%2C%22Arial%22%2Csans-serif%3B%0A%20%20font-weight%3A%20400%3B%0A%20%20font-size%3A%201rem%3B%0A%20%20line-height%3A%201.5%3B%0A%20%20letter-spacing%3A%200.00938em%3B%0A%20%20color%3A%20%23333%0A%7D%0A%0Abody%20%7B%0A%20%20margin%3A%200px%3B%0A%7D%0A%0A.error%20%7B%0A%20%20border-color%3A%20red%3B%0A%7D'

export default function CardConnectTokenForm({
  testModeEnabled,
}: {
  testModeEnabled: boolean
}) {
  const { user } = useContext(AuthContext)
  const { setValue } = useFormContext()
  const { submitCount } = useFormState()

  const theme = useTheme()

  const effectiveCSS = CSS.replace(
    'OUTLINE_COLOR',
    encodeURIComponent(theme.palette.primary.main),
  )

  window.addEventListener(
    'message',
    function (event) {
      if (typeof event.data != 'object') {
        const data = JSON.parse(event.data)
        if (data.message) {
          setValue('ccToken', data.message)
        }
      }
    },
    false,
  )

  return (
    <Stack gap={2}>
      <iframe
        id="tokenFrame"
        name="tokenFrame"
        height={'225px'}
        src={
          (testModeEnabled ? TEST_URL : PROD_URL) +
          PARAMS +
          '&css=' +
          effectiveCSS
        }
        style={{ border: 'none', width: '100%', overflow: 'hidden' }}
      ></iframe>
      {user && (
        <CheckboxElement name="saveMethod" label="Save card for future use" />
      )}
      <TextFieldElement hidden name="ccToken" label="Card Token" required />
      {submitCount > 0 && (
        <Alert severity="error">Invalid Card Information</Alert>
      )}
    </Stack>
  )
}
