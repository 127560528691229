import { Address } from './Address'
import { FulfillmentMethodType } from './FulfillmentMethod'

export interface FulfillmentQuoteMethod {
  methodId: string
  name: string
  amount: number
  instructions: string
  minDays?: number
  maxDays?: number
  type: FulfillmentMethodType,
  address?: Address
  fallback?: boolean
}

export interface FulfillmentQuote {
  _id: string;
  methods: FulfillmentQuoteMethod[]
}

export enum AddressSource {
  USER_ADDRESS = 'userAddress',
  SHIPPING_LOCATION = 'shippingLocation',
  USER_SUPPLIED = 'userSupplied',
}

export interface GetFulfillmentQuoteParams {
  sessionId: string
  userId: string
  type: FulfillmentMethodType
  addressSource?: AddressSource
  savedAddressId?: string
  address?: Address
}
