import { createTheme } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";

export interface StoreStyleOptions {
    themedefaultColor: string;
    themedefaultTextColor: string
}

export const newTheme = (options: StoreStyleOptions) => createTheme({
    palette: {
        primary: {
            main: options.themedefaultColor
        },
    },
    typography: {
        fontFamily: "inherit",
        lineCaption: {
            lineHeight: 1.3,
            fontSize: "0.75rem",
            fontWeight: 400,
        }
    },
    components: {
        MuiInputBase: {
          styleOverrides: {
            inputMultiline: {
              border: 'none !important'
            }
          }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    lineCaption: "span"
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                },
            },
            defaultProps: {
              variant: "outlined",
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                },
            },
        },
        MuiSkeleton: {
            defaultProps: {
                animation: "wave",
                variant: "rounded"
            },
            styleOverrides: {
                root: {
                    variants: [
                        {
                            props: { variant: "rounded" },
                            style: {
                                borderRadius: "12px",
                            },
                        }
                    ]
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "24px",
                },
            },
        },
    }
});