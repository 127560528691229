import React, { useMemo } from 'react'
import { Button, Grid2, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import CheckoutTextField from './CheckoutTextField'
import { LoadingButton } from '@mui/lab'
import { useFormContext } from 'react-hook-form-mui'

interface AddressContactFormProps {
  label: string,
  formPrefix?: string
  loading?: boolean
  showCancel?: boolean
  onSave: () => void
  onCancel?: () => void
}

export default function AddressContactForm({
  label,
  formPrefix,
  loading,
  showCancel,
  onSave,
  onCancel
}: AddressContactFormProps) {
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))

  const { trigger } = useFormContext();

  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`
    } else {
      return ''
    }
  }, [formPrefix])
  
  const handleSave = async () => {
    const valid = await trigger([`${effectivePrefix}contact`])
    if (valid) {
      onSave();
    }
  }

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant="body1">{label} Contact</Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.firstName`}
            label="First Name"
            rules={{ required: true }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.lastName`}
            label="Last Name"
            rules={{ required: true }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.email`}
            label="Email"
            rules={{ required: true }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.phone`}
            label="Phone"
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.company`}
            label="Company"
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.attentionTo`}
            label="Attention To"
          />
        </Grid2>
      </Grid2>
      <Stack direction={'row'} justifyContent={'center'} mt={2} spacing={2}>
        {showCancel && (
          <Button
            variant='outlined'
            onClick={onCancel}
            fullWidth={xsDisplay}
          >
            Cancel
          </Button>
        )}
        <LoadingButton
          variant="contained"
          size="large"
          onClick={handleSave}
          loading={loading}
          fullWidth={xsDisplay}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
